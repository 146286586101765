
import PrimeReact from 'primereact/api';
import { Button } from 'primereact/button';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';
import { Sidebar } from 'primereact/sidebar';
import { classNames } from 'primereact/utils';
import { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../../context';
import './AppConfig.css'
const AppConfig = (props: { simple: any; }) => {
    
    
    const [scales] = useState([12, 13, 14, 15, 16]);

    const { layoutConfig, setLayoutConfig, layoutState, setLayoutState } = useContext(LayoutContext);
    const onConfigButtonClick = () => {
        setLayoutState((prevState: any) => ({ ...prevState, configSidebarVisible: true }));
    };

    const onConfigSidebarHide = () => {
        setLayoutState((prevState: any) => ({ ...prevState, configSidebarVisible: false }));
    };

    const changeInputStyle = (e: RadioButtonChangeEvent) => {
        PrimeReact.inputStyle = layoutConfig.inputStyle as any
        setLayoutConfig((prevState: any) => ({ ...prevState, inputStyle: e.value }));
    };

    const changeRipple = (e: InputSwitchChangeEvent) => {
        PrimeReact.ripple = Boolean(e.value)
        setLayoutConfig((prevState: any) => ({ ...prevState, ripple: e.value }));
    };

    const changeMenuMode = (e: RadioButtonChangeEvent) => {
        setLayoutConfig((prevState: any) => ({ ...prevState, menuMode: e.value }));
    };

    const changeTheme = (theme: string, colorScheme: string) => {
        const themeLink = document.getElementById('theme-css');
        const themeHref = themeLink ? themeLink.getAttribute('href') : null;
        const newHref = themeHref ? themeHref.replace(layoutConfig.theme, theme) : null;

        replaceLink(themeLink, newHref, () => {
            setLayoutConfig((prevState: any) => ({ ...prevState, theme, colorScheme }));
        });
    };

    const replaceLink = (linkElement: HTMLElement | null, href: string | null, onComplete: { (): void; (): any; }) => {
        if (!linkElement || !href) {
            return;
        }
        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true) as HTMLElement;

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');


        linkElement.parentNode?.insertBefore(cloneLinkElement, linkElement.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            linkElement.remove();

            const element = document.getElementById(String(id)); // re-check
            element && element.remove();

            cloneLinkElement.setAttribute('id', String(id));
            onComplete && onComplete();
        });
    };



    const decrementScale = () => {
        setLayoutConfig((prevState: { scale: number; }) => ({ ...prevState, scale: prevState.scale - 1 }));
    };

    const incrementScale = () => {
        setLayoutConfig((prevState: { scale: number; }) => ({ ...prevState, scale: prevState.scale + 1 }));
    };

    const applyScale = () => {
        document.documentElement.style.fontSize = layoutConfig.scale + 'px';
    };


    useEffect(() => {
        applyScale();
    }, [layoutConfig.scale]);


    return (
        <>
            <button className="layout-config-button p-link"  style={{ background: '#11B3CF' }}  type="button" onClick={onConfigButtonClick}>
                <i className="pi pi-cog"></i>
            </button>
            <Sidebar visible={layoutState.configSidebarVisible} onHide={onConfigSidebarHide} position="right" className="layout-config-sidebar w-20rem">
                <div className="py-2">
                    <h5 className='appconfig-labels'>Mode</h5>
                    <Button icon={`pi ${layoutConfig.colorScheme === 'dark' ? 'pi-sun' : 'pi-moon'}`} className="p-button-help p-button-rounded p-button-text p-button-raised" aria-label="theme"
                        onClick={(e) => { changeTheme(`lara-${layoutConfig.colorScheme === 'dark' ? 'light' : 'dark'}-blue`, layoutConfig.colorScheme === 'dark' ? 'light' : 'dark') }} />
                </div>
                <div className="py-2">
                    <h5 className='appconfig-labels'>Scale</h5>
                    <div className="flex align-items-center">
                        <Button icon="pi pi-minus" type="button"  onClick={decrementScale} className="p-button-text p-button-rounded w-2rem h-2rem mr-2" disabled={layoutConfig.scale === scales[0]}></Button>
                        <div className="flex gap-2 align-items-center">
                            {scales.map((item) => {
                                return <i className={classNames('pi pi-circle-fill', { 'text-primary-500': item === layoutConfig.scale, 'text-300': item !== layoutConfig.scale })} key={item}></i>;
                            })}
                        </div>
                        <Button icon="pi pi-plus" type="button" onClick={incrementScale} className="p-button-text p-button-rounded w-2rem h-2rem ml-2" disabled={layoutConfig.scale === scales[scales.length - 1]}></Button>
                    </div>
                </div>
                <div className="py-2">
                    <h5 className='appconfig-labels'>Menu Type</h5>
                    <div className="flex">
                        <div className="field-radiobutton flex-1">
                            <RadioButton name="menuMode" value={'static'} checked={layoutConfig.menuMode === 'static'} onChange={(e) => changeMenuMode(e)} inputId="mode1"></RadioButton>
                            <label htmlFor="mode1">Static</label>
                        </div>
                        <div className="field-radiobutton flex-1">
                            <RadioButton name="menuMode" value={'overlay'} checked={layoutConfig.menuMode === 'overlay'} onChange={(e) => changeMenuMode(e)} inputId="mode2"></RadioButton>
                            <label htmlFor="mode2">Overlay</label>
                        </div>
                    </div>
                </div>
                <div className="py-2">
                    <h5 className='appconfig-labels'>Input Style</h5>
                    <div className="flex">
                        <div className="field-radiobutton flex-1">
                            <RadioButton name="inputStyle" value={'outlined'} checked={layoutConfig.inputStyle === 'outlined'} onChange={(e) => changeInputStyle(e)} inputId="outlined_input"></RadioButton>
                            <label htmlFor="outlined_input">Outlined</label>
                        </div>
                        <div className="field-radiobutton flex-1">
                            <RadioButton name="inputStyle" value={'filled'} checked={layoutConfig.inputStyle === 'filled'} onChange={(e) => changeInputStyle(e)} inputId="filled_input"></RadioButton>
                            <label htmlFor="filled_input">Filled</label>
                        </div>
                    </div>
                </div>

                <div className="py-2">
                    <h5 className='appconfig-labels'>Ripple Effect</h5>
                    <InputSwitch checked={layoutConfig.ripple} onChange={(e) => changeRipple(e)} className='appconfig-ripple'></InputSwitch>
                </div>


            </Sidebar>
        </>
    );
};

export default AppConfig;
