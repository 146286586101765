import React, { useState, useEffect, useRef } from "react";
import { loanSvc } from '../../../services';
import LoanModal from "./LoanModal";
import { toast } from "react-toastify";
import { Dialog } from 'primereact/dialog';
import DataGridV2 from "../../../components/datagrid/DataGridV2";
import { Button } from "primereact/button";
import LoanDetails from "./LoanDetails";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SearchBar from "../../../components/common/searchBar/SearchBar";
import ActionButton from "../../../components/common/buttons/actionButton";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ErrorMessage from "../../../components/common/ErrorMessage";
import ToastMessage from "../../../components/common/toastMessage/toastMessage";
import { Dropdown } from "primereact/dropdown";
import { useLocation, useNavigate } from "react-router-dom";





const LoanList: React.FC = () => {
  const location = useLocation();
   const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [t] = useTranslation()
  const [totalRecords, settotalRecords] = useState(0);
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');
  const [visible, setVisble] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [reload, setRelaod] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [approveDate, setApproveDate] = useState<Date | null>(null);
  const [approveDialogVisible, setApproveDialogVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); //For serch bar
  const [viewDetails, setviewDetails] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<any>('approved');
  const [region, setRegion] = useState<number | string>(0);
  const [regionList, setRegionList] = useState([])

  const [agent, setAgent] = useState<number | string>(0);
  const [agentList, setAgentList] = useState([])
  const columns = [
    { field: 'sl_no', header: 'Sl No' },
    { field: 'full_name', header: 'Customer Name' },
    { field: 'borrow_type', header: "Borrow Type" },
    // { field: 'disburse_type', header: 'Disburse Type' },
    { field: 'requested_amt', header: 'Req Amt', type: 'amount' },
    { field: 'approved_amt', header: 'Approved Amt', type: 'amount' },
    { field: 'requested_on', header: 'Req On', type: 'date' },
    {
      field: 'status', header: 'Status', type: 'status', status: [
        { key: 'approved', value: 'success' },
        { key: 'closed', value: 'info' },
        { key: 'rejected', value: 'danger' },
      ]
    },


  ];

  useEffect(() => {

    const queryParams = new URLSearchParams(location.search);
      const statusParam = queryParams.get('status');

      // Set the default status to 'created' if not provided in the URL
      const status = statusParam || selectedStatus;

      // Update the state and fetch data based on the status
      setSelectedStatus(status);
    loanFilterData(status,region,agent) //Loan list based on selected category
    loanSvc.regionList().then(res => {
      setRegionList(res.data)
    });
    
  }, [limit, page,location.search]);
  useEffect(() => {
    if (reload) {

      loanFilterData(selectedStatus,region,agent)
    }
    setRelaod(false);
  }, [reload]);
  useEffect(() => {
    if (!visible || !viewDetails)
      setId('');
  }, [visible, viewDetails]);
  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };

  const onEditOrView = (edit: boolean, id: string) => {
    setEdit(edit);
    // if (id)
    setId(id);
    setVisble(true);
  };
  const onApprove = () => {
    if (id && approveDate) {

      const desiredFormat = "YYYY-MM-DD HH:mm:ss";
      const convertedFormat = moment(approveDate).format(desiredFormat);

      // check approve date and current date for pass null value if user enter current date those dates are same send null value to server
      if (moment(approveDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
        loanSvc.approve(id, convertedFormat).then(res => {
          setRelaod(true);
        });
      } else {
        loanSvc.approve(id, null).then(res => {
          setRelaod(true);
        });
      }
      onHide()
    }

  };

  const onReject = (id: string) => {
    loanSvc.rejected(id).then(res => {
      setRelaod(true);
    });
  };
  // Close approve date calender
  const onHide = () => {
    setApproveDialogVisible(false)
    setApproveDate(null)
  }
  const toast = useRef<any>(null);
  const deleteRecord = (i: string) => {
    loanSvc.Delete(i).then(res => {
      if (res) {
        // toast.success('Loan deleted successfully');
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Loan deleted successfully', life: 2000 });

        setRelaod(true);
      } else {
        toast.current.show({ severity: 'Error', summary: 'Error', detail: 'Failed to delete Loan', life: 2000 });
      }
    });
  }

  const deleteByIdList = (i: string) => {
    loanSvc.multipleDelete(i).then(res => {
      if (res) {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Loan deleted successfully', life: 2000 });
        setRelaod(true);
      } else {
        toast.current.show({ severity: 'Error', summary: 'Error', detail: 'Failed to delete Loans', life: 2000 });
      }
    });
  }

  const permissionList = useSelector((state: any) => state.permissions.permissions);

  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { deleteRecord(id ?? " "); setDeleteDialog(false) }} />
    </>
  );

  // Implement Search bar 
  const handleSearch = () => {
    if (searchQuery) {
      loanSvc.serchLoan(searchQuery, Number(page), Number(limit)).then(res => {
        if (res) {
          setTableData(res.data.list);
          settotalRecords(res.data.recordcount);
        }
      });
    }

  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (value === '') {
      setRelaod(true);
    }
  };
  const LoanSearch = () => {

    return (
      <React.Fragment>
        <SearchBar
          searchQuery={searchQuery}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
        />
      </React.Fragment>
    );
  };

  const status = [
    { name: 'All', code: 'all' },
    { name: 'Approved', code: 'approved' },
    { name: 'Rejected', code: 'rejected' },
    { name: 'Created', code: 'created' },
    { name: 'Closed', code: 'closed' },
  ];
  const loanFilterData = (status: string,region: number | string, agent: number | string) => {
    // const { code } = status;
    
    loanSvc.searchByStatus(status, region, agent, String(limit), String(page)).then(res => {
      setTableData(res.data.data);
      settotalRecords(res.data.recordcount);
    });
  };

  const LoanListFilter = () => {

    return (
      <div >
        <Dropdown value={selectedStatus} onChange={(e) => { setSelectedStatus(e.value); loanFilterData(e.value, region, agent); }} options={status} optionLabel="name"
          placeholder={t("Select a category") as string} style={{ textTransform: 'capitalize' }} optionValue="code" className="ml-1"/>

        <Dropdown options={regionList} value={region} placeholder={t("Region") as string}
          onChange={(e) => {
            setRegion(e.value);
            loanFilterData(selectedStatus, e.value, 0);
            loanSvc.agentList(e.value).then(res => {
              setAgentList(res.data)
            });
            }} 
            optionLabel="region_name" optionValue="_id"   className="ml-1"/>

        <Dropdown options={agentList} value={agent} placeholder={t("Agent") as string}
          onChange={(e) => {setAgent(e.value); loanFilterData(selectedStatus, region, e.value);}} optionLabel="agent_name" optionValue="_id"  className="ml-1"/>
          {/* <Button icon="pi pi-times "  style={{  width: '35px', height: '38px',border:'none' }} onClick={()=>{loanFilterData(selectedStatus, 0, 0);setAgent(0);setRegion(0)}} className="ml-1"/> */}
      </div>
    );
  };
  const extraAction = ({ rowData }: any) => {

    const hasApprovePermission = permissionList && permissionList.includes('pages.loan.approve');
    const hasRejectPermission = permissionList && permissionList.includes('pages.loan.reject');
    const hasViewPermission = permissionList && permissionList.includes('pages.loan.get');
    const hasUpdatePermission = permissionList && permissionList.includes('pages.loan.update');
    const hasDeletePermission = permissionList && permissionList.includes('pages.loan.delete');
    return <>
      {hasApprovePermission && rowData.status === 'created' && <ActionButton label={t("Approve")} icon="pi pi-check" className="p-button-success" onClick={() => { setApproveDialogVisible(true); setId(rowData._id); }} />}
      {hasRejectPermission && rowData.status === 'created' && <ActionButton label={t("Reject")} icon="pi pi-times" className="p-button-danger" onClick={() => { onReject(rowData._id); }} />}
      {hasViewPermission && rowData.status !== 'created' && rowData.status !== 'rejected' && <ActionButton label={t("View")} icon="pi pi-eye" className="p-button-secondary" onClick={() => { setId(rowData._id); setviewDetails(true); }} />}
      {/* {hasUpdatePermission && rowData.status === 'created' && <ActionButton label={t("Update")} icon="pi pi-pencil" className="p-button-secondary " onClick={() => { onEditOrView(true, rowData._id); }} />} */}
      {hasUpdatePermission && rowData.status !== 'rejected' && <ActionButton label={t("Update")} icon="pi pi-pencil" className="p-button-secondary " onClick={() => { onEditOrView(true, rowData._id); }} />}
      {hasDeletePermission && rowData.status === 'created' && <ActionButton label={t("Delete")} icon="pi pi-trash" className="p-button-danger" onClick={() => { setDeleteDialog(true); setId(rowData._id); }} />}


    </>;
  };


  return (
    <>
      <DataGridV2
        tittle={t("loans")} data={tableData} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} onCreateOrEdit={onEditOrView}
        reload={reload} setReload={setRelaod} deleteByIdList={deleteByIdList}
        extraAction={extraAction} SearchBar={LoanSearch} filter={LoanListFilter} addNew={true} />
      {visible && <LoanModal id={id} setVisible={setVisble} visible={visible} setReload={setRelaod} viewOnly={false} setSelectedStatus={setSelectedStatus} loanFilterData={loanFilterData} />}
      {viewDetails && <LoanDetails id={id} setVisible={setviewDetails} visible={viewDetails} setReload={setRelaod} viewOnly={edit} />}
      <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {!id ? <span>Are you sure you want to delete the selected items?</span> : <span>Are you sure you want to delete ?</span>}
        </div>
      </Dialog>

      <Dialog visible={approveDialogVisible} style={{ width: '450px' }} header="Approve Date" modal onHide={onHide}>
        <div className="flex align-items-center justify-content-center">
          <Calendar value={approveDate} onChange={(e) => setApproveDate(e.value as Date)} dateFormat="dd/mm/yy" showIcon showButtonBar />
          <Button label="Approve" icon="pi pi-check" className="p-button-success p-button-text ml-3" onClick={onApprove} />
        </div>
        <div className="ml-6">{approveDate === null && <ErrorMessage message={"Please select a date"} id="calender" />}</div>
      </Dialog>

      <ToastMessage showToast={toast} />
    </>
  );
};

export default LoanList;
