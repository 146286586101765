import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/common/ErrorMessage";
import { loanSvc } from '../../../services';
import { CancelButton, ModalSubHeading, SaveButton } from "../../../components/styledcomponents";
import { useTranslation } from "react-i18next";


const LoanTopUpModal = ({ setVisible, visible = false, id,updateTableData }: ILoanTopUpModal) => {
    const [loanId, setLoanId] = useState<string | null>(id);
    const [amount, setAmount] = useState<any>("");
    const [preClosureDetails, setPreClosureDetails] = useState<any>(null)
    const [error,setError]=useState(false)
    const [t] = useTranslation()
    useEffect(() => {
        if (id)
        loanSvc.getpreclosureamount(id).then(res => {
                if (res) {
                    setPreClosureDetails(res.data)
                }
            })

    }, [id])

    const onSubmit = async () => {
        if (loanId !== null) {
            if (!amount || isNaN(Number(amount))) {
                setError(true)               
                return;
            }
            const res = await loanSvc.topupLoan(amount,loanId);
            if (res) 
            updateTableData()
            toast.success("Success");
            setVisible(false)
            return;
        }
    };


    const DialogFooter = (
        <>
            <CancelButton label={t("Cancel") + ''} onClose={() => { setVisible(false) }}  />
            <Button label={t("Save") + ''} icon="pi pi-check" className="p-button-success p-button-raised" style={{ background: '#11B3CF',height:'3rem' }}onClick={onSubmit} />
        </>);
    return (
        <Dialog header={preClosureDetails ?`${preClosureDetails.slno} - (${preClosureDetails.customer_name})`: "N/A" } footer={DialogFooter} visible={visible} maximizable style={{ width: '40vw' }} onHide={() => setVisible(false)}>
            <div className="flex flex-row gap-1 font-bold">
              <ModalSubHeading >{t("Pre-Closure Amount :")}</ModalSubHeading>
              <ModalSubHeading className=" blue-text">
                {preClosureDetails ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(preClosureDetails.amnt) : 'N/A'}
              </ModalSubHeading>
            </div>

            <div className="flex flex-column gap-2" style={{ maxWidth: 400 }}>
                <label htmlFor="topUpAmt" className="font-bold block mb-2">{t("Enter the Top-Up Amount")}<span style={{ color: 'red' }}>*</span></label>
                <InputNumber placeholder="INR 00.00"  inputId="currency-india" value={amount} onValueChange={(e) => setAmount(e.value)} mode="currency" currency="INR" currencyDisplay="code" className={`${error ? 'p-invalid' : ''}`} />
                {error && <ErrorMessage id={"topUpAmt"} message={"Required"} />}
            </div>
        </Dialog>
    );
};
export default LoanTopUpModal

interface ILoanTopUpModal {
    visible: boolean;
    setVisible(val: boolean): void;
    id: string | null;
    updateTableData: any
};