import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../../components/common/ErrorMessage";
import { chitfundSvc } from '../../../../../services';
import { Calendar } from "primereact/calendar";
import { useTranslation } from "react-i18next";
import { CancelButton, Essential } from "../../../../../components/styledcomponents";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";

const PayChitAmountModal = ({ setVisible, visible = false, chitId, customerId }: IPayChitAmountModal) => {
    const [repayAmt, setRepayAmt] = useState<any>("");
    const [date, setDate] = useState<Date | null>(null);
    const [paymentDetails, setPaymentDetails] = useState<any>(null)
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [totalSelectedAmount, setTotalSelectedAmount] = useState(0);
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false);//Disable save button in api call

    const [t] = useTranslation()

    useEffect(() => {
        if (chitId && customerId)
            chitfundSvc.getIndividualPayments(chitId, customerId).then(res => {
                if (res.data) {
                    setPaymentDetails(res.data)
                    setData(res.data.data)
                }
            })


    }, [chitId, customerId])

    useEffect(() => {
        let totalAmount = 0;
        selected.forEach((selectedRow: any) => {
            totalAmount += selectedRow.amount_to_pay;
        });
        setTotalSelectedAmount(totalAmount);
    }, [selected]);

    const onSubmit = async () => {

        if (chitId === null || date === null) {
            setError(true);
            return error;
        }

        if (chitId && date) {
            setIsLoading(true);
            const desiredFormat = "YYYY-MM-DD HH:mm:ss";
            const convertedFormat = moment(date).format(desiredFormat);
            // Create payment data objects for selected rows
            const paymentData = selected.map((selectedRow: any) => ({
                _id: selectedRow._id, // Use appropriate ID here
                amnt: selectedRow.amount_to_pay,
            }));

            // Check if paymentData is null or empty
            if (!paymentData || paymentData.length === 0) {
                toast.warning("Select a payment");
                setIsLoading(false);
                return;
            }

            // Create the final payload
            const payload = {
                chit_id: chitId,
                repay_date: convertedFormat,
                customer_id: customerId,
                paymentData: paymentData,
            };
            try {
                const res = await chitfundSvc.chitRepayment(payload);
                setVisible(false);
                toast.success("Success");
            } catch (error) {
                console.error("API error:", error);
            } finally {
                setIsLoading(false); // Re-enable the button
            }

        }
    };


    const formatDate = (value: any) => {
        return value.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const formatIndianDate = (rawData: any) => {
        return formatDate(new Date(rawData));
    };
    // Function to format currency amounts in Indian numbering system
    const formatIndianCurrency = (amount: any) => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR', // Indian Rupee
            minimumFractionDigits: 2,
        }).format(amount);
    };


    const DialogFooter = (
        <>

            <CancelButton label={"Cancel"} onClose={() => { setVisible(false) }} />
            <Button label='Save' icon="pi pi-check" style={{ background: '#11B3CF', border: 'none' }} className="p-button  p-button-raised" onClick={onSubmit} loading={isLoading} />
        </>);
    return (
        <Dialog header={paymentDetails ? paymentDetails.sl_no : 'N/A'} footer={DialogFooter} visible={visible} maximizable style={{ width: '40vw' }} onHide={() => setVisible(false)}>
            <div className="flex flex-row gap-1 font-bold" style={{ fontSize: '18px' }}>
                <p>{t("Total Selected Amount")} :</p>
                <p >
                    {paymentDetails ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(totalSelectedAmount) : 'N/A'}
                </p>

            </div>


            <div className="flex flex-row gap-5" style={{ maxWidth: 400 }}>

                <div className="mb-4">
                    <label htmlFor="repayment-date" className="font-bold block mb-2">{t("Repayment Date")}<span style={{ color: 'red' }}>*</span></label>
                    <Calendar value={date} onChange={(e) => setDate(e.value as Date)} dateFormat="dd/mm/yy" showIcon showButtonBar />
                    <div className="ml-6">{date === null && error && <ErrorMessage message={"Please select a date"} id="calender" />}</div>
                </div>

            </div>
            <DataTable value={data}
                selection={selected}
                onSelectionChange={(e: any) => setSelected(e.value)}
            >
                <Column selectionMode="multiple" frozen alignFrozen='left'></Column>
                <Column field="due_date" header="Due Date" body={(rowData) => formatIndianDate(rowData.due_date)} />
                <Column field="amount_to_pay" header="Amount To Pay" body={(rowData) => formatIndianCurrency(rowData.amount_to_pay)} />
                <Column field="amount_to_pay" header="Status" body={(rowData) => <Badge value={rowData.status}></Badge>} />
            </DataTable>

        </Dialog>
    );
};
export default PayChitAmountModal

interface IPayChitAmountModal {
    visible: boolean;
    setVisible(val: boolean): void;
    chitId: string | null;
    customerId: string;
};