import React, { useState, useEffect, useRef } from "react";
import { loanSvc } from '../../../services';
import { toast } from "react-toastify";
import { Dialog } from 'primereact/dialog';
import DataGridV2 from "../../../components/datagrid/DataGridV2";
import { Button } from "primereact/button";
import LoanDetails from "../loan/LoanDetails";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SearchBar from "../../../components/common/searchBar/SearchBar";
import ActionButton from "../../../components/common/buttons/actionButton";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ErrorMessage from "../../../components/common/ErrorMessage";
import ToastMessage from "../../../components/common/toastMessage/toastMessage";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";





const ClosedLoanList: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const [report, setReport] = useState([]);
  const [t] = useTranslation()
  const [totalRecords, settotalRecords] = useState(0);
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');
  const [visible, setVisble] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const [reload, setRelaod] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState(''); //For serch bar
  const [viewDetails, setviewDetails] = useState<boolean>(false);

  const [region, setRegion] = useState<number | string>(1);
  const [regionList, setRegionList] = useState([])

  const [agent, setAgent] = useState<number | string>(1);
  const [agentList, setAgentList] = useState([])

  const columns = [
    { field: 'sl_no', header: 'Sl No' },
    { field: 'full_name', header: 'Customer Name' },
    { field: 'borrow_type', header: "Borrow Type" },
    { field: 'requested_amt', header: 'Req Amt' ,type:'amount'},
    { field: 'approved_amt', header: 'Approved Amt' ,type:'amount'},
    { field: 'revenue', header: 'Revenue' ,type:'amount'},
    { field: 'approved_on', header: 'Approved On', type: 'date' },
  
  ];
  const closedloanList=(region:string|number,agent:string|number)=>{
    loanSvc.closedLoanList(region,agent,Number(page), Number(limit)).then(res => {
      setTableData(res.data.data);
      settotalRecords(res.data.recordcount);
      setReport(res.data.report)
    });
  }
  useEffect(() => {
    closedloanList(region,agent)
    loanSvc.regionList().then(res => {
      setRegionList(res.data)
    });
  }, [limit, page]);
  useEffect(() => {
    if (reload) {
        closedloanList(region,agent)
    }
    setRelaod(false);
  }, [reload]);
  useEffect(() => {
    if (!visible || !viewDetails)
      setId('');
  }, [visible, viewDetails]);
  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };

  const onEditOrView = (edit: boolean, id: string) => {
    setEdit(edit);
    // if (id)
    setId(id);
    setVisble(true);
  };

  const toast = useRef<any>(null);

  const deleteByIdList = (i: string) => {
    loanSvc.multipleDelete(i).then(res => {
      if (res) {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Loan deleted successfully', life: 2000 });
        setRelaod(true);
      } else {
        toast.current.show({ severity: 'Error', summary: 'Error', detail: 'Failed to delete Loans', life: 2000 });
      }
    });
  }

  const permissionList = useSelector((state: any) => state.permissions.permissions);

  // Implement Search bar 
  const handleSearch = () => {
    if (searchQuery) {
      loanSvc.serchClosedLoan(searchQuery,Number(page), Number(limit)).then(res => {
        if (res) {
          setTableData(res.data.list);
          settotalRecords(res.data.recordcount);
        }
      });
    }

  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (value === '') {
      setRelaod(true);
    }
  };
  const LoanSearch = () => {

    return (
      <React.Fragment>
        <SearchBar
          searchQuery={searchQuery}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
        />
      </React.Fragment>
    );
  };



  const extraAction = ({ rowData }: any) => {

    const hasViewPermission = permissionList && permissionList.includes('pages.loan.get');
    return <>
      {hasViewPermission && rowData.status !== 'created' && rowData.status !== 'rejected' && <ActionButton label={t("View")} icon="pi pi-eye" className="p-button-secondary" onClick={() => { setId(rowData._id); setviewDetails(true); }} />}

    </>;
  };

  const ClosedLoanListFilter = () => {

    return (
      <div >
        <Dropdown options={regionList} value={region} placeholder={t("Region") as string}
          onChange={(e) => {
            setRegion(e.value);
            closedloanList(e.value, 1);
            loanSvc.agentList(e.value).then(res => {
              setAgentList(res.data)
            });
            }} 
            optionLabel="region_name" optionValue="_id"   className="ml-1"/>

        <Dropdown options={agentList} value={agent} placeholder={t("Agent") as string}
          onChange={(e) => {setAgent(e.value); closedloanList(region, e.value);}} optionLabel="agent_name" optionValue="_id"  className="ml-1"/>
           <Button icon="pi pi-times " tooltip="Clear" style={{  width: '35px', height: '36px',border:'none' }} onClick={()=>{closedloanList(1,1);setAgent(1);setRegion(1)}} className="ml-2"/>

      </div>
    );
  };

  return (
    <>
       
      <DataGridV2
        tittle={t("Closed Loans")} data={tableData} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} onCreateOrEdit={onEditOrView}
        reload={reload} setReload={setRelaod} deleteByIdList={deleteByIdList}
        extraAction={extraAction} SearchBar={LoanSearch} filter={ClosedLoanListFilter} report={report}/>
      {viewDetails && <LoanDetails id={id} setVisible={setviewDetails} visible={viewDetails} setReload={setRelaod} viewOnly={edit} />}
      <ToastMessage showToast={toast} />
    </>
  );
};

export default ClosedLoanList;
