import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/common/ErrorMessage";
import { loanSvc } from '../../../services';
import CancelButton from "../../../components/styledcomponents/cancelButton";
import { useTranslation } from "react-i18next";
import { ModalSubHeading } from "../../../components/styledcomponents";


const LoanPreClosureModal = ({ setVisible, visible = false, id, updateTable }: IPreClosureModal) => {
    const [loanId, setLoanId] = useState<string | null>(id);
    const [amount, setAmount] = useState<number>(0);
    const [discount, setDiscount] = useState<number>(0);
    const [preClosureAmt, setPreClosureAmt] = useState<any>(null);
    const [error, setError] = useState(false)
    const [t] = useTranslation()




    useEffect(() => {
        if (id)
            loanSvc.getpreclosureamount(id).then(res => {
                if (res) {
                    setPreClosureAmt(res.data)
                }
            })

    }, [id])

    const onSubmit = async () => {
        if (loanId !== null) {
            // if (!amount && !discount) {
            //     setError(true)
            //     return;

            // }
            if (amount < 0 || discount < 0 ||!amount && !discount) {
                setError(true)
                return;
            }
            const req = {
                emi: amount,
                discount: discount,
                loan_id: loanId,
            }
            try {
                const res = await loanSvc.preCloseLoan(req);
                if (res) {
                    updateTable();
                    toast.success("Success");
                    setVisible(false);
                }

            } catch (error) {
                // toast.error("An error occurred ");
            }
        }
    };

    const updateAmountAndDiscount = (updatedAmount: number, updatedDiscount: number) => {
        setError(false)

        setAmount(updatedAmount);
        setDiscount(updatedDiscount);
    };

    const handleAmountChange = (value: number) => {
        const updatedDiscount = preClosureAmt.amnt - value;
        updateAmountAndDiscount(value, updatedDiscount);
    };

    const handleDiscountChange = (value: number) => {
        const updatedAmount = preClosureAmt.amnt - value;
        updateAmountAndDiscount(updatedAmount, value);
    };



    const DialogFooter = (
        <>
            <CancelButton label={t("Cancel") + ''} onClose={() => { setVisible(false) }} />
            <Button label={t("Save") + ''} icon="pi pi-check" className="p-button-success p-button-raised" style={{ background: '#11B3CF', height: '3rem' }} onClick={onSubmit} />
        </>);
    return (
        <Dialog header={preClosureAmt ? `${preClosureAmt.slno} - (${preClosureAmt.customer_name})` : "N/A"} footer={DialogFooter} visible={visible} maximizable style={{ width: '40vw' }} onHide={() => setVisible(false)}>
            <div className="flex flex-row gap-1 font-bold">
                <ModalSubHeading>{t("Pre Closure Amount:")}</ModalSubHeading>
                <ModalSubHeading>
                    {preClosureAmt ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(preClosureAmt.amnt) : 'N/A'}
                </ModalSubHeading>


            </div>

            <div className="flex gap-3">
                <div className="flex flex-column gap-2" style={{ maxWidth: 200 }}>
                    <label htmlFor="preclosureamt" className="font-bold block mb-2">{t("Discount Amount")}<span style={{ color: 'red' }}>*</span></label>
                    <InputNumber placeholder="INR 00.00" inputId="currency-india" value={discount} onChange={(e) => { handleDiscountChange(e.value as number) }} mode="currency" currency="INR" currencyDisplay="code" className={`${error ? 'p-invalid' : ''}`} />
                    {error && <ErrorMessage id={"discountamt"} message="Enter the correct amount" />}

                </div>
                <div className="flex flex-column gap-2" style={{ maxWidth: 200 }}>
                    <label htmlFor="preclosureamt" className="font-bold block mb-2">{t("Repayment Amount")}<span style={{ color: 'red' }}>*</span></label>
                    <InputNumber placeholder="INR 00.00" inputId="currency-india" value={amount} onChange={(e) => { handleAmountChange(e.value as number) }} mode="currency" currency="INR" currencyDisplay="code" className={`${error ? 'p-invalid' : ''}`} />
                    {error && <ErrorMessage id={"amt"} message="Enter the correct amount" />}
                </div>
            </div>
        </Dialog>
    );
};
export default LoanPreClosureModal

interface IPreClosureModal {
    visible: boolean;
    setVisible(val: boolean): void;
    id: string | null;
    updateTable: any
};