import { Cookies } from 'react-cookie';
import{domain}from './constants'
const cookies = new Cookies();

// export const setCookie = (name: string, value: any, options?: any) => {
//   cookies.set(name, value, options);
// };

export const setCookie = (name: string, value: any, options?: any) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 30); // Set expiration to 30 days from now
  options = { ...options, expires: expirationDate ,domain:domain };
  console.log ("set cookie is",domain)
  cookies.set(name, value, options);
};

export const getCookie = (name: string) => {
  return cookies.get(name);
};

export const removeCookie = (name: string) => {
  cookies.remove(name ,{domain:domain});
  console.log ("remove cookie is",domain)
};
