import React, { useEffect, useState } from "react";
import { Dialog } from 'primereact/dialog';
import moment from 'moment'
import { repaymentSvc } from '../../../services';
import { useTranslation } from "react-i18next";


const RePaymentDetails = ({ setViewDeatails, viewDetails = false, id }: IRepaymentDetails) => {
    const [loanDetails, setLoanDetails] = useState<any>(null)
    const [t, i18n] = useTranslation()
    useEffect(() => {
        if (id) {
            repaymentSvc.getLoanDetails(id).then(res => {
                if (res.data) {
                    setLoanDetails(res.data)
                }
            })

        }
    }, [id])


    return (
        <div className="card flex justify-content-center">
            <Dialog header={t("Repayment Details")} visible={viewDetails} style={{ width: '80vw' }} onHide={() => setViewDeatails(false)} maximizable >
                <div id="invoice-content">
                    <div className="invoice-wrapper">
                        <div className="invoice-content">
                            <div className="invoice-footer grid grid-nogutter">
                                <div className="col-12 md:col-6">
                                    <div className="invoice-table-2 grid grid-nogutter">
                                        <div className="col-6">
                                            <div className="invoice-table-col header-col">
                                                <span>{t("Customer")}</span>
                                                <span>{t("Principal Amount")}</span>
                                                <span>{t("Borrow Type")}</span>
                                                <span>{t("Disburse Type")}</span>
                                                <span>{t("Start On")}</span>
                                                <span>{t("End On")}</span>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="invoice-table-col content-col">
                                                <span>{loanDetails ? loanDetails.customerData.customer_id.first_name.concat(' ', loanDetails.customerData.customer_id.last_name) : 'N/A'}</span>
                                                <span>{loanDetails ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loanDetails.loanData.principal_amt) : 'N/A'}</span>
                                                <span className="capitalize">{t(loanDetails ? loanDetails.customerData.borrow_type : 'N/A')}</span>
                                                <span className="capitalize">{t(loanDetails ? loanDetails.customerData.disburse_type : 'N/A')}</span>
                                                <span>{loanDetails ? moment(loanDetails.loanData.start_on).format('ll') : 'N/A'}</span>
                                                <span>{loanDetails ? moment(loanDetails.loanData.end_on).format('ll') : 'N/A'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="invoice-table-2 summary grid grid-nogutter">
                                        <div className="col-6">
                                            <div className="invoice-table-col header-col">
                                                <span>{t("Total Installments")}</span>
                                                <span>{t("Next Installment")}</span>
                                                <span>{t("Base EMI")}</span>
                                                {/* <span>Remaining Installments </span> */}
                                                <span>{t("Due Date")}</span>
                                                <span>{t("EMI Amount")}</span>
                                                {loanDetails?.customerData.borrow_type === "weekly" &&(<span>{t("Repaid Amount")}</span>)}
                                                <hr />
                                                <span className="total">{t("Balance To Pay")}</span>
                                            </div>

                                        </div>
                                        <div className="col-6">
                                            <div className="invoice-table-col content-col">
                                                <span>{loanDetails ? loanDetails.loanData.installments : 'N/A'}</span>
                                                <span>{loanDetails ? loanDetails.loanData.current_installment : 'N/A'}</span>
                                                {/* <span>{loanDetails ? ((loanDetails.loanData.installments - loanDetails.loanData.current_installment)+1) : 'N/A'}</span> */}
                                                <span>{loanDetails ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loanDetails.loanData.base_emi) : 'N/A'}</span>
                                                <span>{loanDetails ? moment(loanDetails.loanData.next_emi_on).format('ll') : 'N/A'}</span>
                                                <span>{loanDetails ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loanDetails.loanData.next_emi) : 'N/A'}</span>
                                                {loanDetails?.customerData.borrow_type === "weekly" &&
                                                    (<span>{loanDetails ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loanDetails.loanData.repayed_amt) : 'N/A'}</span>)}

                                                <hr />
                                                <span className="total">{loanDetails ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loanDetails.loanData.principal_amt - loanDetails.loanData.repayed_amt) : 'N/A'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
};

export default RePaymentDetails;



interface IRepaymentDetails {
    viewDetails: boolean;
    setViewDeatails(val: boolean): void;
    id: string | null;
};
