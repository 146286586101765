import { axiosInstance } from '../interceptors';

export const save = async (request: any) => {
    return await axiosInstance.post(`/master/addAgency/`, request);
  };

export const list = async (limit: string,page: string) => {
    return await axiosInstance.get(`/master/listAgencies?page=${page}&limit=${limit}`);
};
export const get = async (id:string) => {
    return await axiosInstance.get(`/master/agency/getById/${id}`);
  };

  export const getAutoSuggest = async (name:string) => {
    return await axiosInstance.get(`/master/agency/autosuggest/${name}`);
  };
  
  export const searchAgent = async (name:any) => {
    return await axiosInstance.get(`/master/agent/search/${name}`);
  };

  export const getAutoSuggestById = async (id:string) => {
    return await axiosInstance.get(`/master/agency/getAutoSuggestById?agencyId=${id}`);
  };

  export const update = async (id: string, request: any) => {
    return await axiosInstance.put(`/master/updateAgency?agencyId=${id}`,request)
  };
  
  export const statusUpdate = async (id: string, request: any) => {
    return await axiosInstance.put(`/master/agency/setStatusById/${id}`,{status:request})
  };
  
  export const Delete = async (id:string) => {
    return await  axiosInstance.delete(`/master/deleteAgency/${id}`);
  }
  export const multipleDelete = async (idList:any) => {
    return await  axiosInstance.post(`/master/agency/multipleDelete/`,idList);
  }


