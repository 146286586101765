import React, { useState, useEffect } from "react";
import { repaymentSvc } from '../../../services';
import { useTranslation } from "react-i18next";
import DataGridV3 from "../../../components/datagrid/DataGridV3";

const PaidList: React.FC = () => {
  const [paidList, setpaidList] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');
  const [t, i18n] = useTranslation()
  
  const columns = [
    { field: 'loan_id.sl_no', header: 'Loan Id' },
    { field: 'loan_id.customer_id.first_name', header: 'customer name' },
    // { field: 'loan_id.approved_amt', header: 'Approved Amount' },
    { field: 'paid_amount', header: 'Paid Amount', type: 'amount' },
    { field: 'paid_on', header: 'Paid On', type: 'date' },
    { field: 'due_date', header: 'Due Date', type: 'date' },
    { field: 'pending_paid', header: 'Pending Paid Amount', type: 'amount' },
  ];

  useEffect(() => {
    getPaidList();
  }, [page, limit]);

  const getPaidList = () => {
    repaymentSvc.paidList(String(limit), String(page)).then((res: any) => {
      setpaidList(res.data.data);
      settotalRecords(res.data.recordcount);
    });
  };

  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };

  return (
    <DataGridV3 tittle={t("Paid List")} data={paidList} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} extraAction={undefined} />
  );
};
export default PaidList
