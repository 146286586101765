import * as Yup from 'yup';

export const InterestRateSave = Yup.object().shape({
    borrow_Type: Yup.string().required('Required'),
    disburse_Type: Yup.string().required("Required"),
    //  emi_Interest: Yup.number().required('Required').typeError('Required'),
    emi_Interest: Yup.number().when('borrow_Type', {
        is: 'weekly',
        then: Yup.number().required('Required').typeError('Required'),
        otherwise: Yup.number()
    }),
    rate: Yup.number().required('Required').typeError('Required'),
});