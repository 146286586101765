import React, { useRef, useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { repaymentSvc } from '../../../../services';
import RepaymentModal from './repaymentModal';
import '../master.css'
import InputGroup from 'react-bootstrap/esm/InputGroup';
import { InputText } from 'primereact/inputtext';
import ActionButton from '../../../../components/common/buttons/actionButton';
import { Heading } from '../../../../components/styledcomponents';

const RepaymentSettings = () => {
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [selected, setSelected] = useState([]);
    const [repaymentSettings, setRepaymentSettings] = useState([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(true);
    const [id, setId] = useState<string>('');
    const dt = useRef<DataTable<any>>(null);
    const [t, i18n] = useTranslation()
    const [reload, setReload] = useState<boolean>(true)

    const actionBodyTemplate = (rowData: any) => {
        return (
            <>
                <ActionButton icon="pi pi-pencil" label={t("Edit") + ''} className="p-button-secondary" onClick={() => onEditOrView(true, rowData._id)} />
            </>
        );
    };


    const onEditOrView = (edit: boolean, id?: string) => {
        setEdit(edit);
        if (id)
            setId(id);
        setVisible(true);

    };

    useEffect(() => {
        if (!visible)
            setId('');
    }, [visible]);

    useEffect(() => {
        if (reload) {
            repaymentSvc.repaymentSettingsList().then((res: any) => {
                setRepaymentSettings(res.data);
                setReload(false)

            }).catch(() => { setReload(false) });
        }
    }, [reload])

    const dayNames = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];

    const dayBodyTemplate = (rowData: any) => {
        const day = rowData.day;
        const borrow_type = rowData.borrow_type;
        const dayName = dayNames[day - 1];
        // return <span>{dayName}</span>;
        return <span>{borrow_type === 'weekly' ? dayName : rowData.day}</span>;
    };
    return (
        <React.Fragment>
            <div className="grid">
                <div className="col-12">
                    <div><Heading className="m-0">{t('Loan Settings')}</Heading></div>
                    <div className='master-toolbar'>
                        <div>
                            <div className="search-bar mt-1">
                                <InputGroup>
                                    <span className="p-input-icon-left">
                                        <i className="pi pi-search" />
                                        <InputText
                                            onInput={(e: any) => setGlobalFilter(e.target.value)}
                                            placeholder={t("Search...") as string}
                                            style={{ width: '300px', borderRadius: '0px', height: '2.5rem' }}
                                        />
                                    </span>
                                    <Button label={t("Search") + ''} style={{ backgroundColor: '#11B3CF', height: '2.5rem' }} className='p-button-success' />
                                </InputGroup>
                            </div>
                            {/* <Button label={t("delete")+''} icon="pi pi-trash" className="p-button-danger p-button-sm" onClick={() => { }} disabled={!selected || !selected.length} style={{ display: selected && selected.length>=2 ? 'inline-block' : 'none' }} /> */}
                        </div>
                        <div>
                            <Button tooltip='Reload' tooltipOptions={{ position: "mouse" }} label="" icon={`pi pi-refresh ${reload ? 'pi-spin' : ''}`} style={{ backgroundColor: '#11B3CF',width: '40px', height: '40px' }} className="p-button-success p-button-rounded p-button-raised p-button-secondary  mr-2" onClick={() => { setReload(true); }} />
                            <Button tooltip='Create' tooltipOptions={{ position: "mouse" }} label={t("Add New") as string} icon="pi pi-plus" style={{ backgroundColor: '#11B3CF' }} className="p-button  p-button-raised p-button-success  mr-2" onClick={() => { onEditOrView(false); }} />
                        </div>
                    </div>
                    <DataTable
                        ref={dt}
                        value={repaymentSettings}
                        selection={selected}
                        onSelectionChange={(e: any) => setSelected(e.value)}
                        dataKey="_id"
                        className="datatable-responsive master-datatable"
                        globalFilter={globalFilter}
                        emptyMessage="No data found."
                    >
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} selectionMode="multiple" headerStyle={{ width: '4rem' }}></Column>
                        <Column className="capitalize" style={{ flexGrow: 1, flexBasis: '200px' }} header={t("Borrow Type")} field='borrow_type' ></Column>
                        <Column className="capitalize" style={{ flexGrow: 1, flexBasis: '200px' }} header={t("Day")} field='day' body={dayBodyTemplate} />
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} header={t("Maximum Buzzer")} field='maximum_buzzer' ></Column>
                        <Column header={t("action")} style={{ textAlign: 'center', flexGrow: 1, flexBasis: '200px' }} alignHeader={'center'} body={actionBodyTemplate} ></Column>
                    </DataTable>

                </div>
            </div>
            {visible && <RepaymentModal visible={visible} setVisible={setVisible} viewOnly={edit} id={id} setReload={setReload} />}
        </React.Fragment>
    );
};

export default RepaymentSettings;
