import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Divider } from "primereact/divider";
import { useContext, useEffect, useRef, useState } from "react";
import { Steps } from 'primereact/steps';
import { FieldArray, Form, Formik, getIn } from "formik";
import { userSave } from "../../../validation";
import ErrorMessage from "../../../components/common/ErrorMessage";
import { Dropdown } from "primereact/dropdown";
import { userSvc } from '../../../services'
// import { toast } from "react-toastify";
import { SelectButton } from 'primereact/selectbutton'
import { useTranslation } from "react-i18next";
import { CancelButton } from "../../../components/styledcomponents";
import ToastMessage from "../../../components/common/toastMessage/toastMessage";
import { AuthContext } from "../../../context";
const UserModal = ({ setVisible, visible = false, id, updateTableData }: IUserModal) => {
    const { userId } = useContext(AuthContext);
    const ini = {
        email: '',
        phone: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        gender: '',
        enable_login: '',
        address: [{
            country: '',
            state: '',
            city: '',
            district: '',
            pin_code: '',
            address_type: ''
        }],
        created_by:userId,
    };
    const [stage, setStage] = useState(0);
    const [usersId, setUserId] = useState(id);
    const [initialValues, setInitialValues] = useState(ini);
    const [t] = useTranslation()

    const items = [
        {
            label: 'Basics'
        },
        {
            label: 'Address'
        }
    ];

    const genderOptions = [{ label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Other', value: 'other' }];

    const loginOptions = [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
    ];
    const address_type = [{ label: 'Permanent', value: 'permanent' },
    { label: 'Current', value: 'current' },
    { label: 'Emergency', value: 'emergency' }];
    const toast = useRef<any>(null);
    const onSubmit = async (e: any, actions: any) => {
        if (usersId) {
            await userSvc.update(usersId, e);
        }
        else {
            await userSvc.save(e);
        }
        toast.current.show({ severity: 'success', summary: 'Success', detail: id ? 'Updated' : 'Created', life: 1000 });
        updateTableData();
        onClose();
        actions.setSubmitting(false);
    };
    
    useEffect(() => {
        setUserId(id);
        if (id && visible) {
            userSvc.get(id).then(res => setInitialValues({ ...res.data, address: res.data.address.length === 0 ? initialValues.address : res.data.address }));
        }
    }, [id, visible]);

    const onClose = () => {
        setVisible(false);
        setUserId(undefined);
        setInitialValues(ini);
        setStage(0);
    };
    return (
        <><ToastMessage showToast={toast} />
        <Dialog header={!id ? t("Create User") as string : t("Update User") as string } visible={visible} maximizable style={{ width: '50vw' }} onHide={onClose}>
            <Steps model={items} className="my-3" activeIndex={stage} />
            <Formik enableReinitialize initialValues={initialValues} validationSchema={userSave} onSubmit={onSubmit}>
                {({ isSubmitting, errors, getFieldProps, touched, setFieldValue, values, isValid }) => (
                    <Form>
                        {stage === 0 && <div className="grid p-fluid mt-3">
                            <div className="field col-12 md:col-4">
                                <span className="p-float-label">
                                    <InputText type="text" id="first_name" {...getFieldProps(('first_name'))} name='first_name' />
                                    <label htmlFor="first_name" className="label-raleway-font">{t("First Name")}</label>
                                </span>
                                {touched.first_name && errors.first_name && <ErrorMessage message={errors.first_name} id="first_name" />}
                            </div>

                            <div className="field col-12 md:col-4">
                                <span className="p-float-label">
                                    <InputText id="middle_name" {...getFieldProps(('middle_name'))} name='middle_name' />
                                    <label htmlFor="middle_name" className="label-raleway-font">{t("Middle Name")}</label>
                                </span>
                                {touched.middle_name && errors.middle_name && <ErrorMessage message={errors.middle_name} id="middle_name" />}
                            </div>
                            <div className="field col-12 md:col-4">
                                <span className="p-float-label ">
                                    <InputText id="last_name" {...getFieldProps(('last_name'))} name='last_name' />
                                    <label htmlFor="last_name" className="label-raleway-font">{t("Last Name")}</label>
                                </span>
                                {touched.last_name && errors.last_name && <ErrorMessage message={errors.last_name} id="last_name" />}
                            </div>

                            <div className="field col-12 md:col-4">
                                <span className="p-float-label">
                                    <InputText id="email" {...getFieldProps(('email'))} name='email' disabled={!!id} />
                                    <label htmlFor="email" className="label-raleway-font">{t("Email")}</label>
                                </span>
                                {touched.email && errors.email && <ErrorMessage message={errors.email} id="email" />}
                            </div>
                            <div className="field col-12 md:col-4">
                                <span className="p-float-label">
                                    <InputMask mask="999 999-9999" id="phone" {...getFieldProps(('phone'))} name='phone' />
                                    <label htmlFor="phone" className="label-raleway-font">{t("Phone")}</label>
                                </span>
                                {touched.phone && errors.phone && <ErrorMessage message={errors.phone} id="phone" />}
                            </div>
                            <div className="field col-12 md:col-4">
                                <span className="p-float-label">
                                    <Dropdown options={genderOptions} value={values.gender}
                                        onChange={(e) => setFieldValue('gender', e.value)} disabled={!!id} />


                                    <label htmlFor="gender" className="label-raleway-font"> {t("Gender")}</label>
                                </span>
                                {touched.gender && errors.gender && <ErrorMessage message={errors.gender} id="gender" />}
                            </div>
                            <div className="field col-12 md:col-3">
                                <div className="p-field-checkbox">
                                    <label htmlFor="enable_login" className="label-raleway-font">{t("Login Enable")} </label>
                                    <SelectButton value={values.enable_login} onChange={(e) => setFieldValue('enable_login', e.value)} options={loginOptions} />
                                    {touched.enable_login && errors.enable_login && <ErrorMessage message={errors.enable_login} id="enable_login" />}
                                </div>
                            </div>




                        </div>}
                        {stage === 1 &&
                            <FieldArray
                                name="address"
                                render={arrayHelpers => (
                                    <>
                                        {values.address.map((val, index) => (
                                            <div className="grid p-fluid mt-3" key={index}>
                                                <Divider align="left">
                                                    <div className="inline-flex align-items-center">
                                                        <i className="pi pi-user mr-2"></i>
                                                        <b>{t("Address")} {index + 1}</b>
                                                    </div>
                                                </Divider>
                                                <div className="field col-12 md:col-4">
                                                    <span className="p-float-label">
                                                        <InputText id={`address.${index}.country`} {...getFieldProps((`address.${index}.country`))} name={`address.${index}.country`} />
                                                        <label htmlFor={`address.${index}.country`} className="label-raleway-font">{t("Country")}</label>
                                                    </span>
                                                    {errors.address?.at(index) && <ErrorMessage message={getIn(errors.address?.at(index), 'country')} id={`address.${index}.country`} />}
                                                </div>
                                                <div className="field col-12 md:col-4">
                                                    <span className="p-float-label">
                                                        <InputText id={`address.${index}.state`} {...getFieldProps((`address.${index}.state`))} name={`address.${index}.state`} />
                                                        <label htmlFor={`address.${index}.state`} className="label-raleway-font">{t("State")}</label>
                                                    </span>
                                                    {errors.address?.at(index) && <ErrorMessage message={getIn(errors.address?.at(index), 'state')} id={`address.${index}.state`} />}
                                                </div>
                                                <div className="field col-12 md:col-4">
                                                    <span className="p-float-label">
                                                        <InputText id={`address.${index}.city`} {...getFieldProps((`address.${index}.city`))} name={`address.${index}.city`} />
                                                        <label htmlFor={`address.${index}.city`} className="label-raleway-font">{t("City")}</label>
                                                    </span>
                                                    {errors.address?.at(index) && <ErrorMessage message={getIn(errors.address?.at(index), 'city')} id={`address.${index}.city`} />}
                                                </div>
                                                <div className="field col-12 md:col-4">
                                                    <span className="p-float-label">
                                                        <InputText id={`address.${index}.district`} {...getFieldProps((`address.${index}.district`))} name={`address.${index}.district`} />
                                                        <label htmlFor={`address.${index}.district`} className="label-raleway-font">{t("District")}</label>
                                                    </span>
                                                    {errors.address?.at(index) && <ErrorMessage message={getIn(errors.address?.at(index), 'district')} id={`address.${index}.district`} />}

                                                </div>
                                                <div className="field col-12 md:col-4">
                                                    <span className="p-float-label">
                                                        <InputText id={`address.${index}.pin_code`} {...getFieldProps((`address.${index}.pin_code`))} name={`address.${index}.pin_code`} keyfilter="int" />
                                                        <label htmlFor={`address.${index}.pin_code`} className="label-raleway-font">{t("Pin Code")}</label>
                                                    </span>
                                                    {errors.address?.at(index) && <ErrorMessage message={getIn(errors.address?.at(index), 'pin_code')} id={`address.${index}.pin_code`} />}
                                                </div>
                                                <div className="field col-12 md:col-4">
                                                    <span className="p-float-label">
                                                        <Dropdown options={address_type} id={`address.${index}.address_type`} value={values.address[index].address_type} onChange={(e) => { setFieldValue(`address.${index}.address_type`, e.value); } } name={`address.${index}.address_type`} />
                                                        <label htmlFor={`address.${index}.address_type`} className="label-raleway-font">{t("Address Type")}</label>
                                                    </span>
                                                    {errors.address?.at(index) && <ErrorMessage message={getIn(errors.address?.at(index), 'address_type')} id={`address.${index}.address_type`} />}
                                                </div>
                                                <div className="field col-12 md:col-4 ">
                                                    <Button type="button" icon="pi pi-plus" className="p-button-primary mr-2" aria-label="add" onClick={() => arrayHelpers.push({
                                                        country: '',
                                                        state: '',
                                                        city: '',
                                                        district: '',
                                                        pin_code: '',
                                                        address_type: ''
                                                    })} hidden={values.address.length > index + 1} disabled={!!errors.address} />
                                                    <Button type="button" icon="pi pi-trash" className="p-button-danger" hidden={index === 0} onClick={() => arrayHelpers.remove(index)} />
                                                </div>
                                            </div>

                                        ))}</>
                                )} />}
                        <div className="flex flex-wrap justify-content-end gap-3 mt-3">
                            <CancelButton label={t("Cancel") + ''} onClose={onClose} />
                            <Button label={t("Prev") + ''} type="button" icon="pi pi-angle-double-left" className="p-button-text p-button-raised"  onClick={() => { setStage(pre => pre - 1); } } disabled={stage === 0} />
                            <Button label={t("Next") + ''} type="button" icon="pi pi-angle-double-right" className="p-button-text p-button-raised" iconPos="right" onClick={() => { setStage(pre => pre + 1); } } disabled={stage === 1} />
                            <Button type="submit" label={id ? t("Update") as string : t("Save") as string} icon="pi pi-check" className="p-button-success p-button-raised" style={{ background: '#11B3CF' }} loading={isSubmitting} />
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog></>
    );
};

export default UserModal;



interface IUserModal {
    visible: boolean;
    setVisible(val: boolean): void;
    id: string | undefined;
    updateTableData: any;
}