// import * as React from 'react';

export interface IAppProps {
}

export default function AuthLayout (props: IAppProps) {
  return (
    <div>
        <h6>AuthLayout</h6>
    </div>
  );
}
