import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { customerSvc, repaymentSvc } from '../../../services';
import RePaymentModal from './repaymentModal';
import RePaymentDetails from './repamentDetails';
import { Form, Formik, FormikHelpers } from 'formik';
import Irepayment from '../../../interfaces/models/repayment';
import ErrorMessage from '../../../components/common/ErrorMessage';
import { InputText } from 'primereact/inputtext';
import RepaymentHistory from './repaymentHistory';
import useDebounce from "../../../helpers/debounce";
import { AutoComplete } from 'primereact/autocomplete';
import { Heading } from '../../../components/styledcomponents';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ActionButton from '../../../components/common/buttons/actionButton';
import moment from 'moment';
import InputGroup from 'react-bootstrap/esm/InputGroup';



const RePaymentList = () => {
  const [t] = useTranslation()
  const [visible, setVisible] = useState<boolean>(false);
  const [viewDetails, setViewDeatails] = useState<boolean>(false);
  const [viewHistory, setViewHistory] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const [dataTableVisible, setDataTableVisible] = useState(false)


  const initial: Irepayment = {
    customer_id: "",
    loan_id: "",
  }
  const [initialValues, setInitialValues] = useState<Irepayment>(initial)
  const [loanData, setLoanData] = useState<any>([]);
  const [customerData, setCustomerData] = useState<any>('');
  const [customerSelected, setCustomerSelected] = useState(null);
  const [customer, setCustomer] = useState([]);
  const [customerSearch, setcustomerSearch] = useState<string>('');
  const debouncedValue = useDebounce<string>(customerSearch, 500);

  let error: any = null;

  useEffect(() => {
    if (debouncedValue && debouncedValue.length > 0)
      customerSvc.getAutoSuggest(customerSearch).then(res => {
        setCustomer(res.data);
      });
  }, [debouncedValue]);

  const onSubmit = async (values: Irepayment, formikHelpers: FormikHelpers<Irepayment>) => {
    try {
      if (!values.customer_id && !values.loan_id) {
        error = true
      }
      else {
        let res;
        if (values.customer_id) {
          res = await repaymentSvc.get(values.customer_id);

        } else {
          res = await repaymentSvc.getByLoanId(values.loan_id);
        }
        formikHelpers.resetForm();
        setLoanData(res.data.loandata)
        setCustomerData(res.data.customerData)
        setDataTableVisible(true);
      }
    } catch (error) {
      setDataTableVisible(false);
    }
    setCustomerSelected(null);
  };

  const onEditOrView = (edit: boolean, id?: string) => {
    if (id)
      setId(id);
    setViewDeatails(true);
  };

  const getIndianFormattedDate = (date: any) => {
    return moment(date).format('DD/MM/YYYY');
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold" style={{fontFamily:'Raleway'}}><span>{t("Customer Name :")} </span>
        {`${customerData?.first_name || ''} ${customerData?.middle_name || ''} ${customerData?.last_name || ''}`}
        </span>
    </div>
  );

  const actionBodyTemplate = (rowData: any) => {
    return <>
      <ActionButton label={t("Pay EMI") as string} icon="pi pi-money-bill" className="p-button-secondary" onClick={() => { setVisible(true); setId(rowData._id) }} />
      <ActionButton label={t("Pay Over Due") as string} icon="pi pi-clock" className="p-button-secondary" onClick={() => { setViewHistory(true); setId(rowData._id) }} />
      <ActionButton label={t("Repayment Details") as string} className="p-button-secondary" icon="pi pi-info-circle" onClick={() => onEditOrView(true, rowData._id)} />
    </>;
  };
  
  return (
    <React.Fragment>
      <div className='repaymentAmt'>
        <div><Heading className="mb-4">{t("Repayment")}</Heading></div>
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
          {
            ({ values, errors, getFieldProps, touched, setFieldValue }) => (
              <Form>
                <div className="grid p-fluid mt-3">

                  <div className="p-inputgroup field col-12 md:col-6">
                    <span className="p-float-label">
                      <div className="p-inputgroup">
                        <AutoComplete field="name" value={customerSelected} suggestions={customer} completeMethod={(e) => { setcustomerSearch(e.query); }}
                          onChange={(e) => { setFieldValue("customer_id", e.value.value); setCustomerSelected(e.value); }} />
                        <label htmlFor="customer" className='ml-2 label-raleway-font '>{t("customer name")}</label>
                        <Button icon="pi pi-search" label={t("Search") as string} style={{ backgroundColor: "#11B3CF" }} className="p-button-success" type='submit' />
                      </div>
                    </span>
                    <div className='ml-3 '>
                      {error && <ErrorMessage id={"customer_name"} message="Required" />}
                    </div>

                  </div>


                  <div className="p-inputgroup field col-12 md:col-6">
                    <span className="p-float-label">
                      <InputText id="loan_id" {...getFieldProps('loan_id')} value={values.loan_id}
                      />
                      <label htmlFor="loan_id" className='label-raleway-font '>{t("Loan Id")}</label>
                      <Button icon="pi pi-search" label={t("Search") as string} style={{ backgroundColor: "#11B3CF" }} className="p-button-success" type='submit' />
                    </span>
                    <div className='ml-3 '>
                      {error && <ErrorMessage id={"loan_id"} message="Required" />}
                    </div>
                  </div>

                </div>

              </Form>)
          }
        </Formik>
        {dataTableVisible ?

          <div>
            <DataTable
              value={loanData}
              header={header}
              scrollable
              scrollHeight="400px"

              className="datatable-responsive custom-datatable"
              style={{ minWidth: '50rem' }}>
              <Column style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} field="sl_no" header="Sl No"></Column>
              <Column style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} bodyStyle={{ textAlign: 'center' }} alignHeader={'center'} header={t('Approved On')}body={(rowData: any) => {
                const approvedOn = new Date(rowData.approved_on); return <span>{getIndianFormattedDate(approvedOn)}</span>; }}/>
              <Column header={t("action")} className="action" body={actionBodyTemplate} bodyStyle={{ textAlign: 'center' }}  alignHeader={'center'} style={{ minWidth: '150px' }} alignFrozen="right" frozen></Column>

            </DataTable>

          </div> :
          <div style={{ height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div className='raleway-font'>{t("Search a loan for repayment")}</div>
            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none' }}>
              <i className="pi pi-search" style={{ fontSize: '8em', color: "#11B3CF" }}></i>
            </span>
          </div>
        }

      </div>

      {viewHistory && <RepaymentHistory viewHistory={viewHistory} setViewHistory={setViewHistory} id={id} />}
      {viewDetails && <RePaymentDetails viewDetails={viewDetails} setViewDeatails={setViewDeatails} id={id} />}
      {visible && <RePaymentModal visible={visible} setVisible={setVisible} id={id} />}
    </React.Fragment>
  );
};

export default RePaymentList;
