import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/common/ErrorMessage";
import { repaymentSvc } from '../../../services';
import { CancelButton, Essential } from "../../../components/styledcomponents";
import { Calendar } from "primereact/calendar";
import { useTranslation } from "react-i18next";
import { Checkbox } from "primereact/checkbox";

const RePaymentModal = ({ setVisible, visible = false, id }: IRepaymentModal) => {
    const [loanId, setLoanId] = useState<string | null>(id);
    const [repayAmt, setRepayAmt] = useState<any>("");
    const [emi, setEmi] = useState<any>(null);
    const [currentInstallment, setCurrentInstallment] = useState<any>(null);
    const [pendingAmnt, setPendingAmnt] = useState<any>(null);
    const [date, setDate] = useState<Date | null>(null);
    const [customerDetails, setCustomerDetails] = useState<any>(null)
    const [isOveride, setIsOveride] = useState<any>(false);
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false);//Disable save button in api call
    const [t] = useTranslation()
    useEffect(() => {
        if (id)
            repaymentSvc.getLoanDetails(id).then(res => {
                if (res.data) {
                    setEmi(res.data.loanData)
                    setCurrentInstallment(res.data.loanData.current_installment)
                    setCustomerDetails(res.data.customerData)
                    setPendingAmnt(res.data.pendingAmnt)
                }
            })


    }, [id])

    

    const onSubmit = async () => {
        try {
            if (loanId === null || !repayAmt || isNaN(Number(repayAmt)) || date === null) {
                setError(true);
                return error;
            }
    
            if (loanId && date && repayAmt) {
                setIsLoading(true);
                const desiredFormat = "YYYY-MM-DD HH:mm:ss";
                const convertedFormat = moment(date).format(desiredFormat);
    
                let serverDateValue = null;
                // check repayment date and current date 
                if (moment(date).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
                    serverDateValue = convertedFormat;
                }
                const res = await repaymentSvc.save(loanId, Number(repayAmt), serverDateValue, isOveride);
                if (res) {
                    setVisible(false);
                    toast.success("Success");
                }
            }
        } catch (error) {
            
            // toast.error("An error occurred while processing the request.");
        } finally {
            setIsLoading(false)
           
        }
    };

    const DialogFooter = (
        <>

            <CancelButton label={"Cancel"} onClose={() => { setVisible(false) }} />
            <Button label='Save' icon="pi pi-check" style={{ background: '#11B3CF', border: 'none' }} className="p-button  p-button-raised" onClick={onSubmit} loading={isLoading} />
        </>);
    return (
        <Dialog header={customerDetails ? customerDetails.sl_no : 'N/A'} footer={DialogFooter} visible={visible} maximizable style={{ width: '40vw' }} onHide={() => setVisible(false)}>
            <div className="flex flex-row gap-1 font-bold">
                <p>{t("EMI Amount :")}</p>
                <p >
                    {emi ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(emi.next_emi) : 'N/A'}
                </p>

                <Essential className="ml-3">{t("Due Date :")}</Essential>
                <p >{emi ? moment(emi.next_emi_on).format('ll') : 'N/A'}</p>
                {pendingAmnt ? <Essential className="ml-3">{t("Pending Amount :")}</Essential> : ""}
                <Essential >
                    {pendingAmnt ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(pendingAmnt) : ''}
                </Essential>
            </div>


            <div className="flex flex-row gap-5" style={{ maxWidth: 400 }}>
                <div className="flex-1">
                    <label htmlFor="repayment-amt" className="font-bold block mb-2">{t("Repayment Amount")}<span style={{ color: 'red' }}>*</span></label>
                    <InputNumber placeholder="INR 00.00" inputId="currency-india" value={repayAmt} onValueChange={(e) => setRepayAmt(e.value)} mode="currency" currency="INR" currencyDisplay="code" />
                    {error && !repayAmt && <ErrorMessage id={"repayment-amt"} message="Required" />}
                </div>
                <div>
                    <label htmlFor="repayment-date" className="font-bold block mb-2">{t("Repayment Date")}<span style={{ color: 'red' }}>*</span></label>
                    <Calendar value={date} onChange={(e) => setDate(e.value as Date)} dateFormat="dd/mm/yy" showIcon showButtonBar />
                    <div className="ml-6">{date === null && error && <ErrorMessage message={"Please select a date"} id="calender" />}</div>
                </div>

            </div>

           {currentInstallment===1 && <div className="mt-4">
                <label htmlFor="override" className="font-bold block mb-2">{t("Do you have override payment schedule")}</label>
                <Checkbox onChange={e => setIsOveride(e.checked)} checked={isOveride}></Checkbox>
            </div>
}
        </Dialog>
    );
};
export default RePaymentModal

interface IRepaymentModal {
    visible: boolean;
    setVisible(val: boolean): void;
    id: string | null;
};