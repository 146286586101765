import React, { useEffect, useState } from "react";
// import './loanDetails.css'
import { Dialog } from 'primereact/dialog';
import { IProps } from "../../../../interfaces";
import { chitfundSvc, customerSvc, loanSvc } from "../../../../services";
import { Timeline } from 'primereact/timeline';
import moment from 'moment'
import { useTranslation } from "react-i18next";
import { Badge } from "primereact/badge";
import { Essential, Heading } from "../../../../components/styledcomponents";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function ChitDetails({ setVisible, visible = false, viewOnly = true, id, setReload }: IProps) {
    const [customer, setCustomer] = useState<any>()
    const [t, i18n] = useTranslation()
    const [chitDetails, setChitDetails] = useState<any>(null)
    const [chitData, setChitData] = useState<any>(null)
    const [customerData, setCustomerData] = useState<any>([])
    const [page, setPage] = useState<number>(1); // State for page
    const [limit, setLimit] = useState<number>(200); // State for limit
    useEffect(() => {
        if (id) {
            chitfundSvc.getChitDetails(id).then(res => {
                if (res.data) {
                    setChitDetails(res.data.data)
                    setChitData(res.data.chitData)
                }
            })

            chitfundSvc.getChitCustomers(id, page, limit).then(res => {
                if (res.data) {
                    setCustomerData(res.data.data)
                }
            })

        }
    }, [id])




    return (
        <div className="card flex justify-content-center">
            <Dialog header={t("Chit Details")} visible={visible} style={{ width: '70vw' }} onHide={() => setVisible(false)} maximizable >
                <div id="invoice-content">
                    <div className="invoice-wrapper">
                        <div className="invoice-content">
                            <div className="invoice-footer grid grid-nogutter details-font ">
                                <div className="col-6 md:col-6">
                                    <div className="invoice-table-2 grid grid-nogutter">
                                        <div className="col-6">
                                            <div className="invoice-table-col header-col">
                                                <span>{t("Chit No")}</span>
                                                <span>{t("Total Chit Amount")}</span>
                                                <span>{t("Base Installment")}</span>
                                                <span>{t("Total Installments")}</span>
                                                <span>{t("Total Customers")}</span>
                                                <span>{t("Next Installment On")}</span>
                                                <span>{t("Next Installment")}</span>

                                                <span>{t("Status")}</span>
                                                <span>{t("Chit Start Date")}</span>

                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className="invoice-table-col content-col">
                                                <span>{chitDetails && chitDetails.sl_no ? chitDetails.sl_no : 'N/A'}</span>
                                                <span>{chitData ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(chitData.chit_amount) : 'N/A'}</span>
                                                <span>{chitDetails ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(chitDetails.installment_amount) : 'N/A'}</span>
                                                <span className="capitalize">{chitData && chitData.total_installments ? chitData.total_installments : 'N/A'}</span>
                                                <span className="capitalize">{chitDetails && chitDetails.total_customers ? chitDetails.total_customers : 'N/A'}</span>
                                                <span className="capitalize">{chitData ? moment(chitData.next_installment_on).format('ll') : 'N/A'}</span>
                                                <span className="capitalize">{chitData ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(chitData.next_installment_amount) : 'N/A'}</span>
                                                <span className="capitalize">{t(chitDetails && chitDetails.status ? chitDetails.status : 'N/A')}</span>
                                                <span className="capitalize">{chitDetails ? moment(chitDetails.start_date).format('ll') : 'N/A'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <DataTable value={customerData} paginator rows={6} rowsPerPageOptions={[6, 10, 25, 50]}>
                                    <Column header="No" headerStyle={{ width: '3rem' }} body={(data, options) => options.rowIndex + 1}></Column>
                                    <Column field="sl_no" header="Sl No"></Column>
                                    <Column field="full_name" header="Name"></Column>
                                    </DataTable>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}


