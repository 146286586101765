
import { TabMenu } from 'primereact/tabmenu';
import  { useState } from 'react';
import RoleList from './Roles/RoleList';
import InterestRateList from './interestRate/InterestRateList';
import SerialNoList from './Serial_No/SerialNoList';
import PermissionList from './Permissions/PermissionsList';
import RepaymentSettings from './repaymentSettings/repaymentSettings';
import AgencyList from './agency/agencyList';
import RegionList from './region/regionList';
import { useTranslation } from 'react-i18next';
import './master.css'
import { Heading } from '../../../components/styledcomponents';
import ChitInterestList from './chitInterest/chitInterestList';

const MasterView = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [t, i18n] = useTranslation()
    const items = [
        { label: t('Region'), icon: 'pi pi-fw pi-globe' },
        { label: t('Agent'), icon: 'pi pi-fw pi-briefcase' },
        { label: t('Role'), icon: 'pi pi-fw pi-users' },
        { label: t('Permissions'), icon: 'pi pi-fw pi-lock' },
        { label: t('Serial No'), icon: 'pi pi-fw pi-list' },
        { label: t('Interest Rate'), icon: 'pi pi-fw pi-money-bill' },
        { label: t('Loan Settings'), icon: 'pi pi-fw pi-cog' },
        { label: t('Chit Commision'), icon: 'pi pi-fw pi-cog' },
    ];

    return (
        <>
            <div className='flex mb-3'><i className="pi pi-cog" style={{ fontSize: '2rem' }}></i> <Heading className='ml-2 mt-2'>{t("Admin Panel")}</Heading></div>
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => { setActiveIndex(e.index); }} className='master-tabmenu' />
            {activeIndex === 0 && <div className="mt-5">
                <RegionList />
            </div>}
            {activeIndex === 1 && <div className="mt-5">
                <AgencyList />
            </div>}
            {activeIndex === 2 && <div className="mt-5">
                <RoleList />
            </div>}
            {activeIndex === 3 && <div className="mt-5">
                <PermissionList />
            </div>}
            {activeIndex === 4 && <div className="mt-5">
                <SerialNoList />
            </div>}
            {activeIndex === 5 && <div className="mt-5">
                <InterestRateList />
            </div>}
            {activeIndex === 6 && <div className="mt-5">
                <RepaymentSettings />
            </div>}
            {activeIndex === 7 && <div className="mt-5">
                <ChitInterestList />
            </div>}
        </>
    );
};
export default MasterView;