import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';

function SaveButton({ label,  }:any) {

  return (
    <Button
      type="submit"
      label={label ? label : ""}
      icon="pi pi-check"
      className="p-button-success p-button-raised"
      style={{ background: '#11B3CF' }} 
    />
  );
}

export default SaveButton;