import { Form, Formik, FormikHelpers } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { AutoComplete } from "primereact/autocomplete";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/common/ErrorMessage";
import useDebounce from "../../../helpers/debounce";
import { ILoan } from "../../../interfaces";
import { agencySvc, customerSvc, loanSvc, regionSvc } from "../../../services";
import { LoanSave } from "../../../validation";
import { useTranslation } from "react-i18next";
import { CancelButton } from "../../../components/styledcomponents";
import { Calendar } from "primereact/calendar";
import { AuthContext } from "../../../context";
import { ProgressSpinner } from 'primereact/progressspinner';


const LoanModal = ({ setVisible, visible = false, viewOnly = true, id, setReload, loanFilterData, setSelectedStatus }: ILoanModal) => {
    const { userId } = useContext(AuthContext);
    const initial: ILoan = {
        customer_id: "",
        borrow_type: "",
        requested_on: new Date(),
        disburse_type: "",
        requested_amt: 0,
        Installments: 0,
        base_emi: 0,
        region_id: null,
        agent_id: null,
        created_by: userId,
    };
    const borrow_type = [{ label: 'Weekly', value: 'weekly' }, { label: 'Monthly', value: 'monthly' }];
    const disburse_type = [{ label: 'Full', value: 'full' }, { label: 'Reduced', value: 'reduced' }];
    const [initialValues, setInitialValues] = useState<ILoan>(initial);
    const [t] = useTranslation()
    const [itemId, setItemId] = useState<string | null>(id);

    const [regionList, setRegionList] = useState<any>([])
    const [agentList, setAgentList] = useState([])

    const [customer, setCustomer] = useState([]);
    const [customerSelected, setCustomerSelected] = useState(null);
    const [customerSearch, setcustomerSearch] = useState<string>('');
    const debouncedCustomerSearch = useDebounce<string>(customerSearch, 500);

    const [overrideLoanSettings, setOverrideLoanSettings] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(''); //Temporary update  for add agent and region

    useEffect(() => {
        if (debouncedCustomerSearch && debouncedCustomerSearch.length > 0)
            customerSvc.getAutoSuggest(customerSearch).then(res => {
                setCustomer(res.data);
            });
    }, [debouncedCustomerSearch]);


    useEffect(() => {
        loanSvc.regionList().then(res => {
            setRegionList(res.data)
        });
    }, [])

    const agentListByRegionId = (id: string) => {
        loanSvc.agentList(id).then(res => {
            setAgentList(res.data)
        });
    }

    useEffect(() => {
        if (id && visible) {
            setLoading(true)
            loanSvc.get(id).then((res) => {
                setStatus(res.data.status) //Temporary update  for add agent ang region
                const { customer_id, region_id, agent_id } = res.data;

                // Check if agent_id and region_id are not null before making API calls
                const customerPromise = customer_id
                    ? customerSvc.getAutoSuggestById(customer_id)
                    : Promise.resolve(null);

                const regionPromise = region_id
                    ? regionSvc.getAutoSuggestById(region_id)
                    : Promise.resolve(null);

                const agentPromise = agent_id
                    ? agencySvc.getAutoSuggestById(agent_id)
                    : Promise.resolve(null);

                Promise.all([customerPromise, regionPromise, agentPromise])
                    .then(([customerRes, regionRes, agentRes]) => {
                        if (customerRes) {
                            setCustomerSelected(customerRes.data);
                        }
                        if (regionRes) {

                            agentListByRegionId(regionRes.data.value)
                        }
                        // if (agentRes) {
                        //     setAgentSelected(agentRes.data);

                        // }
                        setInitialValues({ ...res.data });

                        if (res.data.Installments > 0 && res.data.base_emi === 0) {
                            setSelectedOption('installments');
                            setOverrideLoanSettings(true);
                        } else if (res.data.base_emi > 0 && res.data.Installments === 0) {
                            setSelectedOption('emiAmount');
                            setOverrideLoanSettings(true);
                        }
                        setLoading(false)
                    })
                    .catch((error) => {
                        // Handle errors here
                        console.error("Error fetching related data:", error);
                        setLoading(false)
                    });
            });
        }
    }, [itemId, visible]);



    const onSubmit = async (values: ILoan, formikHelpers: FormikHelpers<ILoan>) => {
        const updatedValues: ILoan = {
            ...values,
            Installments: overrideLoanSettings && selectedOption === 'installments' ? values.Installments : 0,
            base_emi: overrideLoanSettings && selectedOption === 'emiAmount' ? values.base_emi : 0
        };

        //Stop the submission overrideLoanSettings check but not enter values of Installments and base_emi
        if (overrideLoanSettings && !values.Installments && !values.base_emi) {
            return
        }


        try {
            const res = itemId ? await loanSvc.update(updatedValues) : await loanSvc.save(updatedValues);
            setVisible(false);
            toast.success('Success');
            setCustomerSelected(null);
            setcustomerSearch('');
            formikHelpers.resetForm();
            setInitialValues(initial);

            const selectedStatus = itemId ? status : 'created' // Use the appropriate status based on itemId
            loanFilterData(selectedStatus, 0, 0);
            setSelectedStatus(selectedStatus);
        } catch (error) {
            // Handle errors here

        }

    };

    const onHide = () => {
        setInitialValues(initial);
        setItemId(null);
        setCustomerSelected(null);
        setcustomerSearch('');
        setVisible(false);
    };

    return (
        <Dialog header={!itemId ? t("Create Loan") as string : t("Update") as string} visible={visible} maximizable style={{ width: '50vw' }} onHide={onHide}>

           {
           loading && 
           <Dialog visible={loading} style={{ width: '20vw' }} onHide={() => {}} closable={false}>
                <div className="custom-dialog-content">
                <p>Loading</p>
                <ProgressSpinner />
                <p>Please wait a moment...</p>
                </div>
            </Dialog>
            }

            <Formik enableReinitialize initialValues={initialValues} validationSchema={LoanSave} onSubmit={onSubmit}>
                {({ isSubmitting, values, errors, getFieldProps, touched, setFieldValue }) => (
                    <Form>

                        <div className="grid p-fluid mt-3">

                            {status !== "approved" && status !== "closed" &&    //Temporary update  for add agent and region
                                <>
                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="borrow_type" className="label-raleway-font">{t("Borrow Type")}</label>
                                        <Dropdown {...getFieldProps(('borrow_type'))} name='borrow_type' options={borrow_type}
                                            id="borrow_type" className="w-full " />
                                        {touched.borrow_type && errors.borrow_type && <ErrorMessage message={errors.borrow_type} id="borrow_type" />}
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="disburse_Type" className="label-raleway-font">{t("Disburse Type")}</label>
                                        <Dropdown {...getFieldProps(('disburse_type'))} name='disburse_type' options={disburse_type}
                                            id="disburse_Type" className="w-full" />
                                        {touched.disburse_type && errors.disburse_type && <ErrorMessage message={errors.disburse_type} id="disburse_Type" />}
                                    </div>



                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="requested_on" className="label-raleway-font">{t("Loan Date")}</label>
                                        <Calendar
                                            id="requested_on"
                                            name="requested_on"
                                            value={new Date(values.requested_on as Date)}
                                            
                                            onChange={(e) => setFieldValue('requested_on', new Date(e.value as Date))}
                                            dateFormat="dd/mm/yy"
                                            showIcon
                                            className="w-full" />
                                        {touched.requested_on && errors.requested_on && <ErrorMessage message={errors.requested_on} id="requested_on" />}
                                    </div>


                                    <div className="field col-12 md:col-6">
                                        <label htmlFor="customer_id" className="label-raleway-font">{t("customer")}</label>
                                        <AutoComplete field="name" value={customerSelected} suggestions={customer} completeMethod={(e) => { setcustomerSearch(e.query); }} onChange={(e) => { setFieldValue("customer_id", e.value.value); setCustomerSelected(e.value); }} />
                                        {touched.customer_id && errors.customer_id && <ErrorMessage message={errors.customer_id} id="customer_id" />}
                                    </div>

                                    <div className="field col-12 md:col-6">
                                        <label htmlFor="request_amt" className="label-raleway-font">{t("Request Amount")}</label>
                                        <InputNumber id="request_amt" minFractionDigits={2} maxFractionDigits={2} onChange={(e) => setFieldValue('requested_amt', e.value)} value={values.requested_amt || 0} name='requested_amt' />
                                        {touched.requested_amt && errors.requested_amt && <ErrorMessage message={errors.requested_amt} id="request_amt" />}
                                    </div>

                                </>
                            }


                            <div className="field col-12 md:col-6">
                                <label htmlFor="region_id" className="label-raleway-font">{t("Region Name")}</label>
                                <Dropdown {...getFieldProps(('region_id'))} optionLabel='region_name' optionValue="_id" options={regionList}
                                    id="region_id" className="w-full"
                                    onChange={(e) => {
                                        setFieldValue("region_id", e.value);
                                        loanSvc.agentList(e.value).then(res => {
                                            setAgentList(res.data)
                                        });
                                    }}
                                />
                                {touched.region_id && errors.region_id && <ErrorMessage message={errors.region_id} id="region_id" />}
                            </div>

                            <div className="field col-12 md:col-6">
                                <label htmlFor="agent_id" className="label-raleway-font">{t("Agent Name")}</label>
                                <Dropdown {...getFieldProps(('agent_id'))} optionLabel='agent_name' optionValue="_id" options={agentList}
                                    id="agent_id" className="w-full" />
                                {touched.agent_id && errors.agent_id && <ErrorMessage message={errors.agent_id} id="agent_id" />}
                            </div>


                            {status !== "approved" && status !== "closed" &&  //Temporary update  for add agent and region
                                <>

                                    <div className="field col-12 md:col-3">
                                        <input
                                            type="checkbox"
                                            id="overrideLoanSettings"
                                            checked={overrideLoanSettings}
                                            onChange={(e) => setOverrideLoanSettings(e.target.checked)}
                                        />
                                        <label htmlFor="overrideLoanSettings" className="ml-2">{t("Modify Loan Settings ?")}</label>
                                    </div>

                                    {overrideLoanSettings &&
                                        <>
                                            <div className="field col-12 md:col-3">
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id="installmentsOption"
                                                        value="installments"
                                                        checked={selectedOption === 'installments'}
                                                        onChange={() => setSelectedOption('installments')}
                                                        disabled={!overrideLoanSettings} />
                                                    <label htmlFor="installmentsOption">{t("No. of Installments")}</label>

                                                    {selectedOption === 'installments' && (
                                                        <div>
                                                            <InputNumber
                                                                id="Installments"
                                                                onChange={(e) => setFieldValue('Installments', e.value)}
                                                                value={values.Installments || 0}
                                                                name="Installments" />
                                                        </div>
                                                    )}</div>
                                            </div><div className="field col-12 md:col-3">
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id="emiAmountOption"
                                                        value="emiAmount"
                                                        checked={selectedOption === 'emiAmount'}
                                                        onChange={() => setSelectedOption('emiAmount')}
                                                        disabled={!overrideLoanSettings} />
                                                    <label htmlFor="emiAmountOption">{t("EMI Amount")}</label>

                                                    {selectedOption === 'emiAmount' && (
                                                        <div>
                                                            <InputNumber
                                                                id="base_emi"
                                                                onChange={(e) => setFieldValue('base_emi', e.value)}
                                                                value={values.base_emi || 0}
                                                                name="base_emi" />

                                                        </div>

                                                    )}</div>

                                            </div>

                                            {touched.Installments && touched.base_emi && !values.Installments && !values.base_emi && (
                                                <ErrorMessage message="Please select an option and enter the value" id={"select an option"} />)}

                                        </>}

                                </>}

                        </div>

                        <div className="flex flex-wrap justify-content-end gap-3">
                            <CancelButton label={t("Cancel") + ''} onClose={onHide} />
                            <Button label={id ? t("Update") as string : t("Save") as string} style={{ background: '#11B3CF' }} icon="pi pi-check" className="p-button-success p-button-raised" type='submit' loading={isSubmitting} />
                        </div>
                    </Form>)}
            </Formik>

        </Dialog>
    );
};

export default LoanModal;



interface ILoanModal {
    visible: boolean;
    setSelectedStatus: any;
    setVisible(val: boolean): void;
    viewOnly: boolean,
    id: string | null;
    setReload(val: boolean): void;
    loanFilterData: any
}