
import React, { useState, useEffect, useRef } from 'react';
import { DataTable, DataTableExpandedRows, DataTableRowEvent, DataTableValueArray } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { commissionReportList } from '../../../services/masterService/regionService';
import { regionSvc } from '../../../services';
import { Dropdown } from 'primereact/dropdown';
import { Paginator, PaginatorTemplateOptions } from 'primereact/paginator';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../../components/styledcomponents';

interface Agents {
    _id: string;
    agent_name: string;
    agentLoansCommission: number,
    sl_no: string,
    commission_rate: number,
    location: string,
    status: string,
}

interface Region {
    _id: string;
    region_name: string;
    sl_no: string,
    region_manager: string,
    location: string,
    status: string,
    agents?: Agents[];
}

const CommissionReportList: React.FC = () => {
    const [t] = useTranslation()
    const [regions, setRegions] = useState<any>([]);
    const [totalRecords, settotalRecords] = useState(0);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState('1');
    const [limit, setLimit] = useState(5);
    const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
    const toast = useRef<Toast>(null);

    useEffect(() => {
        regionData()
    }, [page, limit]);

    const regionData = () => {
        regionSvc.commissionReportList(Number(page), Number(limit)).then(res => {
            setRegions(res.data.data);
            settotalRecords(res.data.recordcount);
        });
    }
    const getPaginationProps = (p: string, l: any) => {
        setPage((Number(p) + 1).toString());
        setLimit(l);
    };

    const onPageChange = (event: { first: React.SetStateAction<number>; rows: React.SetStateAction<number>; page: React.SetStateAction<number>; }) => {
        setFirst(event.first);
        setLimit(event.rows);
        getPaginationProps(event.page.toString(), event.rows.toString());
    };
    const paginatorTemp: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1 pagination-no">
                        {t('Items per page') + ': '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: { first: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; last: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; totalRecords: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }) => {
            return (
                <span className="pagination-no">
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };


    const onRowExpand = (event: DataTableRowEvent) => {
        toast.current?.show({ severity: 'info', summary: 'Region Expanded', detail: event.data.name, life: 3000 });
    };

    const onRowCollapse = (event: DataTableRowEvent) => {
        toast.current?.show({ severity: 'success', summary: 'Region Collapsed', detail: event.data.name, life: 3000 });
    };

    const expandAll = () => {
        let _expandedRows: DataTableExpandedRows = {};

        regions.forEach((p: any) => (_expandedRows[`${p._id}`] = true));

        setExpandedRows(_expandedRows);
    };

    const collapseAll = () => {
        setExpandedRows(undefined);
    };

    const formatCurrency = (value: number) => {
        return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
    };

    const amountBodyTemplate = (rowData: Agents) => {
        return formatCurrency(rowData.agentLoansCommission);
    };

    const statusBodyTemplate = (rowData: any) => {
        return <Tag value={rowData.status} severity={getProductSeverity(rowData)} ></Tag>;
    };

    const getProductSeverity = (product: any) => {
        switch (product.status) {
            case 'active':
                return 'success';

            case 'terminated':
                return 'danger';

            default:
                return null;
        }
    };



    const allowExpansion = (rowData: Region) => {
        return rowData.agents!.length > 0;
    };

    const rowExpansionTemplate = (data: Region) => {
        console.log(data.region_name)
        return (
            <div className="p-3">
                <h5>Agents Under {data?.region_name}</h5>
                <DataTable value={data.agents} >
                    <Column field="sl_no" header="Sl No" />
                    <Column field="agent_name" header="Agent Name" ></Column>
                    <Column field="commission_rate" header="Commision Rate" />
                    <Column field="agentLoansCommission" header="Amount" body={amountBodyTemplate} ></Column>
                    <Column field="status" header="Status" className='capitalize' body={statusBodyTemplate} />
                </DataTable>
            </div>
        );
    };
   
    const header = (
        <div className="flex flex-wrap justify-content-end gap-2">
            <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} style={{ border: 'none',boxShadow:'none' }}/>
            <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} style={{ border: 'none',boxShadow:'none' }}/>
        </div>
    );

    return (
        <div >
            <Toast ref={toast} />
            <div className='common-header-toolbar'>
                <div>
                    <div><Heading>{t("Commission")}</Heading></div>
                </div>
                <div className='flex'>
                    {header}
                </div>
            </div>
            <DataTable
                value={regions}
                expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansionTemplate}
                dataKey="_id"
                
                //  header={header}
                className="datatable-responsive custom-datatable"
                tableStyle={{ minWidth: '60rem' }}>
                <Column expander={allowExpansion} style={{ width: '5rem' }} />
                <Column field="sl_no" header="Sl No" />
                <Column field="region_name" header="Region Name" />
                <Column field="region_manager" header="Region Manager" />
                <Column field="location" header="Location" />
                <Column field="status" header="Status" className='capitalize' body={statusBodyTemplate} />

            </DataTable>
            <Paginator template={paginatorTemp} first={first} rows={limit} totalRecords={totalRecords} onPageChange={onPageChange} className="justify-content-end my-3"></Paginator>

        </div>
    );
}

export default CommissionReportList;
