import { Form, Formik, FormikHelpers } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { AutoComplete } from "primereact/autocomplete";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import useDebounce from "../../../../helpers/debounce";
import { chitfundSvc, customerSvc, loanSvc } from "../../../../services";
import { useTranslation } from "react-i18next";
import { CancelButton } from "../../../../components/styledcomponents";
import { AuthContext } from "../../../../context";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import '../chitFund.css'
import { chitSave } from "../../../../validation";
import { Checkbox } from "primereact/checkbox";

const ChitFundModal = ({ setVisible, visible = false, viewOnly = true, id, setReload, chitFilterData, setSelectedStatus }: IChitFund) => {
    const { userId } = useContext(AuthContext);
    const initial: IChitFundIni = {
        chit_amount: 0,
        duration: 0,
        customer_id: [],
    };
    const [initialValues, setInitialValues] = useState<IChitFundIni>(initial);
    const [t] = useTranslation()
    const [itemId, setItemId] = useState<string | null>(id);
    const [customer, setCustomer] = useState([]);
    const [customerSelected, setCustomerSelected] = useState(null);
    const [customerSearch, setcustomerSearch] = useState<string>('');
    const debouncedValue = useDebounce<string>(customerSearch, 500);
    const [checked, setChecked] = useState(false);
    const [items, setItems] = useState<any>([]);

    useEffect(() => {
        if (debouncedValue && debouncedValue.length > 0)
            customerSvc.getAutoSuggest(customerSearch).then(res => {
                setCustomer(res.data);
            });
    }, [debouncedValue]);
    useEffect(() => {
        if (id && visible) {

        }

    }, [itemId, visible]);

   

    useEffect(() => {
        if (id && visible) {
            chitfundSvc.get(id).then((res) => {
                setInitialValues({ ...res.data });
                // Extract customerData from the response
                const { customerData } = res.data;
    
                // Map the customerData to the desired format
                const formattedItems = customerData.map((customer:any) => ({
                    customer_id: customer.customer_id,
                    customer_name: customer.customer_name,
                }));
    
                // Update the state with the formatted items
                setItems(formattedItems);
            });
        }
    }, [id, visible]);


    const handleAddItem = ({ customer_id: { value, name } }: any) => {
        if (!value) {
            return;
        }

        // Check if the customer is already in the items array using a set.
        const customerIdsSet = new Set(items.map((item: any) => item.customer_id));

        if (!customerIdsSet.has(value)) {
            const newItem = {
                customer_id: value,
                customer_name: name,
            };

            setItems([...items, newItem]);
        }


        setCustomerSelected(null);
    };

    const handleDeleteItem = (itemId: any) => {
        const updatedItems = items.filter((item: any) => item.customer_id !== itemId);
        setItems(updatedItems);
    };

    const onSubmit = async (values: IChitFundIni, formikHelpers: FormikHelpers<IChitFundIni>) => {
        try {
            const customerIds = items.map((item: any) => item.customer_id);

            if (customerIds.length === 0) {
                toast.warning("Please add at least one customer.");
                return;
            }

            const requestData: { chit_amount: number; customer_ids: any; chit_id?: any;autionWithInterest:boolean } = {
                chit_amount: values.chit_amount,
                customer_ids: customerIds,
                autionWithInterest:checked,
            };

            if (itemId) {
                requestData.chit_id = itemId;
            }

            const response = itemId ? await chitfundSvc.update(requestData) : await chitfundSvc.save(requestData);

            if (response) {
                toast.success("Success");
                setCustomerSelected(null);
                setcustomerSearch('');
                formikHelpers.resetForm();
                setInitialValues(initial);
                chitFilterData({ name: 'Created', code: 'created' }); // After save show the created loan list
                setSelectedStatus({ name: 'Created', code: 'created' });
                setVisible(false);
            } else {
                toast.error("An error occurred while saving the data.");
            }
        } catch (error) {
            // toast.error("An error occurred while processing your request.");
            console.error("Error:", error);
        }
    };


    const onHide = () => {
        setInitialValues(initial);
        setItemId(null);
        setCustomerSelected(null);
        setcustomerSearch('');
        setVisible(false);
    };

    return (
        <Dialog header={!itemId ? t("Create Chit") as string : t("Update Chit") as string} visible={visible} maximizable style={{ width: '50vw' }} onHide={onHide}>
            <Formik enableReinitialize initialValues={initialValues} validationSchema={chitSave} onSubmit={onSubmit}>
                {({ isSubmitting, values, errors, getFieldProps, touched, setFieldValue }) => (
                    <Form>

                        <div className="grid p-fluid mt-3">

                            <div className="field col-12 md:col-5">
                                <label htmlFor="chit_amount" className="label-raleway-font">{t("Total Chit Amount")}</label>
                                <InputNumber id="chit_amount" minFractionDigits={2} maxFractionDigits={2} onChange={(e) => setFieldValue('chit_amount', e.value)} value={values.chit_amount || 0} name='chit_amount' />
                                {touched.chit_amount && errors.chit_amount && <ErrorMessage message={errors.chit_amount} id="chit_amount" />}
                            </div>

                            <div className="field col-12 md:col-6">
                                <label htmlFor="customer_id" className="label-raleway-font">{t("customer")}</label>
                                <AutoComplete field="name" value={customerSelected} suggestions={customer} completeMethod={(e) => setcustomerSearch(e.query)}
                                    onChange={(e) => {
                                        const selectedCustomer = e.value; // Get the selected tool object
                                        setFieldValue('customer_id', selectedCustomer);
                                        setCustomerSelected(selectedCustomer);
                                    }}
                                />
                            </div>

                            <div className="field col-12 md:col-1 ">
                                <label htmlFor="add">Add</label>
                                <Button icon="pi pi-plus" type="button" onClick={() => handleAddItem(values)} />
                            </div>
                            <div className="flex align-items-center ml-2">
                            <Checkbox onChange={e => setChecked(e.checked as boolean)} checked={checked}></Checkbox>
                            <label htmlFor="dividend" className="ml-2">Commission chit</label>
                            </div>
                            <div className="col-12">

                                <DataTable
                                    value={items}
                                    // dataKey="_id"
                                    className="datatable-responsive white chitfundmodal-datatable"
                                    emptyMessage="No items selected"
                                    responsiveLayout="scroll"
                                >
                                    <Column header="No" headerStyle={{ width: '3rem' }} body={(data, options) => options.rowIndex + 1}></Column>
                                    <Column className="capitalize" style={{ flexGrow: 1, flexBasis: '200px' }} header="Customer Name" field="customer_name" ></Column>
                                    <Column style={{ width: '6rem' }} headerStyle={{ width: '6rem', textAlign: 'center' }} header="Action" body={(rowData, rowIndex) => (
                                        <Button icon="pi pi-trash" type="button" className="p-button-rounded p-button-danger" onClick={() => handleDeleteItem(rowData.customer_id)} />
                                    )}></Column>
                                </DataTable>

                            </div>

                        </div>
                        <div className="flex flex-wrap justify-content-end gap-3">
                            <CancelButton label={t("Cancel") + ''} onClose={onHide} />
                            <Button label={id ? t("Update") as string : t("Save") as string} style={{ background: '#11B3CF' }} icon="pi pi-check" className="p-button-success p-button-raised" type='submit' loading={isSubmitting} />
                        </div>
                    </Form>)}
            </Formik>

        </Dialog>
    );
};

export default ChitFundModal;



interface IChitFund {
    visible: boolean;
    setSelectedStatus: any;
    setVisible(val: boolean): void;
    viewOnly: boolean,
    id: string | null;
    setReload(val: boolean): void;
    chitFilterData: any
}

interface IChitFundIni {
    chit_amount: number;
    duration: number,
    customer_id: any,
}