import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/common/ErrorMessage";
import { repaymentSvc } from '../../../services';
import { CancelButton } from "../../../components/styledcomponents";
import { Calendar } from "primereact/calendar";


const PendingRepayment = ({ setVisible, visible = false, id, RepaymentHistoryData }: IRepaymentModal) => {
    const [loanId, setLoanId] = useState<string | null>('');
    const [repayAmt, setRepayAmt] = useState<any>("");
    const [pendingAmt, setPendingAmt] = useState<any>(null);
    const [date, setDate] = useState<Date | null>(null);
    const [error, setError] = useState(false)
    useEffect(() => {
        if (id)
            repaymentSvc.getPendingPayment(id).then(res => {
                if (res.data) {
                    setPendingAmt(res.data.amount_to_pay)
                    setLoanId(res.data.loan_id)
                }
            })


    }, [id])
    const onSubmit = async () => {
        if (loanId === null || !repayAmt || isNaN(Number(repayAmt)) || date===null) {
                setError(true);
                return error;
        }

        if (loanId && date && repayAmt) {

            const desiredFormat = "YYYY-MM-DD HH:mm:ss";
            const convertedFormat = moment(date).format(desiredFormat);
      
            // check repayment date and current date for pass null value if user enter current date those dates are same send null value to server
            if (moment(date).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
                repaymentSvc.payPendingAmnt(id,loanId, Number(repayAmt),convertedFormat).then(res => {
                    if (res) {
                    RepaymentHistoryData(loanId)
                    toast.success("Success");
                    setVisible(false);
                }
              });
            } else {
                repaymentSvc.payPendingAmnt(id,loanId, Number(repayAmt), null).then(res => {
                    if (res) {
                        RepaymentHistoryData(loanId)
                        toast.success("Success");
                        setVisible(false);
                    }
                    
              });
            }
          }
    };


    const DialogFooter = (
        <>

            <CancelButton label={"Cancel"} onClose={() => { setVisible(false) }} />
            <Button label='Save' icon="pi pi-check" className="p-button" style={{ background: '#11B3CF',border:'none' }} onClick={onSubmit} />
        </>);
    return (
        <Dialog header={"Pay Pending Amount"} footer={DialogFooter} visible={visible} maximizable style={{ width: '40vw' }} onHide={() => setVisible(false)}>
            <div className="flex flex-row gap-1 font-bold">
                <p>Pending Amount :</p>
                <p >
                    {pendingAmt ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(pendingAmt) : 'N/A'}
                </p>
            </div>
            {/* <div className="flex flex-column gap-2" style={{ maxWidth: 400 }}>
                <label htmlFor="repayment-amt" className="font-bold block mb-2">Repayment Amount<span style={{ color: 'red' }}>*</span></label>
                <InputNumber placeholder="INR 00.00"  inputId="currency-india" value={repayAmt} onValueChange={(e) => setRepayAmt(e.value)} mode="currency" currency="INR" currencyDisplay="code" className={`${error ? 'p-invalid' : ''}`} />
                {error && <ErrorMessage id={"repayment-amt"} message="Required" />}
            </div> */}
            <div className="flex flex-row gap-5" style={{ maxWidth: 400 }}>
                <div className="flex-1">
                    <label htmlFor="repayment-amt" className="font-bold block mb-2">Repayment Amount<span style={{ color: 'red' }}>*</span></label>
                    <InputNumber placeholder="INR 00.00" inputId="currency-india" value={repayAmt} onValueChange={(e) => setRepayAmt(e.value)} mode="currency" currency="INR" currencyDisplay="code" className={`${error ? 'p-invalid' : ''}`} />
                    {error && <ErrorMessage id={"repayment-amt"} message="Required" />}
                </div>
                <div>
                    <label htmlFor="repayment-date" className="font-bold block mb-2">Repayment Date<span style={{ color: 'red' }}>*</span></label>
                    <Calendar value={date} onChange={(e) => setDate(e.value as Date)} dateFormat="dd/mm/yy" showIcon showButtonBar />
                    <div className="ml-6">{date === null && error && <ErrorMessage message={"Please select a date"} id="calender" />}</div>
                </div>
            </div>
        </Dialog>
    );
};
export default PendingRepayment

interface IRepaymentModal {
    visible: boolean;
    setVisible(val: boolean): void;
    id: string | null;
    RepaymentHistoryData: any
};