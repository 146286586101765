import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { chitfundSvc, customerSvc, repaymentSvc } from '../../../../services';
import { Form, Formik, FormikHelpers } from 'formik';
import { InputText } from 'primereact/inputtext';
import useDebounce from "../../../../helpers/debounce";
import { AutoComplete } from 'primereact/autocomplete';
import { Heading } from '../../../../components/styledcomponents';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ActionButton from '../../../../components/common/buttons/actionButton';
import moment from 'moment';
import ErrorMessage from '../../../../components/common/ErrorMessage';
import PayChitAmountModal from './Modals/payChitAmountModal';
import ChitPaymentHistory from './Modals/chitPaymentHistory';



const PayChittyAmount = () => {
  const [t] = useTranslation()
  const [visible, setVisible] = useState<boolean>(false);
  const [viewDetails, setViewDeatails] = useState<boolean>(false);
  const [viewHistory, setViewHistory] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const [customerId, setCustomerId] = useState<string>('');
  const [dataTableVisible, setDataTableVisible] = useState(false)

  interface IChitRepayment {
    customer_id: string,
    chit_id: string,
  }
  const initial: IChitRepayment = {
    customer_id: "",
    chit_id: "",
  }
  const [initialValues, setInitialValues] = useState<IChitRepayment>(initial)
  const [chitData, setChitData] = useState<any>([]);
  const [chitCustomers, setChitCustomers] = useState<any>([]);
  const [data, setData] = useState<any>('');
  const [customerSelected, setCustomerSelected] = useState(null);
  const [customer, setCustomer] = useState([]);
  const [customerSearch, setcustomerSearch] = useState<string>('');
  const debouncedValue = useDebounce<string>(customerSearch, 500);
  const [selectedSearchType, setSelectedSearchType] = useState<string | null>(null); // Track the search type (customer_id or chit_id)
  let error: any = null;

  useEffect(() => {
    if (debouncedValue && debouncedValue.length > 0)
      customerSvc.getAutoSuggest(customerSearch).then(res => {
        setCustomer(res.data);
      });
  }, [debouncedValue]);

  const onSubmit = async (values: IChitRepayment, formikHelpers: FormikHelpers<IChitRepayment>) => {
    try {
      if (!values.customer_id && !values.chit_id) {
        error = true;
      } else {
        let res;
        if (values.customer_id) {
          res = await chitfundSvc.getByCustomerId(values.customer_id);
          setData(res.data.customerData);
          setCustomerId(res.data.customerData.cus_id);
          setChitData(res.data.chitData);
          setSelectedSearchType('customer_id');
        } else if (values.chit_id) {
          res = await chitfundSvc.getByChitId(values.chit_id);
          setChitCustomers(res.data.payments);
          setSelectedSearchType('chit_id');
          setId(res.data.chitData._id);
          setData(res.data.chitData);
          console.log(res.data.chitData.sl_no)
        }
        formikHelpers.resetForm();
        setDataTableVisible(true);
      }
    } catch (error) {
      setDataTableVisible(false);
    }
    setCustomerSelected(null);
  };

  const onEditOrView = (edit: boolean, id?: string) => {
    if (id)
      setId(id);
    console.log(id)
    setViewDeatails(true);
  };

  const getIndianFormattedDate = (date: any) => {
    return moment(date).format('DD/MM/YYYY');
  };

  const formatCurrency = (amount: any) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    }).format(amount);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold" style={{ fontFamily: 'Raleway' }}><span>{t("Customer Name :")} </span>
        {`${data?.cus_name}`}
      </span>
    </div>
  );

  const chitIdHeader = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold" style={{ fontFamily: 'Raleway' }}><span>{t("Chit No :")} </span>
        {`${data.sl_no}`}
      </span>

      <span className="text-xl text-900 font-bold" style={{ fontFamily: 'Raleway' }}><span>{t("Chit Amount :")} </span>
        {data?.chit_amount?.toLocaleString('en-IN', {
          style: 'currency',
          currency: 'INR'
        })}
      </span>
    </div>
  );

  const customerNameActionBodyTemplate = (rowData: any) => {
    return <>
      <ActionButton label={t("Pay Chit") as string} icon="pi pi-money-bill" className="p-button-secondary" onClick={() => { setVisible(true); setId(rowData._id) }} />
      <ActionButton label={t("Payment History") as string} icon="pi pi-clock" className="p-button-secondary" onClick={() => { setViewHistory(true); setId(rowData._id) }} />
    </>
  };

  const chitIdActionBodyTemplate = (rowData: any) => {
    return <>
      <ActionButton label={t("Pay Chit") as string} icon="pi pi-money-bill" className="p-button-secondary" onClick={() => { setVisible(true); setCustomerId(rowData.customer_id._id) }} />
      <ActionButton label={t("Payment History") as string} icon="pi pi-clock" className="p-button-secondary" onClick={() => { setViewHistory(true); setCustomerId(rowData.customer_id._id) }} />
    </>
  };

  return (
    <React.Fragment>
      <div className='repaymentAmt'>
        <div><Heading className="mb-4">{t("Chit Payments")}</Heading></div>
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
          {
            ({ values, errors, getFieldProps, touched, setFieldValue }) => (
              <Form>
                <div className="grid p-fluid mt-3">

                  <div className="p-inputgroup field col-12 md:col-6">
                    <span className="p-float-label">
                      <div className="p-inputgroup">
                        <AutoComplete field="name" value={customerSelected} suggestions={customer} completeMethod={(e) => { setcustomerSearch(e.query); }}
                          onChange={(e) => { setFieldValue("customer_id", e.value.value); setCustomerSelected(e.value); }} />
                        <label htmlFor="customer" className='ml-2 label-raleway-font '>{t("customer name")}</label>
                        <Button icon="pi pi-search" label={t("Search") as string} style={{ backgroundColor: "#11B3CF" }} className="p-button-success" type='submit' />
                      </div>
                    </span>
                    <div className='ml-3 '>
                      {error && <ErrorMessage id={"customer_name"} message="Required" />}
                    </div>

                  </div>


                  <div className="p-inputgroup field col-12 md:col-6">
                    <span className="p-float-label">
                      <InputText id="chit_id" {...getFieldProps('chit_id')} value={values.chit_id}
                      />
                      <label htmlFor="chit_id" className='label-raleway-font '>{t("Chitty Id")}</label>
                      <Button icon="pi pi-search" label={t("Search") as string} style={{ backgroundColor: "#11B3CF" }} className="p-button-success" type='submit' />
                    </span>
                    <div className='ml-3 '>
                      {error && <ErrorMessage id={"chit_id"} message="Required" />}
                    </div>
                  </div>

                </div>

              </Form>)
          }
        </Formik>
        {dataTableVisible && selectedSearchType === 'chit_id' && chitCustomers && (
          // Display chitCustomers table
          <div>
            <DataTable
              value={chitCustomers}
              header={chitIdHeader}
              scrollable
              scrollHeight="400px"
              className="datatable-responsive custom-datatable"
              style={{ minWidth: '50rem' }}>
              <Column header="No" headerStyle={{ width: '3rem' }} body={(data, options) => options.rowIndex + 1}></Column>
              <Column style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} field="customer_id.sl_no" header="Sl No"></Column>
              <Column style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} field="customer_id.first_name" header="Customer Name"></Column>
              <Column header={t("action")} className="action" body={chitIdActionBodyTemplate} bodyStyle={{ textAlign: 'center' }} alignHeader={'center'} style={{ minWidth: '150px' }} alignFrozen="right" frozen></Column>
            </DataTable>
          </div>
        )}

        {dataTableVisible && selectedSearchType === 'customer_id' && chitData &&

          <div>
            <DataTable
              value={chitData}
              header={header}
              scrollable
              scrollHeight="400px"

              className="datatable-responsive custom-datatable"
              style={{ minWidth: '50rem' }}>
              <Column header="No" headerStyle={{ width: '3rem' }} body={(data, options) => options.rowIndex + 1}></Column>
              <Column style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} field="sl_no" header="Sl No"></Column>
              <Column style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} bodyStyle={{ textAlign: 'center' }} field="chit_amount" alignHeader={'center'} body={(rowData) => formatCurrency(rowData.chit_amount)} header={t('Chit Amount')} />
              <Column header={t("action")} className="action" body={customerNameActionBodyTemplate} bodyStyle={{ textAlign: 'center' }} alignHeader={'center'} style={{ minWidth: '150px' }} alignFrozen="right" frozen></Column>

            </DataTable>

          </div>}
        {!dataTableVisible &&
          <div style={{ height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none' }}>
              <i className="pi pi-search" style={{ fontSize: '8em', color: "#11B3CF" }}></i>
            </span>
            <div className='raleway-font mt-3'>{t("Search a Chitty for payments")}</div>

          </div>
        }

      </div>

      {viewHistory && <ChitPaymentHistory viewHistory={viewHistory} setViewHistory={setViewHistory} chitId={id} customerId={customerId} />}
      {/* {viewDetails && <RePaymentDetails viewDetails={viewDetails} setViewDeatails={setViewDeatails} id={id} />} */}
      {visible && <PayChitAmountModal visible={visible} setVisible={setVisible} chitId={id} customerId={customerId} />}
    </React.Fragment>
  );
};

export default PayChittyAmount;
