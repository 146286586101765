import React, { useState, useEffect } from "react";
import { chitfundSvc, repaymentSvc } from '../../../../../services';
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import DataGridV3 from "../../../../../components/datagrid/DataGridV3";
// import PendingRepayment from "./pendingRepayment";
import { Button } from "primereact/button";
// import './repayment.css'
import ActionButton from "../../../../../components/common/buttons/actionButton";

const ChitPaymentHistory= ({ setViewHistory, viewHistory = false, chitId,customerId }: IChitPaymentHistory) => {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [view, setView] = useState<boolean>(false);
  
  const [repaymentId, setRepaymentId] = useState<string>('');
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');
  const [t, i18n] = useTranslation()
  const columns = [
    { field: 'due_date', header: 'Due Date',type: 'date' },
    { field: 'amount_to_pay', header: 'Amount to pay',type: 'amount' },
    { field: 'repaid_amnt', header: 'Amount Paid',type:'amount' },
    { field: 'paid_on', header: 'Paid On',type: 'date' },
    // { field: 'remainingAmount', header: 'Remaining Amount',type: 'amount' },
    
    
    {
        field: 'status', header: 'Status', type: 'status', status: [
          { key: 'paid', value: 'success' },
          { key: 'pending', value: 'info' },
          { key: 'partially paid', value: 'info' }
        ]
      } 
  ];
  useEffect(() => {
    RepaymentHistoryData(chitId,customerId)
  }, [limit, page]);

  const RepaymentHistoryData = (chitId:string,customerId:string) => {
    chitfundSvc.chitHistoryList(chitId,customerId,String(limit), String(page)).then(res => {
      setTableData(res.data.data);
      settotalRecords(res.data.totalRecords);
    });
  };

const extraAction = ({ rowData }: any) => {
  
  return <>
        <ActionButton  icon="pi pi-credit-card" label={"Pay"} className="p-button-secondary" onClick={()=>{setRepaymentId(rowData._id);setView(true)}} />

      </>;
};
  
  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };
  const repaymenthistorySearch = () => {
    return null
  };

    return (
      <>
      <div>
        <Dialog visible={viewHistory} className="custom-dialog" maximizable style={{ width: '80vw'}} onHide={() => setViewHistory(false)} >
          <DataGridV3 tittle={t("Chit Payment History")} data={tableData} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} extraAction={extraAction} SearchBar={repaymenthistorySearch}/>
        
        {/* {view && <PendingRepayment visible={view} setVisible={setView} id={repaymentId} RepaymentHistoryData={RepaymentHistoryData}  />} */}
        </Dialog> 
        </div>
        

        </>
    );
};
export default ChitPaymentHistory

interface IChitPaymentHistory {
    viewHistory: boolean;
    setViewHistory(val: boolean): void;
    chitId: string;
    customerId:string;
};