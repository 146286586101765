
export const model = [
    {
        // label: 'reports',
        items: [{ label: 'dashboard', icon: 'pi pi-home', to: '/app' }],
        seperator: false
    },
    {
        // label: 'pages',
        // icon: 'pi pi-fw pi-briefcase',
        to: '/app',
        items: [

            {
                label: 'customers',
                icon: 'pi pi-fw pi-users',
                to: '/pages/timeline',
                items: [

                    {
                        label: 'customers',
                        icon: 'pi pi-fw pi-users',
                        to: '/app/customer'
                    },
                    {
                        label: 'offers',
                        icon: 'pi pi-fw pi-box',
                        to: '/app/offers'
                    }
                ]
            },
            {
                label: 'transactions',
                icon: 'pi pi-fw pi-arrow-right-arrow-left',
                to: '/pages/timeline',
                items: [

                    {
                        label: 'loans',
                        icon: 'pi pi-fw pi-money-bill',
                        to: '/pages/timeline',
                        items:[
                            {
                                label: 'Add Loans',
                                icon: 'pi pi-fw pi-money-bill',
                                to: '/app/transaction/loans/addloan'
                            },
                            {
                                label: 'repayment',
                                icon: 'pi pi-fw pi-history',
                                to: '/app/transaction/loans/repayment'
                                
                               
                            },
                            {
                                label: 'Loan Top-Up',
                                icon: 'pi pi-fw pi-arrow-up',
                                to: '/app/transaction/loans/loantopup'
                            },
                            {
                                label: 'Pre-Closure',
                                icon: 'pi pi-fw pi-arrow-down',
                                to: '/app/transaction/loans/loanpreclosure'
                            }
                        ]
                    },
                    // {
                    //     label: 'Chits',
                    //     icon: 'pi pi-fw pi-wallet',
                    //     to: '/pages/timeline',
                    //     items:[
                    //         {
                    //             label: 'Add Chits',
                    //             icon: 'pi pi-fw pi-wallet',
                    //             to: '/app/transaction/chits/addchit'
                    //         },
                    //         {
                    //             label: 'Payments',
                    //             icon: 'pi pi-fw pi-history',
                    //             to: '/app/transaction/chits/Payments'
                                
                               
                    //         },
                    //     ]
                    // },
                ]
            },
            {
                label: 'Reports',
                icon: 'pi pi-calculator',
                to: '/pages/timeline',
                items: [

                    {
                        label: 'Paid List',
                        icon: 'pi pi-list',
                        to:'/app/paidlist'
                    },
                    {
                        label: 'Pending List',
                        icon: 'pi pi-book',
                        to:'/app/duelist'
                    },
                    {
                        label: 'Closed Loan',
                        icon: 'pi pi-chart-pie',
                        to: '/app/closedloan'
                    },
                    {
                        label: 'Commission',
                        icon: 'pi pi-calculator',
                        to: '/app/commission'
                    },
                ]
            },
            {
                label: 'Admin Center',
                icon: 'pi pi-cog',
                to: '/pages/timeline',
                items: [

                    {
                        label: 'users',
                        icon: 'pi pi-user-plus',
                        to:'/app/user'
                    },
                    {
                        label: 'Admin Panel',
                        icon: 'pi pi-cog',
                        to:'/app/admin'
                    },
                   
                ]
            },
            
        ]
    },
];
