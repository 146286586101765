import { axiosInstance } from "./interceptors";


export const save = async (request: any) => {
    return await axiosInstance.post(`/chits`, request);
};

export const getAll = async () => {
    return await axiosInstance.get(`/loan/getAll`);
};

export const get = async (id: string) => {
    return await axiosInstance.get(`/chits/getChitForupdate/${id}`);
};
export const searchByStatus = async (status:string,limit: string, page: string) => {
    return await  axiosInstance.get(`/chits/listByStatus?page=${page}&limit=${limit}&status=${status}`);
}

export const searchChit = async (id:any) => {
    return await axiosInstance.get(`/chits/search/${id}`);
};

export const list = async (page: Number, limit: Number) => {
    return await axiosInstance.get(`/chits/list?page=${page}&limit=${limit}`)
};
export const update = async (request: any) => {
    return await axiosInstance.put(`/chits/update`, request)
};
export const approve = async (request:any) => {
    return await axiosInstance.put(`/chits/approve`, request)
};
export const rejected = async (id: string) => {
    return await axiosInstance.put(`/chits/reject?chit_id=${ id }`)
};
export const Delete = async (id: any,userId:string) => {
    return await axiosInstance.delete(`/chits/delete/${id}/${userId}`);
};


export const multipleDelete = async (req:any) => {
    return await  axiosInstance.post(`/chits/deleteByIdList/`,req);
}

export const aucationSave = async (request: any) => {
    return await axiosInstance.post(`/chits/auction`, request);
};

export const getAutoSuggest = async (id:string) => {
    return await axiosInstance.get(`/chits/autoSuggest/serialno/${id}`);
  };

  export const getChitDetails = async (loan_id: string) => {
    return await axiosInstance.get(`/chits/getById/${loan_id}`);
};

export const getChitCustomers = async (id: string,page: Number, limit: Number) => {
    return await axiosInstance.get(`chits/getCustomers?chit_id=${id}&page=${page}&limit=${limit}`);
};

export const getByCustomerId = async (id:string) => {
    return await axiosInstance.get(`/chits/searchByCustomername/${id}` );
};

export const getByChitId = async (id:string) => {
    return await axiosInstance.get(`/chits/searchByChitId/${id}` );
};

export const chitRepayment = async (req:any) => {
    return await axiosInstance.put(`/chits/repayment`,req );
};

export const chitHistoryList = async (chitId:string,customerId:string,limit: string, page: string) => {
    return await axiosInstance.get(`/chits/getIndividualPayments?chit_id=${chitId}&customer_id=${customerId}&page=${page}&limit=${limit}`)
};

export const getIndividualPayments = async (chit_id: string,customer_id: string) => {
    return await axiosInstance.get(`/chits/paymentAmount/${chit_id}/${customer_id}`);
};

export const getAuctionReport = async (chitId:string,page: any, limit: any) => {
    return await axiosInstance.get(`/chits/getChitReports/${chitId}?page=${page}&limit=${limit}`)
};

