import React, { useRef, useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Paginator, PaginatorTemplateOptions } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { offersSvc } from '../../../services';
import OfferModal from './offerModal';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ActionButton from '../../../components/common/buttons/actionButton';
import '../../../components/datagrid/datagrid.css'
import SearchBar from '../../../components/common/searchBar/SearchBar';
import { Heading } from '../../../components/styledcomponents';

const OfferCustomerList = () => {
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
    const [selected, setSelected] = useState([]);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [interestRate, setInterestRate] = useState([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(true);
    const [id, setId] = useState<string>('');
    const dt = useRef<DataTable<any>>(null);
    const [searchQuery, setSearchQuery] = useState(''); //For serch bar
    const [t] = useTranslation()
    const [reload, setReload] = useState<boolean>(true)

    const exportCSV = () => {
        if (dt.current)
            dt.current.exportCSV();
    };
    const permissionList = useSelector((state: any) => state.permissions.permissions);

    const actionBodyTemplate = (rowData: any) => {
        const hasUpdatePermission = permissionList && permissionList.includes('pages.admin.interestRate.update');
        const hasDeletePermission = permissionList && permissionList.includes('pages.admin.interestRate.delete');
        return (
            <>
                {hasUpdatePermission && <ActionButton label={t("Edit") + ''} icon="pi pi-pencil" tooltip='Edit' className="p-button-secondary " onClick={() => onEditOrView(true, rowData._id)} />}
                {hasDeletePermission && <ActionButton label={t("Delete") + ''} icon="pi pi-trash" tooltip='Delete' className="p-button-secondary " onClick={() => (setDeleteDialog(true), setId(rowData._id))} />}
            </>
        );
    };

    const deleteDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { deleteRecord(id) }} />
        </>
    );

    // Implement Search bar 
    const handleSearch = () => {
        offersSvc.serchCustomer(searchQuery).then(res => {
            if (res) {
                setInterestRate(res.data.user);
            }
        });

    };

    const handleInputChange = (e: any) => {
        const value = e.target.value;
        setSearchQuery(value);
        if (value === '') {
            setReload(true);
        }
    };
    const OfferSearch = () => {

        return (
            <React.Fragment>
                <SearchBar
                    searchQuery={searchQuery}
                    handleInputChange={handleInputChange}
                    handleSearch={handleSearch}
                />
            </React.Fragment>
        );
    };


    const paginatorTemp: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1 pagination-no">
                    {t('Items per page') + ': '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: { first: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; last: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; totalRecords: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }) => {
            return (
                <span className='pagination-no'>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };
    const onPageChange = (event: { first: React.SetStateAction<number>; rows: React.SetStateAction<number>; page: React.SetStateAction<number>; }) => {
        setFirst(event.first);
        setLimit(event.rows);
        setPage(event.page);
    };
    const onEditOrView = (edit: boolean, id?: string) => {
        setEdit(edit);
        if (id)
            setId(id);
        setVisible(true);

    };
    const deleteRecord = (i: string) => {
        offersSvc.Delete(i).then(res => {
            if (res) {
                toast.success('Offer deleted successfully');
                setReload(true);
                setDeleteDialog(false)
            } else {
                toast.error('Offer Delete failed');
            }
        });
    }

    useEffect(() => {
        if (!visible)
            setId('');
    }, [visible]);

    useEffect(() => {
        if (reload) {
            offersSvc.getAll((page + 1), limit).then((res: any) => {
                setInterestRate(res.data.user);
                setTotal(res.data.recordcount);
                setReload(false)

            }).catch(() => { setReload(false) });
        }
    }, [reload])
    return (
        <React.Fragment>
            <div className="grid">
                <div className="col-12">
                    <div ><Heading>{t("Offer Rate")}</Heading></div>
                    <div className='common-header-toolbar'>
                        <div>
                            <OfferSearch />
                        </div>
                        <div>
                            <Button tooltip='Reload' tooltipOptions={{ position: "mouse" }} label="" style={{ backgroundColor: '#11B3CF' }} icon={`pi pi-refresh ${reload ? 'pi-spin' : ''}`} className="p-button-success p-button-rounded p-button-raised p-button-secondary  mr-2" onClick={() => { setReload(true); }} />
                            {interestRate && interestRate.length > 0 && <Button tooltip='Export to excel' style={{ backgroundColor: '#11B3CF' }} tooltipOptions={{ position: "bottom" }} icon="pi pi-file-excel" className="p-button-success p-button-rounded p-button-raised p-button-secondary mr-2" onClick={exportCSV} />}
                            <Button tooltip='Create' tooltipOptions={{ position: "mouse" }} label={t("Add New") as string} icon="pi pi-plus" style={{ backgroundColor: '#11B3CF' }} className="p-button-success p-button-raised p-button-sm mr-2" onClick={() => { onEditOrView(false); }} />
                            {/* {selected && selected.length > 1 && <Button tooltip='delete' tooltipOptions={{ position: "mouse" }} label="" icon="pi pi-trash" className="p-button p-button-rounded p-button-raised p-button-danger mr-5" onClick={() => { setDeleteDialog(true);}} />} */}

                        </div>
                    </div>

                    <DataTable
                        ref={dt}
                        value={interestRate}
                        selection={selected}
                        onSelectionChange={(e: any) => setSelected(e.value)}
                        dataKey="_id"
                        className="datatable-responsive custom-datatable"
                        emptyMessage="No data found."
                        scrollable
                        scrollHeight="400px"
                    // responsiveLayout="scroll"
                    >
                        <Column style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} selectionMode="multiple" headerStyle={{ width: '4rem' }}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} header={t("Cust_Id")} field='customer_id.sl_no' ></Column>
                        <Column className="capitalize" style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} header={t("customer name")} body={rowData => rowData.customer_id ? `${rowData.customer_id.first_name} ${rowData.customer_id.middle_name} ${rowData.customer_id.last_name}` : 'N/A'} filterField="customer_id.first_name" ></Column>
                        <Column className="capitalize" style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} header={t("Borrow Type")} field='borrow_Type' ></Column>
                        <Column className="capitalize" style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} header={t("Disburse Type")} field='disburse_Type' ></Column>
                        <Column style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} header={t("Emi Interest (%)")} field='emi_Interest' ></Column>
                        <Column style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} header={t("Rate (%)")} field='rate' ></Column>
                        <Column header={t("action")} className="action" body={actionBodyTemplate} alignHeader={'left'} style={{ minWidth: '150px' }} alignFrozen="right" frozen></Column>


                    </DataTable>
                    <Paginator template={paginatorTemp} first={first} rows={limit} totalRecords={totalRecords} onPageChange={onPageChange} className="justify-content-end my-3"></Paginator>


                    <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Are you sure you want to delete ? </span>}
                        </div>
                    </Dialog>
                </div>
            </div>
            {visible && <OfferModal visible={visible} setVisible={setVisible} viewOnly={edit} id={id} setReload={setReload} />}
        </React.Fragment>
    );
};

export default OfferCustomerList;