import React, { useState } from 'react';
import { Button } from 'primereact/button';
import './feature.css'
import AucationModal from './Modals/aucationModal';
import { useNavigate } from 'react-router-dom';


const FeatureBox = ({ icon, title, description, color, onClick }: any) => (
  <div className="col-md-4 col-sm-6">
    <div className={`ct-featureBox ${color}`}>
      <div className="inner">
        <div><i className={`image ${icon}`}></i></div>
        <p className='tittle'><span>{title}</span></p>
        <p>{description}</p>
        <div ><Button label="View Details" onClick={onClick}/></div>
      </div>
    </div>
  </div>
);

const FeatureBoxes = () => {
  
  const navigate = useNavigate();
  const [visible, setVisble] = useState(false);

 

  return (
    <div className="row">
      <FeatureBox icon="pi pi-credit-card" title="Add Auction Winner"
        description="Add aucation winner and details of prize money"
        color="red" onClick={()=>setVisble(true)} />

      <FeatureBox icon="pi pi-calculator" title="Pay Chitty"
        description="Pay your chitty installments"
        color="" onClick={()=>navigate('/app/transaction/chits/paychitty')} />

      <FeatureBox icon="pi pi-fw pi-history" title="Chit Payment History"
        description="Search and Download previous transaction of customers"
        color="" onClick={()=>navigate('/app/transaction/chits/paychitty')} />

      <FeatureBox icon="pi pi-fw pi-history" title="Previous Auction Transactions"
        description="Search and Download previous auction transaction "
        color="red" onClick={()=>navigate('/app/transaction/chits/previousauctions')}/>

      <FeatureBox icon="pi pi-fw pi-wallet" title="New Chits"
        description="Search for newly announced chitties or create new chitty"
        color="" onClick={()=>navigate('/app/transaction/chits/addchit')} />

      

      {visible && <AucationModal setVisible={setVisble} visible={visible} viewOnly={false}  />}
    </div>


  )
};


export default FeatureBoxes;
