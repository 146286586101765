import { Card } from 'primereact/card';
import { useEffect, useState } from 'react';
import { dashboardSvc } from '../../../services';
import './home.css'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ModalSubHeading } from '../../../components/styledcomponents';
import { Button } from 'primereact/button';
import { dashbordLoanIcoan } from '../../../assets/icons';
import moment from 'moment';
export interface IAppProps { }

export function Home(props: IAppProps) {

  const [data, setData] = useState<any>('');
  const [loanDatas, setLoanDatas] = useState<any>('');
  const [overDuedata, setOverDueData] = useState<any[]>([]);
  const [t] = useTranslation()

  useEffect(() => {
    dashbordData()
    overDueList()

  }, []);

  const dashbordData = () => {
    dashboardSvc.paymentsByThisWeek().then(res => {
      setData(res.data);
      setLoanDatas(res.data.loanDatas)
    });
  };
  const overDueList = () => {
    dashboardSvc.getDuesList().then((res: any) => {
      setOverDueData(res.data.result);
    });
  };

  return (
    <div className="card-group-wrapper">

      {/* Summary first colum */}
      <div>
        <Card title={t("Summary")} style={{ position: 'relative' }} className='dashbord_card'>
        <div style={{ background: "#11B3CF", fontFamily: 'Raleway', padding: '0.1rem',textAlign:'center' }} ><h4 ><span className='ml-2' style={{ fontWeight: 'bold' }}>{loanDatas?.totalLoans} </span>{t("Active")} {t("And")}  <span className='ml-2' style={{ fontWeight: 'bold' }}>{loanDatas?.totalClosedLoans} </span>{t("Closed loans")}</h4></div>
          {/* <div style={{ background: "#11B3CF", fontFamily: 'Raleway', padding: '0.1rem' }} className='flex'><h4 ><span className='ml-2' style={{ fontWeight: 'bold' }}>{loanDatas?.totalLoans} </span>{t("Active loans")}</h4><h4><span className='ml-2' style={{ fontWeight: 'bold' }}>{loanDatas?.totalClosedLoans} </span>{t("Closed loans")}</h4></div> */}
          <div ><p className='total_Borrowing_value'>{t("Active Loan Borrowed")}</p></div>
          <p className='card-value' >
            {loanDatas ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loanDatas?.borrowed) : 'N/A'}
          </p>
          <img  style={{ display: 'block', margin: '1.2rem auto', width: '10rem', height: '10rem' }} src={dashbordLoanIcoan} alt="Loan icon" />
          <div>
            <p className="dashbord-closed-revene">{t("Active Loan Receivable")} :{loanDatas ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loanDatas?.repayable) : 'N/A'} </p>
          </div>
          <div style={{backgroundColor: '#11B3CF', height:"6px" }}/>
          <div style={{textAlign:'center'}}>
          <div className="label-value-container">
            <span className="dashbord-summary-label">{t("Active Loan Revenue")} :</span>
            <span className="dashbord-summary-value">{loanDatas ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loanDatas?.revenue) : 'N/A'} </span>
          </div>
          
          <div className="label-value-container">
            <span className="dashbord-summary-label">{t("Closed loan Revenue")} :</span>
            <span className="dashbord-summary-value"> {loanDatas ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loanDatas?.totalRevenueFromClosedLoans) : 'N/A'}</span>
          </div>
          </div>
          

        </Card>

        <Card style={{ position: 'relative' }} className='mt-3'>
          <center><p className=" dashbord-card-heading">
            {t("No Of Created Loans For Approvel")}
          </p>
          </center>
          <p className='dashboard-loanno' >
            {data?.totalCreatedLoans} {t("Nos")}
          </p>
          <hr />
          <Link to="/app/transaction/loans/addloan?status=created">
            <Button label={t("View More") as string} className='p-button-text viewmore-btn'><i className='pi pi-arrow-right ml-2 mt-1' /></Button>
          </Link>
          <div className='card-corner-color'></div>
          <div className='card-bottom-color'></div>
        </Card>
      </div>

      {/* Current Weekly Outstanding & Last  Weekly Outstanding 2nd column*/}

      <div>
        <Card style={{ position: 'relative' }} >
          <center><p className=" dashbord-card-heading">
            {t("Approved Loans On This Month")}
          </p>
          </center>
          <p className='dashboard-loanno' >
            {loanDatas?.loansOnThisMonth} {t("Nos")}
          </p>
          <hr />
          <Link to="/app/transaction/loans/addloan">
            <Button label={t("View More") as string} className='p-button-text viewmore-btn'><i className='pi pi-arrow-right ml-2 mt-1' /></Button>
          </Link>
          <div className='card-corner-color'></div>
          <div className='card-bottom-color'></div>
        </Card>

        <Card style={{ position: 'relative' }} className='mt-3'>
            <div className='dashbord-card-date'>
              {data ? moment(data.thisWeekDates?.start_date).add(1, 'day').format('DD MMM YYYY') : 'N/A'} to {data ? moment(data.thisWeekDates?.end_date).format('DD MMM YYYY') : 'N/A'}
            </div>

          <div className="label-value-container">
            <span className="dashbord-card-label">{t("Outstanding")} :</span>
            <span className="dashbord-card-value">
              {data ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data.outStandingAmountOnThisWeek) : 'N/A'}
            </span>
          </div>

          <div className="label-value-container">
            <span className="dashbord-card-label">{t("Received")} :</span>
            <span className="dashbord-card-value">
              {data ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data.amountReceivedOnCurrentWeek) : 'N/A'}
            </span>
          </div>

          <div className="label-value-container">
            <span className="dashbord-card-label">{t("Balance")} :</span>
            <span className="dashbord-card-value">
              {data ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data.outStandingAmountOnThisWeek - data.amountReceivedOnCurrentWeek) : 'N/A'}
            </span>
          </div>
          <hr />
          <Link to="/app/paidlist">
            <Button label={t("View More") as string} className='p-button-text viewmore-btn'><i className='pi pi-arrow-right ml-2 mt-1' /></Button>
          </Link>
          <div className='card-corner-color'></div>
          <div className='card-bottom-color'></div>
        </Card>


        <Card style={{ position: 'relative' }} className='mt-3'>
        <div className='dashbord-card-date'>{data ? moment(data.dates?.start_date).add(1, 'day').format('DD MMM YYYY') : 'N/A'} to {data ? moment(data.dates?.end_date).format('DD MMM YYYY') : 'N/A'} </div>


          <div className="label-value-container">
            <span className="dashbord-card-label">{t("Outstanding")} :</span>
            <span className="dashbord-card-value">
            {data ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data.lastWeekOutStandingAmount) : 'N/A'}            </span>
          </div>

          <div className="label-value-container">
            <span className="dashbord-card-label">{t("Received")} :</span>
            <span className="dashbord-card-value">
            {data ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data.lastWeekReceivedAmount) : 'N/A'}
            </span>
          </div>

          <div className="label-value-container">
            <span className="dashbord-card-label">{t("Balance")} :</span>
            <span className="dashbord-card-value">
             {data ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data.lastWeekOutStandingAmount - data.lastWeekReceivedAmount) : 'N/A'}
            </span>
          </div>
          <div className='card-corner-color'></div>
          <div className='card-bottom-color'></div>
        </Card>


      </div>

      {/* Over Due On Last week 3rd column*/}
      <div>
        <Card title={t("Over Due Amount")} style={{ position: 'relative' }} className='dashbord_card'>
          <div className='card-corner-color'></div>
          <p className='card-value'>{data ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data.totalOustandingAmount) : 'N/A'}</p>

          <div>
            <hr />
            {overDuedata.map((overdue: any, index: any) => (
              <div className="custom-card" key={index}>
                <div className="custom-flex mt-2 ">
                  <p className="card-overdue-value">{overdue ? moment(overdue.due_date).format('ll') : 'N/A'}</p>
                  <p className="card-overdue-value">{overdue?.sl_no}</p>
                </div>
                <ModalSubHeading>{overdue?.customer_name}</ModalSubHeading>
                <h3 className="custom-amount">
                  {t("Amount")}: <span className="custom-amount-value">{overdue ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(overdue.amount) : 'N/A'}</span>
                </h3>
              </div>
            ))}
            <Link to="/app/duelist">
              <Button label={t("View More") as string} className='p-button-text viewmore-btn'><i className='pi pi-arrow-right ml-2 mt-1' /></Button>
            </Link>
          </div>

        </Card>
      </div>

    </div>

  );
}
