

// const initialstate = {
//     isExpanded: true
// };

// function sidebarReducer(state =initialstate,action: { type: any; }){
//     switch (action.type){
//         case 'TOGGLE_SIDEBAR':
//             return {
            
//                 ...state,
//                 isExpanded:
//                 !state.isExpanded
                
//             };
            
//             default: 
//             return state;
            
//     }
// }

// export default sidebarReducer;

import { FETCH_USER_PERMISSIONS_SUCCESS } from '../actions/actions';

const initialState = {
  permissions: null,
};

const permissionsReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case FETCH_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      return state;
  }
};

export default permissionsReducer;

