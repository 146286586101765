import React, { useRef, useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Paginator, PaginatorTemplateOptions } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { permissionsSvc, userSvc } from '../../../../services';
import { useTranslation } from 'react-i18next';
import UserPermission from './UserPermission';
import UserRolePermission from './UserRolePermission';
import ActionButton from '../../../../components/common/buttons/actionButton';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import '../master.css'
import SearchBar from '../../../../components/common/searchBar/SearchBar';

const PermissionList = () => {
    const [page, setPage] = useState(0);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [globalFilter, setGlobalFilter] = useState('');
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [selected, setSelected] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [totalRecords, setTotal] = useState(0);
    const [visible, setVisible] = useState(false);
    const [visiblePermission, setVisiblePermission] = useState(false);
    const [visibleRolePermission, setVisibleRolePermission] = useState(false);
    const [edit, setEdit] = useState(true);
    const [id, setId] = useState<string>('');
    const dt = useRef<DataTable<any>>(null);
    const [t] = useTranslation()
    const [searchQuery, setSearchQuery] = useState(''); //For serch bar

    const getUsers = () => {
        permissionsSvc.list((page + 1), limit).then((res: any) => {
            setUsersList(res.data.user);
            setTotal(res.data.recordcount);
        });
    };


    const exportCSV = () => {
        if (dt.current)
            dt.current.exportCSV();
    };



    const confirmDeleteProduct = (product: any) => {
        setDeleteDialog(true);
    };
    const actionBodyTemplate = (rowData: any) => {
        return (
            <>
                <ActionButton label={t('Add Role')} icon="pi pi-user" className="p-button-secondary" onClick={() => { setId(rowData._id); setVisibleRolePermission(true) }} />
                <ActionButton label={t('Add Permission')} icon="pi pi-plus" className="p-button-secondary" onClick={() => { setId(rowData._id); setVisiblePermission(true) }} />
            </>
        );
    };

    // Implement Search bar 
    const handleSearch = () => {
        permissionsSvc.serchUser(searchQuery).then(res => {
            if (res) {
                setUsersList(res.data.employee);
                console.log(res.data)
            }
        });

    };

    const handleInputChange = (e: any) => {
        const value = e.target.value;
        setSearchQuery(value);
        if (value === '') {
            getUsers()
        }
    };
    const userSearch = () => {

        return (
            <React.Fragment>
                <SearchBar
                    searchQuery={searchQuery}
                    handleInputChange={handleInputChange}
                    handleSearch={handleSearch}
                />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center mb-5">
            <div>
                <h3 className="m-0">{t('Users')}</h3>
            </div>
            <div>{userSearch()}</div>
        </div>
    );

    const paginatorTemp: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1 pagination-no">
                        {t('Items per page') + ': '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: { first: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; last: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; totalRecords: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }) => {
            return (
                <span className='pagination-no'>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };
    const onPageChange = (event: { first: React.SetStateAction<number>; rows: React.SetStateAction<number>; page: React.SetStateAction<number>; }) => {
        setFirst(event.first);
        setLimit(event.rows);
        setPage(event.page);
    };
    const onEditOrView = (edit: boolean, id?: string) => {
        setEdit(edit);
        setVisible(false);
        if (id)
            setId(id);
        setVisible(true);

    };
    useEffect(() => {
        getUsers();
    }, [page, limit]);
    useEffect(() => {
        if (!visible)
            setId('');
    }, [visible]);
    return (
        <React.Fragment>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div >{header}</div>
                    <DataTable
                        ref={dt}
                        value={usersList}
                        selection={selected}
                        onSelectionChange={(e: any) => setSelected(e.value)}
                        dataKey="_id"
                        className="datatable-responsive master-datatable"
                        globalFilter={globalFilter}
                        emptyMessage="No data found."
                    >
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} selectionMode="multiple" headerStyle={{ width: '4rem' }}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} header={t("name")} field='display_name' ></Column>
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} header={t("email")} field='email' ></Column>
                        <Column header={t("action")} style={{ textAlign: 'center', flexGrow: 1, flexBasis: '200px' }} alignHeader={'center'} body={actionBodyTemplate} ></Column>
                    </DataTable>
                    <Paginator template={paginatorTemp} first={first} rows={limit} totalRecords={totalRecords} onPageChange={onPageChange} className="justify-content-end my-3"></Paginator>


                </div>
            </div>
            {visiblePermission && <UserPermission visible={visiblePermission} setVisible={setVisiblePermission} id={id} />}
            {visibleRolePermission && <UserRolePermission visible={visibleRolePermission} setVisible={setVisibleRolePermission} id={id} />}

        </React.Fragment>
    );
};

export default PermissionList;