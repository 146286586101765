import { ILoan } from "../interfaces";
import { axiosInstance } from "./interceptors";

export const save = async (request: ILoan) => {
    return await axiosInstance.post(`/loan`, request);
};

export const getAll = async () => {
    return await axiosInstance.get(`/loan/getAll`);
};

export const get = async (id: string) => {
    return await axiosInstance.get(`/loan/${id}`);
};

export const serchLoan = async (name:any,page: Number, limit: Number) => {
    return await axiosInstance.get(`/loan/loan/search?name=${name}&page=${page}&limit=${limit}`);
  };

export const getLoanDetails = async (loan_id: string) => {
    return await axiosInstance.get(`/loan/loandetails/get?loan_id=${loan_id}`);
};
export const getrepaymentSchedule= async (loan_id: string) => {
    return await axiosInstance.get(`/loan/repaymentschedule/get?loan_id=${loan_id}`);
};

export const update = async (request: ILoan) => {
    return await axiosInstance.put(`/loan`, request)
};

export const list = async (page: Number, limit: Number) => {
    return await axiosInstance.get(`/loan/list?page=${page}&limit=${limit}`)
};

export const searchByStatus = async (status:string,region_id:number|string,agent_id:number|string,limit: string, page: string) => {
    return await  axiosInstance.get(`/loan/searchByStatus?status=${status}&region_id=${region_id}&agent_id=${agent_id}&page=${page}&limit=${limit}`);
}

export const approve = async (id: string,date:any) => {
    return await axiosInstance.put(`/loan/approve`, { id,date })
};
export const rejected = async (id: string) => {
    return await axiosInstance.put(`/loan/reject`, { id })
};
export const Delete = async (id: string) => {
    return await axiosInstance.delete(`/loan/${id}`);
};

export const multipleDelete = async (ids:any) => {
    return await  axiosInstance.post(`/loan/deletemany/`,ids);
}

export const getApprovedLoans = async (page: Number, limit: Number) => {
    return await axiosInstance.get(`/loan/getapprovedloans?page=${page}&limit=${limit}`)
};
export const serchApprovedLoans = async (name:any,page: Number, limit: Number) => {
    return await axiosInstance.get(`/loan/search/approvedLoans?name=${name}&page=${page}&limit=${limit}`);
  };
export const getpreclosureamount = async (id:string) => {
    return await  axiosInstance.get(`/loan/getpreclosureamount/${id}`);
}

export const preCloseLoan = async (req:any) => {
    return await axiosInstance.post(`/loan/precloseloan`, req);
};

export const getRepayStatus = async (id:string) => {
    return await  axiosInstance.get(`/loan/getrepaystatus/${id}`);
}

export const getDuesList = async (limit: string, page: string) => {
    return await  axiosInstance.get(`/loan/getdues?page=${page}&limit=${limit}`);
}


export const topupLoan = async (requested_amt: ILoan,loan_id:string) => {
    return await axiosInstance.post(`/loan/topuploan`, {requested_amt,loan_id});
};

export const searchDuePayments = async (from: string,to:string,page: any, limit: any) => {
    return await axiosInstance.post(`/loan/searchPayments?page=${page}&limit=${limit}`, {from,to});
};


export const closedLoanList = async (region:string|number,agent:string|number,page: Number, limit: Number) => {
    return await axiosInstance.get(`/loan/getClosedLoans?region_id=${region}&agent_id=${agent}&page=${page}&limit=${limit}`)
};

export const serchClosedLoan = async (name:any,page: Number, limit: Number) => {
    return await axiosInstance.get(`/loan/search/closedloans?name=${name}&page=${page}&limit=${limit}`);
  };

export const regionList = async () => {
    return await axiosInstance.get(`/master/region/autoSuggest`);
};

export const agentList = async (id:string) => {
    return await axiosInstance.get(`/master/agency/autoSuggest/regionid/${id}`);
};


  
  


  