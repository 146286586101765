import { Form, Formik, FormikHelpers } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { AutoComplete } from "primereact/autocomplete";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../../components/common/ErrorMessage";
import useDebounce from "../../../../../helpers/debounce";
import { chitfundSvc, customerSvc, loanSvc } from "../../../../../services";
import { useTranslation } from "react-i18next";
import { CancelButton } from "../../../../../components/styledcomponents";
import { Calendar } from "primereact/calendar";
import { AuthContext } from "../../../../../context";
import { InputText } from "primereact/inputtext";
import { autionSave } from "../../../../../validation";



const AucationModal = ({ setVisible, visible = false, viewOnly = true,}: IAucationModal) => {
    const { userId } = useContext(AuthContext);
    const initial = {
        chit_id: "",
        customer_id: "",
        auctioned_date: new Date(),
        auction_amnt: 0,
        created_by:userId,
    };
    const [initialValues, setInitialValues] = useState(initial);
    const [t] = useTranslation()
    const [customer, setCustomer] = useState([]);
    const [customerSelected, setCustomerSelected] = useState(null);
    const [customerSearch, setcustomerSearch] = useState<string>('');

    const [chitId, setChitId] = useState([]);
    const [chitIdSelected, setChitIdSelected] = useState(null);
    const [chitIdSearch, setChitIdSearch] = useState<string>('');

    const debouncedChitIdSearch = useDebounce<string>(chitIdSearch, 500);
    const debouncedCustomerSearch = useDebounce<string>(customerSearch, 500);
    

    // useEffect(() => {
    //     if (debouncedValue && debouncedValue.length > 0){
    //         customerSvc.getAutoSuggest(customerSearch).then(res => {
    //             setCustomer(res.data);
    //         });

    //         chitfundSvc.getAutoSuggest(chitIdSearch).then(res => {
    //             setChitId(res.data);
    //         });

    //     }

            
    // }, [debouncedValue]);

    useEffect(() => {
        if (debouncedChitIdSearch && debouncedChitIdSearch.length > 0){
            chitfundSvc.getAutoSuggest(debouncedChitIdSearch).then(res => {
                setChitId(res.data);
            });
        }
    }, [debouncedChitIdSearch]);
    
    useEffect(() => {
        if (debouncedCustomerSearch && debouncedCustomerSearch.length > 0){
            customerSvc.getAutoSuggest(debouncedCustomerSearch).then(res => {
                setCustomer(res.data);
            });
        }
    }, [debouncedCustomerSearch]);
    
   


    const onSubmit = async (values:any, formikHelpers: FormikHelpers<any>) => {
        const res =  await chitfundSvc.aucationSave(values);
        toast.success("Success");
        setCustomerSelected(null);
        setcustomerSearch('');
        formikHelpers.resetForm();
        setInitialValues(initial)
        setVisible(false);
    };

    const onHide = () => {
        setInitialValues(initial);
        setCustomerSelected(null);
        setcustomerSearch('');
        setVisible(false);
    };
    const DialogFooter = (
        <div className="flex flex-wrap justify-content-end gap-3">
            <CancelButton label={t("Cancel") + ''} onClose={onHide} />
            <Button label={t("Save") as string} style={{ background: '#11B3CF' }} icon="pi pi-check" className="p-button-success p-button-raised" type='submit' />
        </div>);
    return (
        <Dialog header={t("Create Auction")} visible={visible} maximizable style={{ width: '50vw' }} onHide={onHide}>
            <Formik enableReinitialize initialValues={initialValues} validationSchema={autionSave} onSubmit={onSubmit}>
                {({ values, errors, getFieldProps, touched, setFieldValue }) => (
                    <Form>

                        <div className="grid p-fluid mt-3">
                            
                            <div className="field col-12 md:col-5">
                                <label htmlFor="chit_id" className="label-raleway-font">{t("Chit Sl No")}</label>
                                {/* <InputText id="chit_id" {...getFieldProps(('chit_id'))} name='chit_id' /> */}
                                <AutoComplete field="serial_no" value={chitIdSelected} suggestions={chitId} completeMethod={(e) => { setChitIdSearch(e.query); }} onChange={(e) => { setFieldValue("chit_id", e.value.value); setChitIdSelected(e.value); }} />

                                {touched.chit_id && errors.chit_id && <ErrorMessage message={errors.chit_id} id="chit_id" />}
                            </div>

                            <div className="field col-12 md:col-5">
                                <label htmlFor="auctioned_date" className="label-raleway-font">{t("Auction Date")}</label>
                                <Calendar
                                    id="auctioned_date"
                                    name="auctioned_date"
                                    value={values.auctioned_date}
                                    onChange={(e) => setFieldValue('auctioned_date', new Date(e.value as Date))}
                                    dateFormat="dd/mm/yy"
                                    showIcon
                                    className="w-full" />
                                {touched.auctioned_date && errors.auctioned_date && <ErrorMessage message={errors.auctioned_date} id="auctioned_date" />}
                            </div>


                            <div className="field col-12 md:col-5">
                                <label htmlFor="customer_id" className="label-raleway-font">{t("customer")}</label>
                                <AutoComplete field="name" value={customerSelected} suggestions={customer} completeMethod={(e) => { setcustomerSearch(e.query); }} onChange={(e) => { setFieldValue("customer_id", e.value.value); setCustomerSelected(e.value); }} />
                                {touched.customer_id && errors.customer_id && <ErrorMessage message={errors.customer_id} id="customer_id" />}
                            </div>

                            <div className="field col-12 md:col-5">
                                <label htmlFor="auction_amnt" className="label-raleway-font">{t("Auction Amount")}</label>
                                <InputNumber id="auction_amnt" minFractionDigits={2} maxFractionDigits={2} onChange={(e) => setFieldValue('auction_amnt', e.value)} value={values.auction_amnt || 0} name='auction_amnt' />
                                {touched.auction_amnt && errors.auction_amnt && <ErrorMessage message={errors.auction_amnt} id="auction_amnt" />}
                            </div>
                        </div>
                        {DialogFooter}
                    </Form>)}
            </Formik>

        </Dialog>
    );
};

export default AucationModal;



interface IAucationModal {
    visible: boolean;
    setVisible(val: boolean): void;
    viewOnly: boolean,
}