import { createContext, useState, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserPermissionsRequest } from '../store/actions/actions';
import { getCookie, setCookie } from '../utils/cookieUtils';
import { profileSvc } from '../services';

type AuthContextType = {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  userId: string | null; 
  setUserId: (value: string | null) => void; 
};

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  setIsAuthenticated: () => { },
  userId: null,
  setUserId: () => { }
});

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(getCookie('isAuthenticated') === 'true');
  const [userId, setUserId] = useState<string | null>(null);

  const dispatch = useDispatch();
  

  useEffect(() => {
    const user = getCookie('user');
    if (user) {
      const data = user;
      const userId = data.user.id;
      dispatch(fetchUserPermissionsRequest(userId));
      setUserId(userId); // Set the userId state
    }
  }, [dispatch]);


  const handleSetIsAuthenticated = (value: boolean) => {
    setIsAuthenticated(value);
    setCookie('isAuthenticated', value.toString());
  };

  const authContextValue = { isAuthenticated, setIsAuthenticated: handleSetIsAuthenticated,userId,setUserId,};

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

