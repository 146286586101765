import React, { useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import { Paginator, PaginatorTemplateOptions } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import './datagrid.css'
import { Heading } from '../styledcomponents';
import 'jspdf-autotable';
import PdfExporter from '../common/pdfExporter/pdfExporter';

interface IDataGridProps {
    tittle: string;
    data: any[];
    totalRecords: number;
    reload: boolean,
    setReload(val: boolean): void,
    columns: Array<{
        field: string,
        header: string;
        type?: string;


    }>;
    extraAction: any;
    paginationProps(page: string, limit: string): void;
    onCreateOrEdit(edit: boolean, id?: string): void;
    deleteByIdList(selectedIds: any): unknown;
    SearchBar?: any;
    filter?: any;
    addNew?: boolean; //For add new data (Loan creation)
    report?: any[];

}
declare module 'jspdf' {
    interface jsPDF {
        autoTable: (options: any) => jsPDF;
    }
}
const DataGridV2 = (props: IDataGridProps) => {
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    // const [globalFilter, setGlobalFilter] = useState('');
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [id, setId] = useState<string | undefined>();
    const [selected, setSelected] = useState([]);

    const dt = useRef<DataTable<any>>(null);
    const [t] = useTranslation();

    const onPageChange = (event: { first: React.SetStateAction<number>; rows: React.SetStateAction<number>; page: React.SetStateAction<number>; }) => {
        setFirst(event.first);
        setLimit(event.rows);
        props.paginationProps(event.page.toString(), event.rows.toString());
    };

    const exportCSV = () => {
        if (dt.current)
            dt.current.exportCSV();

    };
    const formatDate = (value: any) => {
        return value.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };


    const multipleDelete = () => {
        const selectedIds = (selected as { _id: string }[]).map(item => item._id);
        if (selectedIds.length) {
            props.deleteByIdList({ ids: selectedIds });
            setSelected([]) //hide multiple delete button after delete
        }
    };
    const dateBodyTemplate = (rawData: any, field: string) => {
        return formatDate(new Date(rawData[field]));
    };
    const statusBodyTemplate = (rawData: any) => {
        const txt = rawData.status.toString();
        return <Badge value={rawData.status} severity={txt === 'approved' ? "success" : (txt === 'rejected' ? "warning" : txt === 'created' ? 'warning' : txt === 'closed' ? 'danger' : 'info')} />;
    };
    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className='flex ml-auto' style={{ textAlign: 'center' }}>
                {<props.extraAction rowData={rowData} />}
            </div>
        );
    };

    const deleteDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { multipleDelete(); setDeleteDialog(false) }} />
        </>
    );

    const paginatorTemp: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1 pagination-no">
                        {t('Items per page') + ': '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: { first: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; last: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; totalRecords: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }) => {
            return (
                <span className="pagination-no">
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };
    const formatIndianRupee = (rawData: any, field: string) => {
        const formatter = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2
        });
        return rawData[field] !== undefined ? formatter.format(rawData[field]) : "";

    };
    return (
        <div className="grid">
            <div className="col-12">
                <div><Heading>{t(props.tittle)}</Heading></div>
                <div className='common-header-toolbar'>
                    <div className='flex'>

                        {props.SearchBar && <props.SearchBar />}
                        {selected && selected.length > 1 && <Button label={t("delete") + ''} icon="pi pi-trash" className="p-button-danger p-button-raised p-button-sm ml-3" onClick={() => { setDeleteDialog(true); }} style={{ backgroundColor: '#11B3CF', border: 'none' }} />}
                        <div >{props.filter && <props.filter />}</div>
                    </div>
                    <div>
                        <Button tooltip='Reload' style={{ backgroundColor: '#11B3CF', width: '35px', height: '35px' }} tooltipOptions={{ position: "mouse" }} label="" icon={`pi pi-refresh ${props.reload ? 'pi-spin' : ''}`} className="p-button-success p-button-rounded p-button-raised p-button-secondary  mr-2" onClick={() => { props.setReload(true); }} />
                        {props.data && props.data.length > 0 && <Button tooltip='Export to excel' style={{ backgroundColor: '#11B3CF',width: '35px', height: '35px' }} tooltipOptions={{ position: "bottom" }} icon="pi pi-file-excel" className="p-button-success p-button-rounded p-button-raised p-button-secondary mr-2" onClick={exportCSV} />}
                        {props.data && props.data.length > 0 && <PdfExporter tittle={props.tittle} columns={props.columns} data={props.data} report={props.report} />}
                        {props.addNew && <Button style={{ backgroundColor: '#11B3CF' }} className="p-button-success p-button-raised p-button-sm mr-2" tooltipOptions={{ position: "mouse" }} label={t("Add New") + ' ' + props.tittle} icon="pi pi-plus" onClick={() => { props.onCreateOrEdit(true); }} />}

                    </div>
                </div>
                {props.report &&
                    <div className="closed-report-data" >
                        {props.report && props.report.map((item: any) => (
                            <div key={item.key} className="report-item" style={{ position: 'relative' }}>
                                <div className="report-key">
                                    <i className="pi pi-info-circle" /> {t(item.key)}
                                </div>
                                <div className="report-value">{item.value}</div>

                                <div className='report-corner-color'></div>
                                <div className='report-bottom-color'></div>
                            </div>
                        ))}
                    </div>}
                <DataTable
                    ref={dt}
                    value={props.data}
                    selection={selected}
                    sortField="createdAt"
                    sortOrder={-1}
                    onSelectionChange={(e: any) => setSelected(e.value)}
                    dataKey="_id"
                    className="datatable-responsive custom-datatable"
                    // globalFilter={globalFilter}
                    emptyMessage={t("no_data")}
                    // scrollDirection="both"
                    scrollable
                    scrollHeight="400px"
                >
                    <Column selectionMode="multiple" frozen alignFrozen='left'></Column>
                    {props.columns.map((col, i) => {
                        return (
                            col.type && col.type === 'date' ? (
                                <Column key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} header={t(col.header)} body={(e) => dateBodyTemplate(e, col.field)} />
                            ) : col.type === 'status' ? (
                                <Column className='capitalize' key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} body={statusBodyTemplate} header={t(col.header)} />
                            ) : col.type === 'amount' ? (
                                <Column key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} field={col.field} header={t(col.header)} body={(e) => formatIndianRupee(e, col.field)} />
                            ) : (
                                <Column className='capitalize' key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} field={col.field} header={t(col.header)} />
                            )
                        );
                    })}
                    <Column header={t("action")} className="action" body={actionBodyTemplate}  alignHeader={'left'} alignFrozen="right" frozen></Column>
                </DataTable>
                <Paginator template={paginatorTemp} first={first} rows={limit} totalRecords={props.totalRecords} onPageChange={onPageChange} className="justify-content-end my-3"></Paginator>

                <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {!id ? <span>Are you sure you want to delete the selected items?</span> : <span>Are you sure you want to delete ?</span>}
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default DataGridV2;


