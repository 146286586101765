import styled from 'styled-components';
const Heading = styled.h2`
color: #000;
font-family: Raleway;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 18px;
  /* Add more styles as needed */
`;

export default Heading;
