export const FETCH_USER_PERMISSIONS_REQUEST = 'FETCH_USER_PERMISSIONS_REQUEST';
export const FETCH_USER_PERMISSIONS_SUCCESS = 'FETCH_USER_PERMISSIONS_SUCCESS';
export const FETCH_USER_PERMISSIONS_FAILURE = 'FETCH_USER_PERMISSIONS_FAILURE';

export const fetchUserPermissionsRequest = (userId: string) => ({
  type: FETCH_USER_PERMISSIONS_REQUEST,
  payload:userId,
});

export const fetchUserPermissionsSuccess = (permissions: any) => ({
  type: FETCH_USER_PERMISSIONS_SUCCESS,
  payload: permissions
});

export const fetchUserPermissionsFailure = (error: any) => ({
  type: FETCH_USER_PERMISSIONS_FAILURE,
  payload: error,
});

