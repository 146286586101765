import React, { useState, useEffect } from "react";
import DataGrid from "../../../../components/datagrid/DataGrid";
import { regionSvc } from '../../../../services';
import RegionModal from "./regionModal";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import ViewDetailsModal from "../../../../components/modal/viewDetailsModal";
import SearchBar from "../../../../components/common/searchBar/SearchBar";
import ActionButton from "../../../../components/common/buttons/actionButton";


const RegionList: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');
  const [visible, setVisble] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [id, setId] = useState<string | undefined>();
  const [viewDetails, setviewDetails] = useState<boolean>(false);
  const[values,setValues]=useState<any>('')
  const [searchQuery, setSearchQuery] = useState(''); //For serch bar
  const [t, i18n] = useTranslation()
  const columns = [
    { field: 'sl_no', header: 'Sl No' },
    { field: 'region_name', header: 'Name' },
    { field: 'location', header: 'Location' },
    { field: 'region_manager', header: 'Manger Name' },
    {
      field: 'status', header: 'Status', type: 'status', status: [
        { key: 'active', value: 'success' },
        { key: 'Active', value: 'info' },
        { key: 'rejected', value: 'danger' },
      ]
    }
  ];
  useEffect(() => {
    regionData()
  }, [limit, page]);

  const regionData = () => {
    regionSvc.list(String(limit), String(page)).then(res => {
      setTableData(res.data.data);
      settotalRecords(res.data.recordcount);
    });
  };

  const handleStatusUpdate = (e: any, id: string,newStatus:any) => {
    regionSvc.statusUpdate(id, newStatus).then(res => {
        regionData();
    });
  };

  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };
  const onCreateOrUpdate = (i: string) => {
    setId(i);
    setVisble(true);
  };

  const details=(id:string)=>{
    regionSvc.get(id).then(res =>setValues(res.data));
    setviewDetails(true)
}

const agencyDetails: any = {
    "Region Id": values.sl_no?values.sl_no:"N/A", 
    "Region Name": values.region_name?values.region_name:"N/A",
    "Location": values.location?values.location:"N/A",
    "Region Manager":values.region_manager?values.region_manager:"N/A",
    "status": values.status?values.status:"N?A",
};


  const permissionList = useSelector((state: any) => state.permissions.permissions);

  const deleteRecord = (i: string) => {
    regionSvc.Delete(i).then(res => {
      if (res) {
        toast.success('Region deleted successfully');
        regionData();
      } else {
        toast.error('Failed to delete region');
      }
    });
  }

  const deleteByIdList = (i: any) => {
    regionSvc.multipleDelete(i).then(res => {
      if (res) {
        toast.success('Region deleted successfully');
        regionData();
      } else {
        toast.error('Failed to delete region');
      }
    });
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { deleteRecord(id ?? " "); setDeleteDialog(false) }} />
    </>
  );
     // Implement Search bar 
     const handleSearch = () => {
      regionSvc.searchRegion(searchQuery).then(res => {
        if (res) {
          setTableData(res.data.regionData);
        }
      });
  
    };
  
    const handleInputChange = (e: any) => {
      const value = e.target.value;
      setSearchQuery(value);
      if (value === '') {
        regionData()
      }
    };
    const RegionSearch = () => {
  
      return (
        <React.Fragment>
          <SearchBar
            searchQuery={searchQuery}
            handleInputChange={handleInputChange}
            handleSearch={handleSearch}
          />
        </React.Fragment>
      );
    };
  const extraAction = ({ rowData }: any) => {
    const hasViewPermission = permissionList && permissionList.includes('pages.admin.region.get');
    const hasUpdatePermission = permissionList && permissionList.includes('pages.admin.region.update');
    const hasDeletePermission = permissionList && permissionList.includes('pages.admin.region.delete');
    return <> 
      {hasViewPermission &&  (<ActionButton label={t("View")+''} icon="pi pi-eye" className="p-button-secondary"  onClick={() => { setId(rowData._id); details(rowData._id) }} />)}
      { hasUpdatePermission && (<ActionButton label={t("Update")+''}  icon="pi pi-pencil" className="p-button-secondary" onClick={() => { onCreateOrUpdate(rowData._id); }} />)}
      { hasDeletePermission && (<ActionButton label={t("Delete")+''}  icon="pi pi-trash" className="p-button-secondary" onClick={() => { setDeleteDialog(true); setId(rowData._id) }} />)}
    </>;
  };

  return (
    <>
      <DataGrid tittle={t("Region")} data={tableData} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} onCreateOrEdit={onCreateOrUpdate}
        deleteByIdList={deleteByIdList} extraAction={extraAction} handleStatusUpdate={handleStatusUpdate} SearchBar={RegionSearch}/>
      <RegionModal id={id} setVisible={setVisble} visible={visible} updateTableData={regionData} />
      <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {!id ? <span>Are you sure you want to delete the selected items?</span> : <span>Are you sure you want to delete ?</span>}
        </div>
      </Dialog>
     {viewDetails && <ViewDetailsModal  setVisible={setviewDetails} visible={viewDetails} details={agencyDetails} tittle={"Region Details"}/>}
    </>
  );
};

export default RegionList;