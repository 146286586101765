import React, { useRef, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge';
import { Paginator, PaginatorTemplateOptions } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import './datagrid.css'
import InputGroup from 'react-bootstrap/esm/InputGroup';
import { Heading } from '../styledcomponents';

interface IDataGridProps {
    tittle: string;
    data: any[];
    totalRecords: number;

    paginationProps(page: string, limit: string): void;
    columns: Array<{
        field: string,
        header: string;
        type?: string;


    }>;
    extraAction: any | undefined;
    SearchBar?: any | undefined;



}
const DataGridV3 = (props: IDataGridProps) => {
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [globalFilter, setGlobalFilter] = useState('');
    const [id, setId] = useState<string | undefined>();
    const [selected, setSelected] = useState([]);
    const dt = useRef<DataTable<any>>(null);
    const [t] = useTranslation();

    const onPageChange = (event: { first: React.SetStateAction<number>; rows: React.SetStateAction<number>; page: React.SetStateAction<number>; }) => {
        setFirst(event.first);
        setLimit(event.rows);
        props.paginationProps(event.page.toString(), event.rows.toString());
    };


    const exportCSV = () => {
        if (dt.current)
            dt.current.exportCSV();
    };
    const formatDate = (value: any) => {
        return value.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };


    const dateBodyTemplate = (rawData: any, field: string) => {
        return rawData[field] ? formatDate(new Date(rawData[field])) : '';
    };

    const statusBodyTemplate = (rawData: any) => {
        const status = rawData.status.toString();

        if (status === 'partially paid') {
            return (
                <div >
                    <Badge value="Partially Paid" severity="warning" />
                    {props.extraAction ? <props.extraAction rowData={rawData} /> : null}
                </div>
            );
        }

        return <Badge value={status} severity={status === 'paid' ? 'success' : (status === 'pending' ? 'warning' : status === 'created' ? 'warning' : status === 'closed' ? 'danger' : 'info')} />;
    };

    const formatIndianRupee = (rawData: any, field: string) => {
        const formatter = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2
        });
        return rawData[field] !== undefined ? formatter.format(rawData[field]) : "";

    };


    const paginatorTemp: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1 pagination-no">
                        {t('Items per page') + ': '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: { first: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; last: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; totalRecords: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }) => {
            return (
                <span className="pagination-no">
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className='common-header-toolbar'>
                    <div>
                        <div><Heading>{t(props.tittle)}</Heading></div>
                    </div>
                    <div className='flex'>
                        {props.data && props.data.length > 0 && <Button tooltip='Export to excel' style={{ backgroundColor: '#11B3CF',width: '35px', height: '35px' }} tooltipOptions={{ position: "bottom" }} icon="pi pi-file-excel" className="p-button-success p-button-rounded p-button-raised p-button-secondary mr-2" onClick={exportCSV} />}

                        {props.SearchBar ? <props.SearchBar /> :
                            <div className="search-bar mt-1">
                                <InputGroup>
                                    <span className="p-input-icon-left">
                                        <i className="pi pi-search" />
                                        <InputText
                                            onInput={(e: any) => setGlobalFilter(e.target.value)}
                                            placeholder={t("Search...") as string}
                                            style={{ width: '300px',borderRadius:'0px',height:'2.5rem' }}
                                        />
                                    </span>
                                    <Button label={t("Search") + ''} style={{ backgroundColor: '#11B3CF',height:'2.5rem' }} className='p-button-success' />
                                </InputGroup>
                            </div>
                        }

                    </div>
                </div>

                <DataTable
                    ref={dt}
                    value={props.data}
                    selection={selected}
                    sortField="paid_on"
                    sortOrder={-1}
                    onSelectionChange={(e: any) => setSelected(e.value)}
                    dataKey="_id"
                    className="datatable-responsive mt-5 custom-datatable"
                    globalFilter={globalFilter}
                    emptyMessage={t("no_data")}
                    // scrollDirection="both"
                    scrollable
                    scrollHeight="400px"
                >

                    {props.columns.map((col, i) => {
                        return (
                            col.type && col.type === 'date' ?
                                <Column key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} header={t(col.header)} body={(e) => dateBodyTemplate(e, col.field)} />
                                :
                                (col.type === 'amount' ? <Column key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} field={col.field} header={t(col.header)} body={(e) => formatIndianRupee(e, col.field)} />
                                    : col.type === 'status' ? <Column className='capitalize' key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} body={statusBodyTemplate} header={t(col.header)} />
                                        : <Column className='capitalize' key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} field={col.field} header={t(col.header)} />)
                        );
                    })}


                </DataTable>
                <Paginator template={paginatorTemp} first={first} rows={limit} totalRecords={props.totalRecords} onPageChange={onPageChange} className="justify-content-end my-3"></Paginator>

            </div>
        </div>
    );
};

export default DataGridV3;
