import { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './chatBot.css'
import axios from 'axios';
import styled from 'styled-components';
import chatBot from '../../../assets/chatbot.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faComment, faUser, faRobot } from "@fortawesome/free-solid-svg-icons";
import Spinner from 'react-bootstrap/Spinner';
import Chat from '../../../services/chatService';

function ChatBot() {



  const MessageContainer = styled.div`
  margin: 5px;
  padding: 8px;
  border-radius: 10px;
  background-color: ${(props) => (props.type === 'bot' ? '#ffffff' : '#11B3CF')};
  color: ${(props) => (props.type === 'error' ? 'red' : 'black')};
  word-wrap: break-word; /* Optional: Allow long words to break into a new line */
`;

  const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.type === 'user' ? 'flex-end' : 'flex-start')};
`;

  const ChatMessage = ({ type, text }) => {
    const icon = type === 'user' ? faUser : faRobot; // Choose appropriate icons for user and bot

    return (
      <MessageWrapper type={type}>
        {type === 'bot' && (
          <FontAwesomeIcon icon={icon} className='text-primary' />
        )}
        <MessageContainer type={type}>
          {text}
        </MessageContainer>
        {type === 'user' && (
          <FontAwesomeIcon icon={icon} className='text-primary' />
        )}
      </MessageWrapper>
    );
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [messages, setMessages] = useState([{ text: "Hi, How can i Assist You ?", type: 'bot' }]);
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchChatbotResponse = async (input) => {
    try {
      setLoading(true);
      // Fetch chatbot response
      // const response = await axios.post('YOUR_API_ENDPOINT', { input: inputText });
      console.error('responseData:');
     const responseData = await Chat(inputText);
      console.error('responseData:',responseData);
      // Update the state with the bot's response
      const botMessage = { text: responseData, type: 'bot' };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching chatbot response:', error);

      // Display error message
      const errorMessage = { text: 'Hello, user! I am not trained yet', type: 'bot' };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessage = () => {
    if (inputText.trim() !== '') {
      setMessages([...messages, { text: inputText, type: 'user' }]);
      fetchChatbotResponse(inputText);
      setInputText('');
    }

  };
  const ChatButton = styled(Button)`
  position: fixed;
  bottom: 20px;
  right: 10px;
  background-color: #11B3CF;
  border: none; /* Remove border color */
  border-radius: 50%;
  &:hover {
    background-color: #11B3CF; 
  }
`;


  const LogoContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
`;

  const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
  return (
    <div >


      {/* <ChatButton onClick={handleShow} className="chat-bot-visible">
        <img src={chatBot} alt="Image Alt Text" style={{ height: '50px', width: '45px' }} />
      </ChatButton> */}
      <Button onClick={handleShow} style={{position:'fixed',bottom:'20px',right:"10px",backgroundColor:'#11B3CF',border:'none'}}><FontAwesomeIcon icon={faComment} size="2x" color="#fff" /></Button> 


      <Modal show={show} className='chat-bot' onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <LogoContainer>
            <IconWrapper>
              <FontAwesomeIcon icon={faComment} size="2x" color="#fff" />
            </IconWrapper>
          </LogoContainer>
          <Modal.Title >Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: "300px", overflowY: 'auto', }}>
            {messages.map((message, index) => (
              <ChatMessage key={index} type={message.type} text={message.text} />
            ))}
            {loading && (
              <ChatMessage key="loading" type="bot" text="Loading ..." />
            )}
          </div>
          <div>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Type message here ....."
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
              />
              <Button id="button-addon2" onClick={handleSendMessage} disabled={loading}>
                {/* Show loading animation or send icon based on loading state */}
                {loading ? (
                  <Spinner animation="border" role="status" variant="light">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <FontAwesomeIcon icon={faPaperPlane} size='1x' />
                )}
              </Button>
            </InputGroup>
          </div>
        </Modal.Body>

      </Modal>
    </div>
  );
}

export default ChatBot;
