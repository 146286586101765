import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { permissionsSvc } from '../../../../services';
import { toast } from "react-toastify";
import { ListBox } from 'primereact/listbox';


const UserRolePermission = ({ setVisible, visible = false, id }: IRoleModal) => {
  const [roles, setRoles] = useState<IRole[]>([]);
  const [userid, setId] = useState(id);
  const [selectedRoles, setSelectedRoles] = useState<IRole[]>([]);

  useEffect(() => {
    permissionsSvc.roleList().then((res: any) => {
      setRoles(res.data);
    });
    permissionsSvc.getUserRoles(userid).then(res => {
      const { data } = res.data;
      const roles = data.map(({ _id, name }:any) => ({ _id, name }));
      setSelectedRoles(roles);
    });
 
  }, [id]);
 
  const onSubmit = async () => {
    if (selectedRoles.length > 0) {
      const res = await permissionsSvc.saveRole(userid, selectedRoles.map(role => role._id));
      if (res) {
        toast.success("Permissions updated");
        setVisible(false)
      }
    }
  };
  const DialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={() => {setVisible(false) }} />
      <Button label={!id ? 'Update' : 'Save'} icon="pi pi-check" className="p-button-primary"  onClick={onSubmit} style={{ background: '#11B3CF',border:'none' }}/>
    </>
  );
  return (
    <Dialog header="Add Role" footer={DialogFooter} visible={visible} maximizable style={{ width: '50vw' }} onHide={() => setVisible(false)}>
      <ListBox options={roles} optionLabel="name" className="capitalize" value={selectedRoles} onChange={(e) => setSelectedRoles(e.value)} multiple />
    </Dialog>
  );
};
export default UserRolePermission;

interface IRoleModal {
  visible: boolean;
  setVisible(val: boolean): void;
  id: string;
}
interface IRole {
  _id: string;
  name: string;
}
