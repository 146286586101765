import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { chitfundSvc,} from '../../../../services';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { InputText } from 'primereact/inputtext';
import useDebounce from "../../../../helpers/debounce";
import { Heading } from '../../../../components/styledcomponents';
import moment from 'moment';
import DataGridV3 from '../../../../components/datagrid/DataGridV3';


const PreviousAuctionsList = () => {
    const [dataTableVisible, setDataTableVisible] = useState(false)

    const initial: any = {
        chit_id: "",
    }
    const [initialValues, setInitialValues] = useState<any>(initial)
    const [auctionData, setAuctionData] = useState<any>([]);
    const [chitId, setChitId] = useState('');
    const [totalRecords, settotalRecords] = useState(0);
    const [page, setPage] = useState('1');
    const [limit, setLimit] = useState('10');
    const [t, i18n] = useTranslation()

    let error: any = null;

    const columns = [
        { field: 'aution_customer.first_name', header: 'customer name' },
        { field: 'auction_amount', header: 'Auctioned Amount',type: 'amount' },
        { field: 'total_divident', header: 'Dividend', type: 'amount' },
        // { field: 'paid_amount', header: 'Paid Amount', type: 'amount' },
        { field: 'auctioned_date', header: 'Auction On', type: 'date' },
        { field: 'next_installment_amount', header: 'Next Installment Amount', type: 'amount' },
    ];

    

    const getPaginationProps = (p: string, l: string) => {
        setPage((Number(p) + 1).toString());
        setLimit(l);
    };

    const onSubmit = async () => {
        try {
            if (chitId) {
               const res = await chitfundSvc.getAuctionReport(chitId,String(page),String(limit), );
                setDataTableVisible(true);
                setAuctionData(res.data.autions);
                settotalRecords(res.data.totalRecords);
            } 
            
            

        } catch (error) {
            setDataTableVisible(false);
        }
        // setCustomerSelected(null);
    };

    const getIndianFormattedDate = (date: any) => {
        return moment(date).format('DD/MM/YYYY');
    };


    


    return (
        <React.Fragment>
            <div className='repaymentAmt'>
                <div><Heading className="mb-4">{t("Chit Auctions")}</Heading></div>


                <Formik initialValues={{ chit_id: '' }}
                    // validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className="p-inputgroup">
                                <InputText id="chit_id" name="chit_id" value={chitId} onChange={(e: any) => setChitId(e.target.value)} placeholder="Chit serial No" />
                                <Button type="submit" icon="pi pi-search" label="Search" />

                            </div>


                        </Form>
                    )}
                </Formik>

                {dataTableVisible ?
                    <DataGridV3 tittle={""} data={auctionData} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} extraAction={undefined} />
                    :
                    <div style={{ height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none' }}>
                            <i className="pi pi-search" style={{ fontSize: '8em', color: "#11B3CF" }}></i>
                        </span>
                        <div className='raleway-font mt-3'>{t("Search a Chitty for auction report")}</div>

                    </div>
                }

            </div>


        </React.Fragment>
    );
};

export default PreviousAuctionsList;
