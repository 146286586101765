import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import {  Form, Formik } from "formik";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import {regionSvc } from '../../../../services';
import { toast } from "react-toastify";
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { CancelButton, SaveButton } from "../../../../components/styledcomponents";
import { regionSave } from "../../../../validation";
const RegionModal = ({ setVisible, visible = false, id, updateTableData }: ICustomerModal) => {
    const ini = {
        region_name: '',
        location: '',
        region_manager: '', 

    };
    const [custId, setCustId] = useState(id);
    const [initialValues, setInitialValues] = useState(ini);
    const [t, i18n] = useTranslation()

    useEffect(() => {
        setCustId(id);
        if (id && visible) {
            regionSvc.get(id).then(res => setInitialValues({ ...res.data}));
        }
    }, [id, visible]);

    const onSubmit = async (e: any, actions: any) => {
        if (custId) {
            await regionSvc.update(custId, e);
            toast.success("Updated");
            updateTableData();
            onClose();
        }
        else {
            await regionSvc.save(e);
            toast.success("Created");
            updateTableData();
            onClose();

        }
       
        actions.setSubmitting(false);
    };
   
    const onClose = () => {
        setInitialValues(ini);setCustId(undefined); setVisible(false); 
    };
   
    return (
        <Dialog header={!id ? t("Create Region")as string : t("Update Region")as string} visible={visible} maximizable style={{ width: '50vw' }} onHide={onClose}>
            <Formik enableReinitialize initialValues={initialValues} validationSchema={regionSave}  onSubmit={onSubmit}>
                {
                    ({ isSubmitting, errors, getFieldProps, touched, setFieldValue, values, isValid }) => (
                        <Form>
                             <div className="grid p-fluid mt-3">
                                <div className="field col-12 md:col-4">
                                    <span className="p-float-label">
                                        <InputText type="text" id="region_name" {...getFieldProps(('region_name'))} name='region_name' />
                                        <label htmlFor=" region_name"  >Region Name</label>
                                    </span>
                                    {touched.region_name && errors.region_name && <ErrorMessage message={errors.region_name} id="region_name" />}
                                    
                                </div>

                                <div className="field col-12 md:col-4">
                                    <span className="p-float-label">
                                        <InputText id="location" {...getFieldProps(('location'))} name='location' />
                                        <label htmlFor="location">Location</label>
                                    </span>
                                    {touched.location && errors.location && <ErrorMessage message={errors.location} id="location" />}
                                </div>
                                <div className="field col-12 md:col-4">
                                    <span className="p-float-label ">
                                        <InputText id="region_manager" {...getFieldProps(('region_manager'))} name='region_manager' />
                                        <label htmlFor="region_manager">Region Manager Name</label>
                                    </span>
                                    {touched.region_manager && errors.region_manager && <ErrorMessage message={errors.region_manager} id="region_manager" />}
                                </div>
                            </div>
                            
                            <div className="flex flex-wrap justify-content-end gap-3 mt-3">
                                <CancelButton label={t("Cancel") + ''} onClose={onClose}/>
                                <SaveButton label={id ? t("Update") as string : t("Save") as string} isSubmitting={isSubmitting} />

                            </div>
                        </Form>
                    )
                }
            </Formik>
        </Dialog>
    );
};

export default RegionModal;



interface ICustomerModal {
    visible: boolean;
    setVisible(val: boolean): void;
    id: string | undefined;
    updateTableData: any;
}