import { axiosInstance } from '../interceptors';

export const list = async (page: Number, limit: Number) => {
    return await axiosInstance.get(`admin/role/list?page=${page}&limit=${limit}`);
};
export const get = async (id: string) => {
    return await axiosInstance.get(`admin/role/${id}`);
};
export const save = async (req: any) => {
    return await axiosInstance.post(`admin/role`, req);
};

export const update = async (name: string, id: string) => {
    return await axiosInstance.put(`admin/role/${id}`, { name });
};
export const getAvailablePermiisions = async () => {
    return await axiosInstance.get(`admin/getAvailablePermission`);
};
export const getRolePermiisions = async (id:string) => {
    return await axiosInstance.get(`admin/getrolePermission?id=${id}`);
};
export const updateRolePermiisions = async (req:any) => {
    return await axiosInstance.post(`admin/role/updatePermission`,req);
};
export const Delete = async (id:string) => {
    return await axiosInstance.delete(`admin/role/${id}`);
};
export const multipleDelete = async (idList:any) => {
    return await  axiosInstance.post(`admin/deleteByIdList`,idList);
  }