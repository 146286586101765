import * as Yup from 'yup';

export const LoanSave = Yup.object().shape({
    customer_id: Yup.string().required('Required'),
    disburse_type: Yup.string().required("Required"),
    borrow_type: Yup.string().required('Required'),
    requested_amt: Yup.number().required('Required').positive("Required").typeError('Required'),

    region_id: Yup.string().when('agent_id', {
        is: (agentId:any) => !!agentId,
        then: Yup.string().nullable().required('Region is required when an agent is selected'),
        otherwise: Yup.string().nullable(),
      }),
      agent_id: Yup.string().nullable(),

});