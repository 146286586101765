import * as Yup from 'yup';

export const autionSave = Yup.object().shape({
    chit_id: Yup.string().required('Chit Sl No is required'),
    auctioned_date: Yup.date().required('Auction Date is required'),
    customer_id: Yup.string().required('Customer is required'),
    auction_amnt: Yup.number()
        .typeError('Auction Amount must be a number')
        .required('Auction Amount is required')
        .positive('Auction Amount must be a positive number')
});