import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import {  Form, Formik } from "formik";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import { agencySvc, regionSvc} from '../../../../services';
import { toast } from "react-toastify";
import useDebounce from "../../../../helpers/debounce";
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { CancelButton, SaveButton } from "../../../../components/styledcomponents";
import { agencySave } from "../../../../validation";
import { AutoComplete } from "primereact/autocomplete";
import { InputNumber } from "primereact/inputnumber";
const AgencyModal = ({ setVisible, visible = false, id, updateTableData }: IAgencyModal) => {
    const ini = {
        agent_name: '',
        location: '',
        commission_rate: 0,
        region_id:""

    };
    const [agencyId, setAgencyId] = useState(id);
    const [initialValues, setInitialValues] = useState(ini);
    const [region, setRegion] = useState([]);
    const [t, i18n] = useTranslation()
    const [regionSelected, setRegionSelected] = useState(null);
    const [regionSearch, setRegionSearch] = useState<string>('');
    const debouncedValue = useDebounce<string>(regionSearch, 500);
    useEffect(() => {
        if (debouncedValue && debouncedValue.length > 0){
        regionSvc.getAutoSuggest(regionSearch).then(res => {
                setRegion(res.data);
            });}
            else {
                setRegionSelected(null)
            }
    }, [debouncedValue]);

    useEffect(() => {
        if (id&&visible) {
            agencySvc.get(id).then(res => {
                console.log(res.data.data.region_id)
                regionSvc.getAutoSuggestById(res.data.data.region_id).then(res => {
                    setRegionSelected(res.data)
                    console.log(res.data)
                })
                setInitialValues({ ...res.data.data });

            });
        }

    }, [id,visible]);

    const onSubmit = async (e: any, actions: any) => {
        
        if (id) {
            await agencySvc.update(id, e);
            toast.success("Updated");
            updateTableData();
            setRegionSelected(null);
            setRegionSearch('');
            onClose();
        }
        else {
            await agencySvc.save(e);
            toast.success("Created");
            updateTableData();
            onClose();

        }
       
        actions.setSubmitting(false);
    };
    
    const onClose = () => {
        setInitialValues(ini);
        setAgencyId(undefined);
        setRegionSelected(null);
        setRegionSearch(''); 
        setVisible(false); 
    };
   
    return (
        <Dialog header={!id ? t("Create Agent")as string : t("Update Agent")as string} visible={visible} maximizable style={{ width: '50vw' }} onHide={onClose}>
            <Formik enableReinitialize initialValues={initialValues} validationSchema={agencySave} onSubmit={onSubmit}>
                {
                    ({ isSubmitting, errors, getFieldProps, touched, setFieldValue, values, isValid }) => (
                        <Form>
                             <div className="grid p-fluid mt-3">
                                <div className="field col-12 md:col-6">
                                <label htmlFor=" agency_name"  >Agent Name</label>
                                        <InputText type="text" id="agent_name" {...getFieldProps(('agent_name'))} name='agent_name' />                      
                                    {touched.agent_name && errors.agent_name && <ErrorMessage message={errors.agent_name} id="agent_name" />}
                                    
                                </div>  

                                <div className="field col-12 md:col-6">
                                    
                                    <label htmlFor="commission_rate">Commission Rate(%)</label>
                                    <InputNumber id="commission_rate" onChange={(e) => setFieldValue('commission_rate', e.value)} value={values.commission_rate} name='commission_rate' minFractionDigits={2} maxFractionDigits={2} />                             
                                    {touched.commission_rate && errors.commission_rate && <ErrorMessage message={errors.commission_rate} id="rate" />}
                                </div>
                                               
                                <div className="field col-12 md:col-6">
                                <label htmlFor="location">Location </label>
                                        <InputText id="location" {...getFieldProps(('location'))} name='location' />
                                    {touched.location && errors.location && <ErrorMessage message={errors.location} id="location" />}
                                </div>

                                
                                <div className="field col-12 md:col-6">
                                <label htmlFor="region_id">{t("Region Name")}</label>
                                    <AutoComplete field="name" value={regionSelected} suggestions={region} completeMethod={(e) => { setRegionSearch(e.query); }} onChange={(e) => { setFieldValue("region_id", e.value.value); setRegionSelected(e.value); }}  />
                                    
                                    {touched.region_id && errors.region_id &&  <ErrorMessage message={errors.region_id} id="region_id" />}
                                </div>
                            </div>         
                            <div className="flex flex-wrap justify-content-end gap-3 mt-3">
                                <CancelButton label={t("Cancel") + ''} onClose={onClose}/>
                                <SaveButton label={id ? t("Update") as string : t("Save") as string} isSubmitting={isSubmitting} />

                            </div>
                        </Form>
                    )
                }
            </Formik>
        </Dialog>
    );
};

export default AgencyModal;



interface IAgencyModal {
    visible: boolean;
    setVisible(val: boolean): void;
    id: string | undefined;
    updateTableData: any;
}