// import {createStore,combineReducers,applyMiddleware} from "redux"
// import createSagaMiddleware from "redux-saga"
// import permissionReducer from "./reducers/reducer"
// import {watchFetchPermissionList} from "./sidebarSaga"

// const rootReducer=combineReducers({
//     sidebar:permissionReducer
// })
// const sagaMiddleware = createSagaMiddleware();
// const store = createStore(rootReducer,applyMiddleware(sagaMiddleware));
// sagaMiddleware.run(watchFetchPermissionList);

// export default store;

import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import permissionsReducer from './reducers/reducer';
import rootSaga from './saga/permissionSaga';

const sagaMiddleware = createSagaMiddleware();

const initialState = {
  permissions: null,
};
const rootReducer = combineReducers({
  permissions: permissionsReducer,
});

const store = createStore(
  rootReducer,
//   initialState,
  applyMiddleware(sagaMiddleware),
);

sagaMiddleware.run(rootSaga);

export default store;
