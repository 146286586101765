import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import { Calendar } from 'primereact/calendar';
import { repaymentSvc } from '../../../../services';
import { CancelButton, SaveButton } from "../../../../components/styledcomponents";
import { Form, Formik } from "formik";
import { loanSettings } from "../../../../validation";


const RepaymentModal = ({ setVisible, visible = false, viewOnly = true, id, setReload }: IRepaymentModal) => {
    const ini = {
        borrow_type: 'weekly',
        day: '',
        maximum_buzzer: '',
    };
    const [initialValues, setInitialValues] = useState<any>(ini);

    const borrowTypeOptions = [
        { label: "Weekly", value: "weekly" },
        { label: "Monthly", value: "monthly" },
    ];

    const weekdaysOptions = [
        { label: "Monday", value: 1 },
        { label: "Tuesday", value: 2 },
        { label: "Wednesday", value: 3 },
        { label: "Thursday", value: 4 },
        { label: "Friday", value: 5 },
        { label: "Saturday", value: 6 },
        { label: "Sunday", value: 7 },
    ];

    useEffect(() => {
        if (id && visible) {
            repaymentSvc.repaymentSettingsGet(id).then(res => setInitialValues({ ...res.data }));
        }
    }, [id, visible]);

    const onSubmit = async (e: any, actions: any) => {
        if (id) {
            await repaymentSvc.repaymentSettingsUpdate(id, e);
            toast.success("Updated");
            setReload(true)
            setVisible(false)
        }
        else {
            await repaymentSvc.repaymentSettingsSave(e);
            toast.success("Created");
            setReload(true)
            setVisible(false)

        }
        actions.setSubmitting(false);
    };

    const DialogFooter = (
        <div className="flex flex-wrap justify-content-end gap-3">
            <CancelButton label={"Cancel"} onClose={() => { setVisible(false) }} />
            <SaveButton label={id ? 'Update' : 'Save'} icon="pi pi-check" />
        </div>);
    return (
        <Dialog header="Repayment Settings" visible={visible} maximizable onHide={() => setVisible(false)}>
            <Formik enableReinitialize initialValues={initialValues} validationSchema={loanSettings} onSubmit={onSubmit} >
                {
                    ({ isSubmitting, errors, getFieldProps, touched, setFieldValue, values, isValid }) => (

                        <Form>
                            <div className="grid p-fluid mt-3">
                                <div className="field col-12 md:col-6">
                                    <label htmlFor="borrow_type">Borrow Type</label>
                                    <Dropdown options={borrowTypeOptions} {...getFieldProps(('borrow_type'))} value={values.borrow_type}
                                        onChange={(e) => setFieldValue('borrow_type', e.value)} />
                                    {touched.borrow_type && errors.borrow_type && <ErrorMessage message={errors.borrow_type} id="borrow_type" />}
                                </div>
                                {values.borrow_type === "weekly" && (
                                    <div className="field col-12 md:col-6">
                                        <label htmlFor="day">Day</label>

                                        <Dropdown
                                            id="day" value={values.day} options={weekdaysOptions}
                                            onChange={(e) => setFieldValue('day', e.value)} className="weekly-day-input" />
                                        {touched.day && errors.day && <ErrorMessage message={errors.day} id="maximum_buzzer" />}

                                    </div>
                                )}
                                {values.borrow_type === "monthly" && (
                                    <div className="field col-12 md:col-6">
                                        <label htmlFor="day">Day</label>
                                        <Dropdown
                                            id="day"
                                            value={values.day} // Assuming `day` is part of your Formik state
                                            onChange={(e) => setFieldValue('day', e.value)}
                                            options={Array.from({ length: 31 }, (_, index) => ({ label: (index + 1).toString(), value: index + 1 }))}
                                            placeholder="Select a day"
                                        />
                                        {touched.day && errors.day && <ErrorMessage message={errors.day} id="maximum_buzzer" />}

                                    </div>
                                )}

                                <div className="field col-12 md:col-5">
                                    <label htmlFor="maximum_buzzer">Maximum Buzzer</label>
                                    <InputNumber id="maximum_buzzer" value={values.maximum_buzzer} onChange={(e: any) => setFieldValue('maximum_buzzer', e.value)} name='maximum_buzzer' />
                                    {touched.maximum_buzzer && errors.maximum_buzzer && <ErrorMessage message={errors.maximum_buzzer} id="maximum_buzzer" />}
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-content-end gap-3 mt-3">
                                {DialogFooter}
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </Dialog>
    );
};

export default RepaymentModal;

interface IRepaymentModal {
    visible: boolean;
    setVisible(val: boolean): void;
    viewOnly: boolean,
    id: string | null;
    setReload(val: boolean): void
}
