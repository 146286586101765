import React, { useRef, useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Paginator, PaginatorTemplateOptions } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { loanSvc } from '../../../services';
import RePaymentDetails from '../repayment/repamentDetails';
import LoanPreClosureModal from './loanPreClosureModal';
import '../../../components/datagrid/datagrid.css'
import ActionButton from '../../../components/common/buttons/actionButton';
import SearchBar from '../../../components/common/searchBar/SearchBar';
import { Heading } from '../../../components/styledcomponents';

const LoanPreClosure = () => {
    const [page, setPage] = useState(0);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selected, setSelected] = useState([]);
    const [lonDataList, setLoanDataList] = useState([]);
    const [viewDetails, setViewDeatails] = useState<boolean>(false);
    const [totalRecords, setTotal] = useState(0);
    const [searchQuery, setSearchQuery] = useState(''); //For serch bar
    const [visible, setVisible] = useState(false);
    const [id, setId] = useState<string>('');
    const dt = useRef<DataTable<any>>(null);
    const [t] = useTranslation()


    const getList = () => {
        loanSvc.getApprovedLoans((page + 1), limit).then((res: any) => {
            setLoanDataList(res.data.loans);
            setTotal(res.data.recordcount);
        });
    };



    const exportCSV = () => {
        if (dt.current)
            dt.current.exportCSV();
    };




    const actionBodyTemplate = (rowData: any) => {
        return (
            <>
                <ActionButton label={t("Payment")} icon="pi pi-fw pi-money-bill " className="p-button-secondary " onClick={() => preClosureModal(true, rowData._id)} /> {" "}
                <ActionButton label={t("Repayment Details")} icon="pi pi-info-circle" className="p-button-secondary" onClick={() => repaymentDetails(true, rowData._id)} /> {" "}

            </>
        );
    };

    // Implement Search bar 
    const handleSearch = () => {
        loanSvc.serchApprovedLoans(searchQuery, (page + 1), limit).then(res => {
            if (res) {
                setLoanDataList(res.data.loans);
                setTotal(res.data.recordcount);
            }
        });

    };

    const handleInputChange = (e: any) => {
        const value = e.target.value;
        setSearchQuery(value);
        if (value === '') {
            getList()
        }
    };
    const LoanPreclosureSearch = () => {

        return (
            <React.Fragment>
                <SearchBar
                    searchQuery={searchQuery}
                    handleInputChange={handleInputChange}
                    handleSearch={handleSearch}
                />
            </React.Fragment>
        );
    };


    const paginatorTemp: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1 pagination-no">
                        {t('Items per page') + ': '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: { first: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; last: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; totalRecords: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }) => {
            return (
                <span className="pagination-no">
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };
    const onPageChange = (event: { first: React.SetStateAction<number>; rows: React.SetStateAction<number>; page: React.SetStateAction<number>; }) => {
        setFirst(event.first);
        setLimit(event.rows);
        setPage(event.page);
    };
    const repaymentDetails = (edit: boolean, id?: string) => {
        if (id) {
            setId(id);
            setViewDeatails(true);
        }
    };

    const preClosureModal = (edit: boolean, id?: string) => {
        if (id) {
            setId(id);
            setVisible(true);
        }
    };

    useEffect(() => {
        getList();
    }, [page, limit]);
    useEffect(() => {
        if (!visible)
            setId('');
    }, [visible]);

    return (
        <React.Fragment>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div> 
                    <div><Heading className="m-0">{t("Loan Pre-Closure")}</Heading></div></div>
                    <div className="common-header-toolbar" >
                        <div>
                            <LoanPreclosureSearch />
                        </div>
                        <div>
                            <Button icon="pi pi-file-excel" tooltip='Export to Excel' tooltipOptions={{ position: 'left' }} style={{ backgroundColor: '#11B3CF' }} className="p-button-success p-button-sm" onClick={exportCSV} />
                        </div>
                    </div>
                    <DataTable
                        ref={dt}
                        value={lonDataList}
                        selection={selected}
                        onSelectionChange={(e: any) => setSelected(e.value)}
                        dataKey="_id"
                        className="datatable-responsive custom-datatable"
                        globalFilter={globalFilter}
                        scrollable
                        scrollHeight="400px"
                        emptyMessage="No data found."
                    >
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} header={t("Loan Id")} field='sl_no' ></Column>
                        {/* <Column style={{ flexGrow: 1, flexBasis: '200px' }} header={t("customer name")} body={rowData => `${rowData.customer_id.first_name} ${rowData.customer_id.middle_name} ${rowData.customer_id.last_name}`} filterField="customer_id.first_name" ></Column> */}
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} header={t("customer name")} body={rowData => `${rowData.customer_id?.first_name || ''} ${rowData.customer_id?.middle_name || ''} ${rowData.customer_id?.last_name || ''}`} filterField="customer_id.first_name" ></Column>

                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} header={t("Approved On")} field='approved_on' body={(rowData) => {
                            const date = new Date(rowData.approved_on);
                            return date.toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric'
                            });
                        }}></Column>
                        <Column header={t("action")} style={{ textAlign: 'center', flexGrow: 1, flexBasis: '200px' }} alignHeader={'center'} body={actionBodyTemplate} ></Column>
                    </DataTable>
                    <Paginator template={paginatorTemp} first={first} rows={limit} totalRecords={totalRecords} onPageChange={onPageChange} className="justify-content-end my-3"></Paginator>


                </div>
            </div>
            {viewDetails && <RePaymentDetails viewDetails={viewDetails} setViewDeatails={setViewDeatails} id={id} />}
            {visible && <LoanPreClosureModal visible={visible} setVisible={setVisible} id={id} updateTable={getList} />}

        </React.Fragment>
    );
};

export default LoanPreClosure;