import React, { useEffect, useState } from "react";
import './loanDetails.css'
import { Dialog } from 'primereact/dialog';
import { IProps } from "../../../interfaces";
import { customerSvc, loanSvc } from "../../../services";
import { Timeline } from 'primereact/timeline';
import moment from 'moment'
import { useTranslation } from "react-i18next";
import { Badge } from "primereact/badge";
import { Essential, Heading } from "../../../components/styledcomponents";
export default function LoanDetails({ setVisible, visible = false, viewOnly = true, id, setReload }: IProps) {
    const [customer, setCustomer] = useState<any>()
    const [loan, setLoan] = useState<any>({})
    const [t, i18n] = useTranslation()
    const [loanDetails, setLoanDetails] = useState<any>(null)
    const [data ,setData] = useState<any>(null)
    const [schedules, setSChedules] = useState<any>(null)
    const [status, setStatus] = useState<any>([])
    useEffect(() => {
        if (id) {
            loanSvc.getLoanDetails(id).then(res => {
                if (res.data) {
                    setLoanDetails(res.data.data)
                    setLoan(res.data.data.loan)
                    setData(res.data)
                    customerSvc.getAutoSuggestById(res.data.data.loan.customer_id).then(res => {
                        setCustomer(res.data)
                    })
                }
                else {
                    loanSvc.get(id).then(res => {
                        setLoan(res.data)
                        customerSvc.getAutoSuggestById(res.data.customer_id).then(res => {
                            setCustomer(res.data)
                        })
                    })
                }
            })

        }
    }, [id])


    useEffect(() => {
        if (loan._id) {
            loanSvc.getrepaymentSchedule(loan._id).then(res => {
                setSChedules(res.data.schedule)

                //Get status
                loanSvc.getRepayStatus(loan._id).then(res => {
                    setStatus(res.data)
                })

            })
        }
    }, [loan])

    return (
        <div className="card flex justify-content-center">
            <Dialog header={t("Loan Details")} visible={visible} style={{ width: '80vw' }} onHide={() => setVisible(false)} maximizable >
                <div id="invoice-content">
                    <div className="invoice-wrapper">
                        <div className="invoice-content">
                            <div className="invoice-footer grid grid-nogutter details-font">
                                <div className="col-12 md:col-6">
                                    <div className="invoice-table-2 grid grid-nogutter">
                                        <div className="col-6">
                                            <div className="invoice-table-col header-col">
                                                <span>{t("Loan No")}</span>
                                                <span>{t("Customer")}</span>
                                                <span>{t("Borrow Type")}</span>
                                                <span>{t("Disburse Type")}</span>
                                                <span>{t("Requested Amount")}</span>
                                                {data && data.agent_name &&<span>{t("Agent Name")}</span>}
                                                <span>{t("Status")}</span>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="invoice-table-col content-col">
                                                <span>{loan && loan.status ? loan.sl_no : 'N/A'}</span>
                                                <span>{customer ? customer.name : 'N/A'}</span>
                                                <span className="capitalize">{t(loan && loan.status ? loan.borrow_type : 'N/A')}</span>
                                                <span className="capitalize">{t(loan && loan.status ? loan.disburse_type : 'N/A')}</span>
                                                <span>{loan ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loan.requested_amt) : 'N/A'}</span>
                                                {data && data.agent_name &&<span className="capitalize">{data.agent_name}</span>}
                                                <span className="capitalize">{loan && loan.status ? loan.status : 'N/A'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="invoice-table-2 summary grid grid-nogutter">
                                        <div className="col-6">
                                            <div className="invoice-table-col header-col">
                                                <span>{t("Approved On")}</span>
                                                <span>{t("Approved Amount")}</span>
                                                <span>{t("Total Installments")}</span>
                                                <span>{t("Principal Amount")}</span>
                                                {loan.amount_After_deduction ? <span>{t("Amount After Deduction")}</span> : ""}
                                                {loan.status && loan.status !== "closed" && <span>{t("EMI Amount")}</span>}
                                                {loan.status && loan.status !== "closed" && <span>{t("Next EMI")}</span>}
                                                
                                                {loan.status && loan.status !== "closed" ? <><hr /><span className="total">{t("Balance To Pay")}</span></>
                                                 : <><hr /><span className="total">{t("Revenue")}</span></>}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="invoice-table-col content-col">
                                                <span>{loan ? moment(loan.approved_on).format('ll') : 'N/A'}</span>
                                                <span>{loan ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loan.approved_amt) : 'N/A'}</span>
                                                <span>{loanDetails ?loanDetails.installments : 'N/A'}</span>
                                                <span>{loanDetails ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loanDetails.principal_amt) : 'N/A'}</span>
                                                {loan.amount_After_deduction ? <span >{loan ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loan.amount_After_deduction) : 'N/A'}</span> : ""}
                                                {loan.status && loan.status !== "closed" && <span>{loanDetails ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loanDetails.next_emi) : 'N/A'}</span>}
                                                {loan.status && loan.status !== "closed" && <span>{loanDetails ? moment(loanDetails.next_emi_on).format('ll') : 'N/A'}</span>}
                                               
                                                {loan.status && loan.status !== "closed" ? <><hr /> <span className="total">{loanDetails ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loanDetails.principal_amt - loanDetails.repayed_amt) : 'N/A'}</span></>
                                                : <><hr /> <span className="total">{loanDetails ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data?.amnt - loan?.approved_amt) : 'N/A'}</span></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {loan.status && loan.status === "approved" ? */}
                                <div className="invoice-footer grid grid-nogutter">
                                    <Heading>{t("Payment Schedule")}</Heading>
                                    <div className="col-12 md:col-12 my-5 " style={{ display: 'flex', overflowX: 'auto', scrollbarWidth: 'thin', scrollbarColor: 'grey lightgrey' }}>
                                        <Timeline
                                            value={schedules}
                                            marker={() => <i className="pi pi-clock text-color-warning" ></i>}
                                            opposite={(item) => (
                                                <div>
                                                    <b className="text-color-secondary transform-rotate--90 mr-8 details-font">
                                                        {moment(item.due_date).format("DD/MM/YYYY")}
                                                    </b>
                                                    <div>
                                                        {status && status.find((status: any) => status.id === item._id) ? (
                                                            <Badge
                                                                value={status.find((s: any) => s.id === item._id)?.status || ""}
                                                                severity={status.find((s: any) => s.id === item._id)?.status === "paid" ? "success" :
                                                                status.find((s: any) => s.id === item._id)?.status === "over paid" ? "success" : "info"} className="capitalize"
                                                                style={{
                                                                    backgroundColor: status.find((s: any) => s.id === item._id)?.status === "over paid" ? " #006400":""
                                                                }}
                                                            />
                                                        ) : (
                                                            <Badge value="Pending" severity="warning" />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            layout="horizontal"
                                            align="top"
                                            content={(item) => (
                                                <div>
                                                    <div className="details-font">
                                                        {status.find((s: any) => s.id === item._id)?.status === "partially paid" ? <div><Essential>-{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item.emi- (status.find((s: any) => s.id === item._id)?.amnt || 0))}</Essential></div> 
                                                        : <div> {new Intl.NumberFormat('en-IN', {style: 'currency',currency: 'INR'}).format(item.emi - (status.find((s:any) => s.id === item._id)?.amnt || 0)).replace(/^-/, '+')}</div>}
                                                    </div>
                                                </div>
                                            )}
                                        />


                                    </div>
                                </div> 
                                {/* : ""} */}

                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}


