import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import  {roleSvc} from '../../../../services';


const RoleModal = ({ setVisible, visible = false, viewOnly = true, id,updateTableData }: IRoleModal) => {
    const [role, setRoles] = useState({});
    const [roleId, setRoleId] = useState(id);
    const [name, setName] = useState("");
    let error: any = null;
    useEffect(() => {
        if (id) {
            roleSvc.get(id).then(res => {
                setRoles(res.data);
                setName(res.data.name);
            });
        }
    }, [id]);
    const onSubmit = async () => {
        if (roleId) {
            const res = await roleSvc.update(name, roleId);
            if (res) toast.success("Updated");
            setVisible(false)
            updateTableData()
            return;
        }
        const res = await roleSvc.save({name});
            if (res) toast.success("Created");
            updateTableData()
            setVisible(false)
            return;

    };
    const DialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-button-raised" onClick={() => {setVisible(false) }} />
            <Button label={id ? 'Update' : 'Save'} icon="pi pi-check" className="p-button-primary p-button-raised" style={{ background: '#11B3CF',border:'none' }} onClick={onSubmit} />
            
        </>);
    return (
        <Dialog header={id ? 'Update Role' : 'Add Role'} footer={DialogFooter} visible={visible} maximizable style={{ width: '50vw' }} onHide={() => setVisible(false)}>
            <div className="flex flex-column gap-2" style={{ maxWidth: 400 }}>
                <label htmlFor="role">Role Name</label>
                <InputText id="role" value={name} aria-describedby="role-help" onChange={(e) => setName(e.target.value)} className={`${error ? 'p-invalid' : ''}`} />
                {error && <ErrorMessage id={"role-help"} message="role required" />}
            </div>
        </Dialog>
    );
};

export default RoleModal;



interface IRoleModal {
    visible: boolean;
    setVisible(val: boolean): void;
    viewOnly: boolean,
    id: string | null;
    updateTableData:any;
}