import { axiosInstance } from '../interceptors';

export const save = async (request: any) => {
    return await axiosInstance.post(`/master/addRegion/`, request);
  };

export const list = async (limit: string,page: string) => {
    return await axiosInstance.get(`/master/listRegions?page=${page}&limit=${limit}`);
};

export const commissionReportList = async (page: number,limit: number) => {
  return await axiosInstance.get(`/master/listRegions/report?page=${page}&limit=${limit}`);
};

export const get = async (id:string) => {
    return await axiosInstance.get(`/master/region/getById/${id}`);
  };
  
  export const update = async (id: string, request: any) => {
    return await axiosInstance.put(`master/updateRegion?regionId=${id}`,request)
  };

  export const searchRegion = async (name:any) => {
    return await axiosInstance.get(`/master/region/search/${name}`);
  };
  export const getAutoSuggest = async (name:any) => {
    return await axiosInstance.post(`/master/region/getAutoSuggestByName/`,{name});
  };

  export const getAutoSuggestById = async (regionId:string) => {
    return await axiosInstance.get(`/master/region/getAutoSuggestById?regionId=${regionId}`);
  };
  export const statusUpdate = async (id: string, request: any) => {
    return await axiosInstance.put(`/master/region/setStatusById/${id}`,{status:request})
  };
  
  export const Delete = async (id:string) => {
    return await  axiosInstance.delete(`/master/deleteRegion/${id}`);
  }
  export const multipleDelete = async (idList:any) => {
    return await  axiosInstance.post(`/master/region/multipleDelete/`,idList);
  }