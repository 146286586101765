import React, { useRef, useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Paginator, PaginatorTemplateOptions } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { irSvc } from '../../../../services';
import InterestRateModal from './InterestRateModal';
import '../master.css'
import ActionButton from '../../../../components/common/buttons/actionButton';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import { Heading } from '../../../../components/styledcomponents';

const InterestRateList = () => {
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
    const [selected, setSelected] = useState([]);
    const [interestRate, setInterestRate] = useState([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(true);
    const [id, setId] = useState<string>('');
    const dt = useRef<DataTable<any>>(null);
    const [t, i18n] = useTranslation()
    const [reload, setReload] = useState<boolean>(true)

    const exportCSV = () => {
        if (dt.current)
            dt.current.exportCSV();
    };

    const actionBodyTemplate = (rowData: any) => {
        return (
            <>
                <ActionButton icon="pi pi-pencil" label={t('Edit')} className="p-button-secondary" onClick={() => onEditOrView(true, rowData._id)} />
            </>
        );
    };

    const deleteDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { }} />
        </>
    );


    const paginatorTemp: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                        Items per page:{' '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: { first: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; last: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; totalRecords: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };
    const onEditOrView = (edit: boolean, id?: string) => {
        setEdit(edit);
        if (id)
            setId(id);
        setVisible(true);

    };

    useEffect(() => {
        if (!visible)
            setId('');
    }, [visible]);

    useEffect(() => {
        if (reload) {
            irSvc.getAll().then((res: any) => {
                setInterestRate(res.data);
                setReload(false)

            }).catch(() => { setReload(false) });
        }
    }, [reload])
    return (
        <React.Fragment>
            <div className="grid">
                <div className="col-12">
                    <div><Heading className="m-0">{t('Interest Rate')}</Heading></div>
                    <div className='master-toolbar' >
                        <div>
                            <div className="search-bar mt-1">
                                <InputGroup>
                                    <span className="p-input-icon-left">
                                        <i className="pi pi-search" />
                                        <InputText
                                            onInput={(e: any) => setGlobalFilter(e.target.value)}
                                            placeholder={t("Search...") as string}
                                            style={{ width: '300px', borderRadius: '0px', height: '2.5rem' }}
                                        />
                                    </span>
                                    <Button label={t("Search") + ''} style={{ backgroundColor: '#11B3CF', height: '2.5rem' }} className='p-button-success' />
                                </InputGroup>
                            </div>
                        </div>
                        <div>
                            <Button tooltip='Reload' tooltipOptions={{ position: "mouse" }} label="" icon={`pi pi-refresh ${reload ? 'pi-spin' : ''}`} style={{ backgroundColor: '#11B3CF',width: '35px', height: '35px' }} className="p-button-success p-button-rounded p-button-raised p-button-secondary mr-2" onClick={() => { setReload(true); }} />
                            {interestRate && interestRate.length > 0 && <Button tooltip='Export to excel' style={{ backgroundColor: '#11B3CF',width: '35px', height: '35px' }} tooltipOptions={{ position: "bottom" }} icon="pi pi-file-excel" className="p-button-success p-button-rounded p-button-raised p-button-secondary mr-2" onClick={exportCSV} />}
                            <Button tooltip='Create' tooltipOptions={{ position: "mouse" }} label={t("Add New") as string} style={{ backgroundColor: '#11B3CF' }} icon="pi pi-plus" className="p-button-success p-button-raised p-button-sm mr-2" onClick={() => { onEditOrView(false); }} />

                        </div>
                    </div>
                    <DataTable
                        ref={dt}
                        value={interestRate}
                        selection={selected}
                        onSelectionChange={(e: any) => setSelected(e.value)}
                        dataKey="_id"
                        className="datatable-responsive master-datatable"
                        globalFilter={globalFilter}
                        emptyMessage="No data found."
                    >
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} selectionMode="multiple" headerStyle={{ width: '4rem' }}></Column>
                        <Column className="capitalize" style={{ flexGrow: 1, flexBasis: '200px' }} header={t("Borrow Type")} field='borrow_Type' ></Column>
                        <Column className="capitalize" style={{ flexGrow: 1, flexBasis: '200px' }} header={t("Disburse Type")} field='disburse_Type' ></Column>
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} header={t("Emi Interest (%)")} field='emi_Interest' ></Column>
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} header={t("Rate (%)")} field='rate' ></Column>
                        <Column header={t("action")} style={{ textAlign: 'center', flexGrow: 1, flexBasis: '200px' }} alignHeader={'center'} body={actionBodyTemplate} ></Column>
                    </DataTable>

                    <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Are you sure you want to delete the selected products?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
            {visible && <InterestRateModal visible={visible} setVisible={setVisible} viewOnly={edit} id={id} setReload={setReload} />}
        </React.Fragment>
    );
};

export default InterestRateList;