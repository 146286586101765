import React, { useState, useEffect } from "react";
import { loanSvc } from '../../../services';
import { useTranslation } from "react-i18next";
import DataGridV3 from "../../../components/datagrid/DataGridV3";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import moment from "moment";
import './paymentList.css'

const DueList: React.FC = () => {
  const [dueList, setDueList] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');
  const [dates, setDates] = useState<any>([null, null]);
  const [t] = useTranslation()
  const columns = [
    { field: 'sl_no', header: 'Loan Id' },
    { field: 'customer_name', header: 'customer name' },
    { field: 'amount', header: 'Amount To Pay', type: 'amount' },
    { field: 'due_date', header: 'Due Date', type: 'date' },
    {
      field: 'status', header: 'Status', type: 'status', status: [
        { key: 'paid', value: 'success' },
        { key: 'pending', value: 'info' },
      ]
    }
  ];

  useEffect(() => {
    getDueList();
  }, [page, limit]);

  const getDueList = () => {
    loanSvc.getDuesList(String(limit), String(page)).then((res: any) => {
      setDueList(res.data.result);
      settotalRecords(res.data.recordcount);
    });
  };

  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };



  const handleSearch = () => {
   
      const [from, to] = dates;

      if (from === null && to === null) {
        getDueList();
        return;
      }
      if (from ||to) {
        const desiredFormat = "YYYY-MM-DD HH:mm:ss";
        const convertedFromFormat = moment(from).format(desiredFormat);
        const convertedToFormat = moment(to).format(desiredFormat);
      loanSvc.searchDuePayments(convertedFromFormat,convertedToFormat, String(page),String(limit)).then((res: any) => {
        setDueList(res.data.result);
        settotalRecords(res.data.recordcount);
      });
    }
  };

  const duePaymentSearch = () => {
  
    return (
      <React.Fragment>
      <span className="duelist-header-right">{t("From")}</span>
      <Calendar value={dates[0]} onChange={(e) => setDates([e.target.value, dates[1]])} className="mr-2 duelist-header-right-calender" showButtonBar/>
      <span className="duelist-header-right">{t("To")}</span>
      <Calendar value={dates[1]} onChange={(e) => setDates([dates[0], e.target.value])} className="mr-2 duelist-header-right-calender"  showButtonBar />
      <Button label={t("Search") as string} icon="pi pi-search" className="p-button-success duelist-header-rightsearch" style={{ backgroundColor: "#11B3CF",height:'2.2rem' }} onClick={handleSearch} />
    </React.Fragment>
    );
  };
  return (
    <>
      
      <DataGridV3 tittle={t("Pending List")} data={dueList} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} extraAction={undefined} SearchBar={duePaymentSearch} />
    </>
  );
};
export default DueList
