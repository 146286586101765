import { axiosInstance } from './interceptors';

export const list = async () => {
    return await axiosInstance.get(`/loan/list`)
};

export const save = async (loan_id:string,emi:Number,date:any,isOveride:boolean) => {
    return await axiosInstance.post(`loan/repayment?isOveride=${isOveride}`,{loan_id,emi,date});
};

export const getLoanDetails = async (loan_id: string) => {
    return await axiosInstance.get(`loan/loandetails/${loan_id}`);
};

export const getByLoanId = async (serialno:string) => {
    return await axiosInstance.get(`/loan/getdetailsBySerialno/${serialno}` );
};

export const customerList = async () => {
    return await axiosInstance.get(`/customer/list`);
  };

  export const get = async (id: any) => {
    return await axiosInstance.get(`/loan/getloandetailsbyid/${id}`);
};

export const historyList = async (limit: string, page: string,id:string) => {
    return await axiosInstance.get(`/loan/getpaymenthistory/${id}?page=${page}&limit=${limit}`)
};

export const paidList = async (limit: string, page: string) => {
    return await axiosInstance.get(`/loan/getRepaymentDetails?page=${page}&limit=${limit}`)
};

export const repaymentSettingsSave = async (request:any) => {
    return await axiosInstance.post(`/master/loanSetting`,request);
};

export const repaymentSettingsList = async () => {
    return await axiosInstance.get(`/master/loanSetting/list`)
};

export const repaymentSettingsGet = async (id: string) => {
    return await axiosInstance.get(`/master/loanSetting/get/${id}`)
};
export const repaymentSettingsUpdate = async (id: string,request:any) => {
    return await axiosInstance.put(`/master/loanSetting/update/${id}`,request)
};

export const getPendingPayment = async (id: string) => {
    return await axiosInstance.get(`/loan/getPendingPaymentByRepaymentId?repayment_id=${id}`)
};

export const payPendingAmnt = async (id:any,loan_id:string,emi:Number,date:any) => {
    return await axiosInstance.put(`/loan/payPendingAmnt?repayment_id=${id}`,{loan_id,emi,date});
};