import React, { useState, useEffect, useRef, useContext } from "react";
import { chitfundSvc, loanSvc } from '../../../../services';
import LoanModal from "./chitFundModal";
import { toast } from "react-toastify";
import { Dialog } from 'primereact/dialog';
import DataGridV2 from "../../../../components/datagrid/DataGridV2";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SearchBar from "../../../../components/common/searchBar/SearchBar";
import ActionButton from "../../../../components/common/buttons/actionButton";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import ToastMessage from "../../../../components/common/toastMessage/toastMessage";
import { Dropdown } from "primereact/dropdown";
import ChitFundModal from "./chitFundModal";
import ChitDetails from "./chitDetails";
import { AuthContext } from "../../../../context";





const ChitFundList: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const [t] = useTranslation()
  const [totalRecords, settotalRecords] = useState(0);
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');
  const [visible, setVisble] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [reload, setRelaod] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [approveDate, setApproveDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [approveDialogVisible, setApproveDialogVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); //For serch bar
  const [viewDetails, setviewDetails] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<any>({ name: 'Approved', code: 'approved' });
  const columns = [
    { field: 'sl_no', header: 'Sl No' },
    { field: 'chit_amount', header: 'Chit Amount', type: 'amount' },
    { field: 'installment_amount', header: "Installment Amount", type: 'amount' },
    { field: 'total_customers', header: 'No of customers' },
    // { field: 'approved_amt', header: 'No of customers'},
    // { field: 'requested_on', header: 'Req On', type: 'date' },
    {
      field: 'status', header: 'Status', type: 'status', status: [
        { key: 'approved', value: 'success' },
        { key: 'closed', value: 'info' },
        { key: 'rejected', value: 'danger' },
      ]
    },


  ];

  const { userId } = useContext(AuthContext);

  useEffect(() => {
    chitFilterData(selectedStatus) //Loan list based on selected category
  }, [limit, page]);
  useEffect(() => {
    if (reload) {

      chitFilterData(selectedStatus)
    }
    setRelaod(false);
  }, [reload]);
  useEffect(() => {
    if (!visible || !viewDetails)
      setId('');
  }, [visible, viewDetails]);
  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };

  const onEditOrView = (edit: boolean, id: string) => {
    setEdit(edit);
    // if (id)
    setId(id);
    setVisble(true);
  };
  const onApprove = () => {
    if (id && approveDate &&startDate) {

      const desiredFormat = "YYYY-MM-DD HH:mm:ss";
      const ApproveDateFormat = moment(approveDate).format(desiredFormat);
      const StartDateFormat = moment(startDate).format(desiredFormat);

      const req = {
        chit_id: id,
        approved_on: ApproveDateFormat,
        start_date: StartDateFormat
      }
      chitfundSvc.approve(req).then(res => {
        setRelaod(true);
      });
      onHide()
    }

  };

  const onReject = (id: string) => {
    chitfundSvc.rejected(id).then(res => {
      setRelaod(true);
    });
  };
  // Close approve date calender
  const onHide = () => {
    setApproveDialogVisible(false)
    setApproveDate(null)
  }
  const toast = useRef<any>(null);
  const deleteRecord = (i: any, userId: string) => {
    chitfundSvc.Delete(i, userId).then(res => {
      if (res) {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Chit deleted successfully', life: 2000 });
        setRelaod(true);
      } else {
        toast.current.show({ severity: 'Error', summary: 'Error', detail: 'Failed to delete chit', life: 2000 });
      }
    });
  }

  const deleteByIdList = (i: any) => {
    const idList = i.ids
    const payload = {
      idList,
      user_id: userId
    };
    chitfundSvc.multipleDelete(payload).then(res => {
      if (res) {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Chit deleted successfully', life: 2000 });
        setRelaod(true);
      } else {
        toast.current.show({ severity: 'Error', summary: 'Error', detail: 'Failed to delete Chits', life: 2000 });
      }
    });
  }

  const permissionList = useSelector((state: any) => state.permissions.permissions);

  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { deleteRecord(id, userId ?? " "); setDeleteDialog(false) }} />
    </>
  );

  // Implement Search bar 
  const handleSearch = () => {
    if (searchQuery) {
      chitfundSvc.searchChit(searchQuery).then(res => {
        if (res) {
          setTableData(res.data);
          // settotalRecords(res.data.recordcount);
        }
      });
    }

  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (value === '') {
      setRelaod(true);
    }
  };
  const LoanSearch = () => {

    return (
      <React.Fragment>
        <SearchBar
          searchQuery={searchQuery}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
        />
      </React.Fragment>
    );
  };

  const status = [
    { name: 'All', code: 'all' },
    { name: 'Approved', code: 'approved' },
    { name: 'Rejected', code: 'rejected' },
    { name: 'Created', code: 'created' },
    { name: 'Closed', code: 'closed' },
  ];
  const chitFilterData = (status: any) => {
    const { code } = status;
    chitfundSvc.searchByStatus(code, String(limit), String(page)).then(res => {
      setTableData(res.data.data);
      settotalRecords(res.data.totalRecords);
    });
  };

  const LoanListFilter = () => {

    return (
      <React.Fragment>
        <Dropdown value={selectedStatus} onChange={(e) => { setSelectedStatus(e.value); chitFilterData(e.value) }} options={status} optionLabel="name"
          placeholder={t("Select a category") as string} className="ml-2"/>
      </React.Fragment>
    );
  };
  const extraAction = ({ rowData }: any) => {

    const hasApprovePermission = permissionList && permissionList.includes('pages.loan.approve');
    const hasRejectPermission = permissionList && permissionList.includes('pages.loan.reject');
    const hasViewPermission = permissionList && permissionList.includes('pages.loan.get');
    const hasUpdatePermission = permissionList && permissionList.includes('pages.loan.update');
    const hasDeletePermission = permissionList && permissionList.includes('pages.loan.delete');
    return <>
      {hasApprovePermission && rowData.status === 'created' && <ActionButton label={t("Approve")} icon="pi pi-check" className="p-button-success" onClick={() => { setApproveDialogVisible(true); setId(rowData._id); }} />}
      {hasRejectPermission && rowData.status === 'created' && <ActionButton label={t("Reject")} icon="pi pi-times" className="p-button-danger" onClick={() => { onReject(rowData._id); }} />}
      {/* {hasApprovePermission && rowData.status === 'created' && <SplitButton label="Approve" icon="pi pi-check" onClick={() => { onApprove(rowData._id); setApproveDate(null); setId(rowData._id) }} model={items} className="p-button-success p-button-text mr-2 " />} */}
      {hasViewPermission && rowData.status !== 'created' && rowData.status !== 'rejected' && <ActionButton label={t("View")} icon="pi pi-eye" className="p-button-secondary" onClick={() => { setId(rowData._id); setviewDetails(true); }} />}
      {hasUpdatePermission && rowData.status === 'created' && <ActionButton label={t("Update")} icon="pi pi-pencil" className="p-button-secondary " onClick={() => { onEditOrView(true, rowData._id); }} />}
      {hasDeletePermission && rowData.status === 'created' && <ActionButton label={t("Delete")} icon="pi pi-trash" className="p-button-danger" onClick={() => { setDeleteDialog(true); setId(rowData._id); }} />}


    </>;
  };


  return (
    <>
      <DataGridV2
        tittle={t("Chits")} data={tableData} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} onCreateOrEdit={onEditOrView}
        reload={reload} setReload={setRelaod} deleteByIdList={deleteByIdList}
        extraAction={extraAction} SearchBar={LoanSearch} filter={LoanListFilter} addNew={true} />
      {visible && <ChitFundModal id={id} setVisible={setVisble} visible={visible} setReload={setRelaod} viewOnly={false} setSelectedStatus={setSelectedStatus} chitFilterData={chitFilterData} />}
      {viewDetails && <ChitDetails id={id} setVisible={setviewDetails} visible={viewDetails} setReload={setRelaod} viewOnly={edit} />}
      <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {!id ? <span>Are you sure you want to delete the selected items?</span> : <span>Are you sure you want to delete ?</span>}
        </div>
      </Dialog>

      <Dialog visible={approveDialogVisible} style={{ width: '450px' }} header="Approve" modal onHide={onHide}>
        <div className=" align-items-center justify-content-center gap-2">
          <Calendar value={approveDate} onChange={(e) => setApproveDate(e.value as Date)} dateFormat="dd/mm/yy" showIcon showButtonBar placeholder="Select approve date" />
          <div className="ml-6">{approveDate === null && <ErrorMessage message={"Please select a date"} id="calender" />}</div>
          <Calendar value={startDate} onChange={(e) => setStartDate(e.value as Date)} dateFormat="dd/mm/yy" showIcon showButtonBar className="mt-3" placeholder="Select start date" />
          <Button label="Approve" icon="pi pi-check" className="p-button-success p-button-text ml-3" onClick={onApprove} />
        </div>
        <div className="ml-6">{startDate === null && <ErrorMessage message={"Please select a date"} id="calender" />}</div>
      </Dialog>

      <ToastMessage showToast={toast} />
    </>
  );
};

export default ChitFundList;
