import React from 'react';
import { Button } from 'primereact/button';
import '../../../assets/fonts/fonts.css';

const ActionButton = ({ label, icon, onClick, className }:any) => {
  const buttonClassName = ` ${className} p-button-text mr-2`;

  return (
    <Button
      label={label}
      icon={icon}
      style={{ fontSize: '14px', padding: '1px 8px',fontFamily: 'Raleway',fontStyle:"normal",fontWeight:"500px" }}
      className={buttonClassName}
      onClick={onClick}
    />
  );
};

export default ActionButton;
