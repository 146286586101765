import { axiosInstance } from '../interceptors';

export const save = async (req: any) => {
    return await axiosInstance.post(`master/serialNo/`, req);
};
export const list = async (page: Number, limit: Number) => {
    return await axiosInstance.get(`master/serialNo/list?page=${page}&limit=${limit}`);
};

export const serialNoTypeList = async () => {
    return await axiosInstance.get(`master/serialNoList`);
};

export const update = async (serialId:any,e:any ) => {
    return await axiosInstance.put(`master/serialNo/update`,e);
};
export const get = async (id: string) => {
    return await axiosInstance.get(`master/serialNo/${id}`);
};