import React, { useContext } from 'react';
import { MainLayout } from '../containers/layout';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Home } from '../containers/views/home/home';
import Login from '../containers/views/login/login';
import ForgotPassword from '../containers/views/forgotpassword/forgotpassword';
import SetNewPaasword from '../containers/views/setnewpassword/setnewpassword';
import CheckMail from '../containers/views/checkmail/checkmail';
import { AuthLayout } from '../containers/layout';
import UserList from '../containers/views/user/userList';
import CustomerList from '../containers/views/customer/customerlist';
import MasterView from '../containers/views/master/MasterView';
import { LayoutProvider } from '../context';
import LoanList from '../containers/views/loan/LoanList';
import OfferCustomerList from '../containers/views/offers/offerCustomerList';
import { AuthContext } from '../context';
import RePaymentrList from '../containers/views/repayment/repaymentList';
import ProfileView from '../containers/views/profile/profileView';
import LoanTopUpList from '../containers/views/loanTopUp/loanTopUpList';
import LoanPreClosure from '../containers/views/loanPreClosure/loanPreClosureList';
import PaidList from '../containers/views/reports/paidList';
import DueList from '../containers/views/reports/dueList';
import ClosedLoanList from '../containers/views/reports/closedLoanList';
import ChitFundList from '../containers/views/chitFunds/newChitFunds/chitFundList';
import FeatureBoxes from '../containers/views/chitFunds/payments/feature';
import PayChittyAmount from '../containers/views/chitFunds/payments/payChittyAmount';
import PreviousAuctionsList from '../containers/views/chitFunds/payments/previousAuctionsList';
import CommissionReportList from '../containers/views/reports/commissionList';


const Index = () => {
  const { isAuthenticated } = useContext(AuthContext);
  
  return (
    <LayoutProvider>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route path='/app' element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route path='admin' element={<MasterView />} />
              <Route path='user' element={<UserList />} />
              <Route path='customer' element={<CustomerList />} />
              <Route path='offers' element={<OfferCustomerList />} />
              <Route path='transaction/loans/addloan' element={<LoanList />} />
              <Route path='transaction/loans/repayment' element={<RePaymentrList />} />
              <Route path='transaction/loans/loantopup' element={<LoanTopUpList />} />
              <Route path='transaction/loans/loanpreclosure' element={<LoanPreClosure />} />
              <Route path='transaction/chits/addchit' element={<ChitFundList />} />

              <Route path='transaction/chits/payments' element={<FeatureBoxes />} />
              <Route path='transaction/chits/paychitty' element={<PayChittyAmount />} />
              <Route path='transaction/chits/previousauctions' element={<PreviousAuctionsList />} />

              <Route path='paidlist' element={<PaidList />} />
              <Route path='duelist' element={<DueList />} />
              <Route path='closedloan' element={<ClosedLoanList />} />
              <Route path='commission' element={<CommissionReportList />} />
              <Route path='profile' element={<ProfileView />} />
              <Route path='*' element={<Navigate to='/app' replace />} />
            </Route>
            {/* <Route  path='/login'  element={<Navigate to='/app' replace />} /> */}
            </>
           
        ): null }
        <Route  path='/login'  element={<Login />} />
        <Route path='/auth' element={<AuthLayout />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/checkmail' element={<CheckMail />} />
        <Route path='/setnewpassword' element={<SetNewPaasword />} />
        <Route path='*' element={<Navigate to='/login' replace />} />
      </Routes>
    </LayoutProvider>
  );
};

export default Index;


